import React from 'react'

import SEO from '../../SEO'

import ETFHero from './ETFHero'
import Sections from './Sections'

const ETFPage = () => (
  <>
    <SEO
      title="ETF: Investering i ETF’ere"
      description="Investering i ETF’er giver stor risikospredning til lave omkostninger. Læs mere om ETF’er her."
      keywords={['etf', 'investering']}
      pathname="/etf"
    />
    <ETFHero />
    <Sections />
  </>
)

export default ETFPage
