import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const WorkPlaceSection = () => (
  <BaseSection height="half" scrollReveal>
    <Row>
      <Col xs={12} lg={5} className="text-center text-lg-left">
        <BaseSection.Header.Base variant="white" scrollReveal>
          En unik arbejdsplads
        </BaseSection.Header.Base>
        <BaseSection.Text scrollReveal>
          NORD.investments kan tilbyde en unik arbejdsplads, hvor du får stor
          indﬂydelse, oplever en stejl læringskurve og har rig mulighed for at
          udvikle dig.
        </BaseSection.Text>
      </Col>
      <Col xs={12} lg={{ span: 6, offset: 1 }}>
        <CheckList>
          <ScrollTransition.Item as={CheckList.Item}>
            Dynamisk og energisk team
          </ScrollTransition.Item>
          <ScrollTransition.Item as={CheckList.Item}>
            Ægte start-up miljø
          </ScrollTransition.Item>
          <ScrollTransition.Item as={CheckList.Item}>
            Kom tæt på beslutninger og få medansvar
          </ScrollTransition.Item>
        </CheckList>
      </Col>
    </Row>
  </BaseSection>
)

export default WorkPlaceSection
