import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const SectionTop = React.forwardRef(
  ({ children, className, level, scrollReveal, ...props }, ref) => {
    let content = (
      <Text
        as={`h${level}`}
        size="lg"
        className={classNames(
          'mb-6',
          'text-center',
          'position-relative',
          className
        )}
        ref={ref}
        {...props}
      >
        {children}
      </Text>
    )

    if (scrollReveal) {
      content = <ScrollTransition.Item>{content}</ScrollTransition.Item>
    }

    return content
  }
)

SectionTop.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  level: PropTypes.number,
  scrollReveal: PropTypes.bool
}

SectionTop.defaultProps = {
  className: undefined,
  level: 2,
  scrollReveal: undefined
}

SectionTop.displayName = 'BaseSection.Header.SectionTop'

export default SectionTop
