import NotFoundContent from '@nordinvestments/nord-storybook/dist/components/NotFoundContent'
import React from 'react'

import BaseSection from '../../BaseSection'
import SEO from '../../SEO'

const NotFoundPage = () => (
  <>
    <SEO
      title="NORD er nem opsparing og investering for alle"
      description="Vi gør op med oldnordisk formuepleje, og gør det billigere, transparent og intelligent. Få højere afkast og lavere risiko med portefølje af ETF´ere."
      keywords={[
        'formuepleje',
        'investering',
        'opsparing',
        'ETF',
        'nord',
        'nord invest',
        'passiv investering',
        'index fond',
        'indeks fonde'
      ]}
      pathname="/404"
    />
    <BaseSection.Hero
      backgroundClass="bg-nord-gradient"
      height="half"
      withCut
    />
    <BaseSection className="mt-n6" fullScreen={false}>
      <NotFoundContent />
    </BaseSection>
  </>
)

export default NotFoundPage
