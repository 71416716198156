/* eslint-disable react/jsx-no-target-blank */
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import React from 'react'

const onboardingUrl = getConfig('domains.onboarding')

const questionsData = [
  {
    index: '0',
    title: 'Er afkastet før eller efter omkostninger?',
    content:
      'Afkastet vises før omkostninger, eftersom omkostningerne bl.a. afhænger af om du vælger en standard- eller ansvarlig portefølje.'
  },
  {
    index: '1',
    title: 'Er afkastet efter skat?',
    content: (
      <>
        Skat er meget individuelt og derfor vises afkastet før skat. Beregn
        hvilken beskatningsform der er mest fordelagtig for dig på{' '}
        <a
          href="https://www.beregnaktieskat.dk/"
          target="_blank"
          className="link-light"
        >
          {' '}
          beregnaktieskat.dk
        </a>
        .
      </>
    )
  },
  {
    index: '2',
    title: 'Er der forskel på standard og ansvarlige porteføljer?',
    content: (
      <>
        Der er forskel på standard og ansvarlige porteføljer, men de har givet
        nogenlunde det samme afkast beregnet over de sidste 20 år. Du kan læse
        mere om{' '}
        <a
          href="https://faq.nord.investments/da/articles/3019353-nord-s-ansvarlige-portefoljer"
          target="_blank"
          className="link-light"
        >
          ansvarlige porteføljer her
        </a>
        .
      </>
    )
  },
  {
    index: '3',
    title: 'Hvad indeholder porteføljerne?',
    content: (
      <>
        Porteføljerne er sammensat af 7 ETF’er, som samlet set indeholder 4-5000
        forskellige værdipapirer. De består af aktier og obligationer.{' '}
        <a href={onboardingUrl} target="_blank" className="link-light">
          {' '}
          Tag en gratis investeringsplan{' '}
        </a>{' '}
        og se hvordan din portefølje vil se ud.
      </>
    )
  }
]

export default questionsData
