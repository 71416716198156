import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import RipplesShape from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import useDetectBrowser from '@nordinvestments/nord-storybook/dist/hooks/useDetectBrowser'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../BaseSection'

import TeamImageIE from './images/team-ie.png'
import TeamImage from './TeamImage'

const HiringHero = () => {
  const isIE = useDetectBrowser('ie')

  return (
    <BaseSection.Hero backgroundClass="bg-nord-gradient" withCut>
      <Row>
        <Col
          xs={12}
          lg={{ span: 5, offset: 1 }}
          className="mt-4 mt-lg-0 my-lg-auto text-center text-lg-left"
        >
          <BaseSection.Header.HeroTop className="text-white" scrollReveal>
            Karriere
          </BaseSection.Header.HeroTop>
          <BaseSection.Header.Base
            className="text-white"
            scrollReveal
            isHeroHeader
          >
            Bliv del af en vækstrejse
          </BaseSection.Header.Base>
          <BaseSection.Text scrollReveal>
            Få et indblik i vores hverdag og søg job hos os.
          </BaseSection.Text>
          <ScrollTransition.Item>
            <BaffleButton
              variant="success"
              as="a"
              href="https://thehub.io/startups/nordinvestments-as"
            >
              Se vores ledige stillinger
            </BaffleButton>
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} lg={5} className="mt-5 mt-lg-0">
          {isIE ? (
            <img className="img-fluid" src={TeamImageIE} alt="teamImage" />
          ) : (
            <RipplesShape.Mask variant="C" hasLines hero>
              <TeamImage />
            </RipplesShape.Mask>
          )}
        </Col>
      </Row>
    </BaseSection.Hero>
  )
}

export default HiringHero
