import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import formatDate from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import times from 'lodash/times'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'

import BaseSection from '../../../../BaseSection'

import styles from './Testimonial.module.scss'
import { ReactComponent as TrustpilotStar } from './TrustpilotStar.svg'

const SCROLL_REVEAL_ITEM_COUNT = 9

const Testimonial = ({ text, rating, author, date, index }) => {
  const startIndex = index * SCROLL_REVEAL_ITEM_COUNT

  return (
    <Col xs={12} md={6} className="px-md-6 mb-6">
      <ScrollTransition.Item
        as={Card}
        className="text-center mb-6"
        index={startIndex}
      >
        <Card.Body>
          <BaseSection.Text as="div">
            <ScrollTransition.Item
              as="p"
              className={styles.text}
              index={startIndex + 1}
            >
              {text}
            </ScrollTransition.Item>
            <div className="text-center mt-3">
              {times(rating, (starIndex) => (
                <ScrollTransition.Item
                  key={starIndex}
                  as="span"
                  className="px-1 d-inline-block"
                  index={startIndex + starIndex + 2}
                >
                  <TrustpilotStar />
                </ScrollTransition.Item>
              ))}
            </div>
          </BaseSection.Text>
        </Card.Body>
      </ScrollTransition.Item>
      <div className="text-center">
        <ScrollTransition.Item index={startIndex + 7}>
          <BaseSection.Text as="h4" className={styles.author}>
            {author}
          </BaseSection.Text>
        </ScrollTransition.Item>
        <ScrollTransition.Item
          as="span"
          className="font-size-sm text-monospace d-inline-block"
          index={startIndex + 8}
        >
          {formatDate(date)}
        </ScrollTransition.Item>
      </div>
    </Col>
  )
}

Testimonial.propTypes = {
  text: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired
}

export default Testimonial
