import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import AvatarCard from '../../../../AvatarCard'
import BaseSection from '../../../../BaseSection'

import boardData from './boardData'
import BoardMemberModal from './BoardMemberModal'

const seniorManagementData = boardData.filter((member) => !member.isBoardMember)
const boardMembersData = boardData.filter((member) => member.isBoardMember)

const BoardSection = () => {
  const [showModal, setShowModal] = useState(false)
  const [activeMemberData, setActiveMemberData] = useState({})

  const handleOpenModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  const handleOpenCV = (boardMember) => {
    handleOpenModal()
    setActiveMemberData(boardMember)
  }

  return (
    <BaseSection
      className="text-center bg-deep-dark"
      height="large"
      scrollReveal
    >
      <Row className="mb-6">
        <Col>
          <BaseSection.Header.Base variant="white" scrollReveal>
            Ledelsen
          </BaseSection.Header.Base>
        </Col>
      </Row>
      <Row>
        {seniorManagementData.map((boardMember, index) => (
          <AvatarCard
            {...boardMember}
            key={boardMember.name}
            index={index}
            indexOffset={2}
          >
            <Button
              variant="link"
              className="font-size-md pb-0"
              onClick={() => handleOpenCV(boardMember)}
            >
              Læs mere
            </Button>
          </AvatarCard>
        ))}
      </Row>
      <Row className="mb-6">
        <Col>
          <BaseSection.Header.Base variant="white" scrollReveal>
            Bestyrelsen
          </BaseSection.Header.Base>
        </Col>
      </Row>
      <Row>
        {boardMembersData.map((boardMember, index) => (
          <AvatarCard
            {...boardMember}
            key={boardMember.name}
            index={index}
            indexOffset={2}
          >
            <Button
              variant="link"
              className="font-size-md pb-0"
              onClick={() => handleOpenCV(boardMember)}
            >
              Læs mere
            </Button>
          </AvatarCard>
        ))}
      </Row>
      <BoardMemberModal
        memberData={activeMemberData}
        show={showModal}
        onHide={handleCloseModal}
      />
    </BaseSection>
  )
}

export default BoardSection
