import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const BackgroundImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(
        relativePath: {
          eq: "components/pages/AboutPage/AboutHero/background-image.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1620, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.backgroundImage.childImageSharp.fluid}
      alt="Om os"
      loading="eager"
      style={{
        opacity: 0.25
      }}
      {...props}
    />
  )
}

export default BackgroundImage
