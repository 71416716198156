import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import styleConfiguration from '@nordinvestments/nord-storybook/dist/configuration/styleConfiguration'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { PieChart, Pie, Cell } from 'recharts'

import CostsContext from '../CostsContext'

import styles from './Chart.module.scss'

const { blues } = styleConfiguration

const calculateValue = (fees, percentage) => fees / percentage

const {
  privateAndCompany: {
    fees: {
      base: { nord: nordFees, saxo: saxoFees, indirect: indirectFees },
      esg: { nord: nordESGFees, indirect: indirectESGFees }
    }
  }
} = prices

const Chart = ({ width, height }) => {
  const { investmentAmount, costs, isESG } = useContext(CostsContext)

  let nordTotalFees
  let otherTotalFees

  const saxoTotalFees = saxoFees.total.value

  if (isESG) {
    otherTotalFees = indirectESGFees.total.value

    if (investmentAmount < prices.yearlyCostsLimit.value) {
      nordTotalFees = nordESGFees.total.high.value
    } else {
      nordTotalFees = nordESGFees.total.low.value
    }
  } else {
    otherTotalFees = indirectFees.total.value

    if (investmentAmount < prices.yearlyCostsLimit.value) {
      nordTotalFees = nordFees.total.high.value
    } else {
      nordTotalFees = nordFees.total.low.value
    }
  }

  const data = [
    {
      name: 'NORD.investments',
      value: calculateValue(nordTotalFees, costs)
    },
    { name: 'Saxo Bank', value: calculateValue(saxoTotalFees, costs) },
    {
      name: 'Øvrige omkostinger',
      value: calculateValue(otherTotalFees, costs)
    }
  ]

  const largeAndUp = useBreakpointHandler('lg')

  const marginTop = largeAndUp ? 0 : 20
  const formattedWidth = largeAndUp ? width / 2 : width
  const formattedHeight = largeAndUp ? formattedWidth : formattedWidth / 2
  const yCenter = largeAndUp ? height / 2 : width / 5 + marginTop

  return (
    <div className={classNames(styles.chartWrapper, 'ml-lg-3')}>
      <PieChart
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }}
        width={formattedWidth}
        height={formattedHeight}
        className="mx-auto mb-auto mt-n3 my-lg-0"
      >
        <Pie
          data={data}
          dataKey="value"
          cy={yCenter}
          innerRadius={60}
          outerRadius={80}
          paddingAngle={3}
        >
          <Cell fill={blues[100]} />
          <Cell fill={blues[300]} />
          <Cell fill={blues[500]} />
        </Pie>
      </PieChart>
    </div>
  )
}

Chart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number
}

Chart.defaultProps = {
  height: undefined,
  width: undefined
}

export default withResizeDetector(Chart)
