import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const NordMacbookImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      nordMacbookImage: file(
        relativePath: {
          eq: "components/pages/WealthPage/Sections/PriceSection/images/nord-macbook.jpg"
        }
      ) {
        childImageSharp {
          fluid(
            quality: 75
            maxWidth: 5351
            maxHeight: 3685
            cropFocus: CENTER
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.nordMacbookImage.childImageSharp.fluid} {...props} />
}

export default NordMacbookImage
