import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const ETFSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <BaseSection.Header.Base
      className="mt-6"
      variant="white"
      align="center"
      scrollReveal
    >
      Din investering følger markedets afkast
    </BaseSection.Header.Base>
    <Row>
      <Col md={6} className="offset-md-3">
        <BaseSection.Text align="center" scrollReveal>
          Du bliver investeret i 4 - 5000 forskellige værdipapirer via ETF’er.
          Det giver dig en stor spredning til lave omkostninger.
        </BaseSection.Text>
      </Col>
    </Row>
  </BaseSection>
)

export default ETFSection
