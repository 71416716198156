import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import calendarData from './calendarData'

const FinanceCalendarSection = () => (
  <BaseSection height="half" scrollReveal>
    <Row>
      <ScrollTransition.Item as={Col}>
        <Card bg="deep-dark">
          <Card.Body>
            <Row>
              <Col xs={12} lg={6}>
                <BaseSection.Header.Base
                  variant="white"
                  className="text-center text-lg-left"
                  scrollReveal
                >
                  Finansiel Kalender
                </BaseSection.Header.Base>
              </Col>
              <Col
                xs={12}
                lg={6}
                className="text-center text-lg-left children-py-1"
              >
                {calendarData.map(({ date, title }) => (
                  <div
                    key={title}
                    className="d-flex flex-column flex-lg-row align-items-center"
                  >
                    <Text variant="white" size="lg" transform="uppercase">
                      {title}
                    </Text>
                    <Text variant="warning" size="md">
                      {' '}
                      <span className="d-none d-lg-inline-block">-</span> {date}
                    </Text>
                  </div>
                ))}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </ScrollTransition.Item>
    </Row>
  </BaseSection>
)

export default FinanceCalendarSection
