import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import { numbersToPercentRange } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import styles from './AdvantageCard.module.scss'

const AdvantageSection = () => (
  <Row className="mt-6">
    <Col>
      <ScrollTransition.Item
        as={Card}
        className={classNames(
          'shadow',
          'mx-lg-4',
          styles.advantageCardBackground
        )}
      >
        <Card.Body>
          <Row className="mx-lg-4">
            <Col xs={12} lg={6} className="text-center text-lg-left">
              <BaseSection.Header.Base
                variant="white"
                className="d-none d-lg-block"
                scrollReveal
              >
                Dine fordele ved nord
              </BaseSection.Header.Base>
              <BaseSection.Header.Base
                variant="white"
                className="d-lg-none"
                scrollReveal
              >
                Dine fordele
              </BaseSection.Header.Base>
              <BaseSection.Text variant="body" scrollReveal>
                Hos NORD får du et bedre afkast med lavere omkostninger på din
                pension. Vi tager dig i hånden hele vejen og har du spørgsmål
                kan du altid kontakte os.
              </BaseSection.Text>
            </Col>
            <Col xs={12} lg={5} className="offset-lg-1">
              <CheckList>
                <ScrollTransition.Item as={CheckList.Item}>
                  Historiske pensionsafkast på op til{' '}
                  {prices.pension.historicalReturn.format} årligt (siden 1999)
                </ScrollTransition.Item>
                <ScrollTransition.Item as={CheckList.Item}>
                  Lavere risiko med optimal spredning
                </ScrollTransition.Item>
                <ScrollTransition.Item as={CheckList.Item}>
                  Altid lave omkostninger på{' '}
                  {numbersToPercentRange(
                    prices.pension.costs.low.value,
                    prices.pension.costs.high.value
                  )}
                </ScrollTransition.Item>
              </CheckList>
            </Col>
          </Row>
        </Card.Body>
      </ScrollTransition.Item>
    </Col>
  </Row>
)

export default AdvantageSection
