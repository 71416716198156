import React from 'react'

import BaseSection from '../../../../BaseSection'

import InvestmentContent from './InvestmentContent'

const InvestmentSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <InvestmentContent />
  </BaseSection>
)

export default InvestmentSection
