import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const MoreSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <BaseSection.Header.Base
      className="mt-6"
      variant="white"
      align="center"
      scrollReveal
    >
      En investering der passer til dig
    </BaseSection.Header.Base>
    <Row>
      <Col xs={12} lg={6} className="offset-lg-3">
        <BaseSection.Text align="center" scrollReveal>
          Ved investering i ETF’er får du en lille ejerandel i tusindvis af
          forskellige aktier uden selv at skulle købe dem enkeltvist. Læs mere{' '}
          <a
            href="https://blog.nord.investments/etf/"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            className="link-white"
          >
            ETF’ere her
          </a>
          .
        </BaseSection.Text>
      </Col>
    </Row>
  </BaseSection>
)

export default MoreSection
