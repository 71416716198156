import React from 'react'

import KeywordsSection from '../../../KeywordsSection'

import AdvantagesSection from './AdvantagesSection'
import GallerySection from './GallerySection'
import KnowMoreSection from './KnowMoreSection'
import OpenPositionsSection from './OpenPositionsSection'
import TeamSection from './TeamSection'
import WorkPlaceSection from './WorkplaceSection'

const Sections = () => (
  <>
    <WorkPlaceSection />
    <OpenPositionsSection />
    <AdvantagesSection />
    <KeywordsSection dark />
    <TeamSection />
    <GallerySection />
    <KnowMoreSection />
  </>
)

export default Sections
