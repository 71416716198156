import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BoardImageIE from '../../../../../assets/images/board-image-IE.png'
import BaseSection from '../../../../BaseSection'
import BoardImage from '../../../../BoardImage'

const PensionContent = () => (
  <SectionWithOverflowImage
    className="my-6"
    image={<BoardImage />}
    imageAlign="right"
    imageForIE={BoardImageIE}
    variant="C"
    hasLines
  >
    <BaseSection.Header.Base variant="white" scrollReveal>
      En investering, der passer til dig
    </BaseSection.Header.Base>
    <BaseSection.Text scrollReveal>
      Jo nærmere du kommer din pension, desto lavere risiko får din investering.
      Vi tilpasser din investering løbende.
    </BaseSection.Text>
  </SectionWithOverflowImage>
)

export default PensionContent
