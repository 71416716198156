import React from 'react'

import SEO from '../../SEO'

import Sections from './Sections'
import WealthHero from './WealthHero'

const WealthPage = () => (
  <>
    <SEO
      title="NORD Wealth - til dig med en formue på +5M"
      description="NORD Wealth er til dig med en formue på +5M kr. Private Banking uden de høje omkostninger. Læs mere om hvad vi kan gøre for dig og din formue."
      keywords={['formue', 'formuepleje', 'private banking']}
      pathname="/wealth"
    />
    <WealthHero />
    <Sections />
  </>
)

export default WealthPage
