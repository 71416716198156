import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import DocumentCard from './DocumentCard'
import documentsData from './documentsData'

const DocumentsSection = () => (
  <BaseSection height="half" scrollReveal>
    <Row className="mt-5 pt-5">
      <Col xs={12}>
        <BaseSection.Header.Base align="center" variant="white" scrollReveal>
          Dokumenter
        </BaseSection.Header.Base>
      </Col>
    </Row>
    <Row className="my-5">
      {documentsData.map((document) => (
        <DocumentCard key={document.title} {...document} />
      ))}
    </Row>
  </BaseSection>
)

export default DocumentsSection
