const calendarData = [
  {
    title: 'Halvårsregnskab 2021',
    date: '24. august 2021'
  },
  {
    title: 'Årsregnskab 2021',
    date: '22. marts 2022'
  },
  {
    title: 'Generalforsamling',
    date: '20. april 2022'
  },
  {
    title: 'Halvårsregnskab 2022',
    date: '24. august 2022'
  }
]

export default calendarData
