import { Anders } from '../../../AboutPage/Sections/TeamSection/images'

import Sasja from './Sasja'

const contactPersonsData = [
  {
    name: 'Anders Hartmann',
    jobTitle: 'Administrerende Direktør',
    company: 'NORD.investments',
    email: 'anders@nord.investments',
    phone: '+45 29 43 95 88',
    image: Anders
  },
  {
    name: 'Sasja Dalgaard',
    jobTitle: 'Certified Adviser',
    company: 'Tofte & Company',
    email: 'sd@toftecompany.com',
    phone: '+45 26 10 08 77',
    image: Sasja
  }
]

export default contactPersonsData
