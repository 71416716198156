import React from 'react'

import SEO from '../../SEO'

import PriceHero from './PriceHero'
import Sections from './Sections'

const PricePage = () => (
  <>
    <SEO
      title="Danmarks billigste investeringsservice"
      description="Vi anvender lav omkostnings indeks fonde. Online selvbetjeningsplatform."
      keywords={[
        'ÅOP',
        'lave omkostninger',
        'renters rente',
        'årligt afkast',
        'valutaspread',
        'handelsomkostninger',
        'depotomkostninger',
        'fondsomkostninger',
        'vekselomkostninger'
      ]}
      pathname="/priser"
    />
    <PriceHero />
    <Sections />
  </>
)

export default PricePage
