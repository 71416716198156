import React from 'react'

import BaseSection from '../../../../BaseSection'

import AdvantagesContent from './AdvantagesContent'
import Description from './Description'

const DetailedAdvantagesSection = () => (
  <BaseSection className="bg-deep-dark" scrollReveal>
    <Description />
    <AdvantagesContent />
  </BaseSection>
)

export default DetailedAdvantagesSection
