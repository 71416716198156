import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaseSection from '../../../../BaseSection'

const ContactSection = () => (
  <BaseSection className="bg-deep-dark" scrollReveal>
    <Row>
      <Col xs={12} lg={6} className="offset-lg-3 text-center">
        <BaseSection.Header.Base variant="white" scrollReveal>
          Kontakt os og hør mere
        </BaseSection.Header.Base>
        <BaseSection.Text scrollReveal>
          Book en tid for en introduktion eller kontakt vores direktør
          Anders&nbsp;Hartmann direkte på{' '}
          <a href="mailto:anders@nord.investments" className="link-light">
            anders@nord.investments
          </a>{' '}
          eller{' '}
          <a href="tel:+4571969628" className="link-light">
            +45&nbsp;7196&nbsp;9628
          </a>
          .
        </BaseSection.Text>
        <div className="d-flex justify-content-center">
          <IconButton
            icon={ArrowRight}
            variant="success"
            href="https://calendly.com/andershartmann/15min"
            target="_blank"
            rel="noopener noreferrer"
          >
            Book intro
          </IconButton>
        </div>
      </Col>
    </Row>
  </BaseSection>
)

export default ContactSection
