import React from 'react'

const Cookies = () => (
  <>
    <h2>Cookies</h2>
    <p>
      På NORD benytter vi cookies til at forbedre den oplevelse du får på vores
      hjemmeside og indsamle statistik om besøgende. Uden cookies ville du
      faktisk ikke kunne benytte flere dele af vores hjemmeside.
    </p>
    <p>
      NORD har en række samarbejdspartnere hvor udveksling af data kan forekomme
      - herunder udveksling af data via cookies. Det vil sige, at godkendte
      tredjeparts tjenester, i nogle tilfælde kan indsamle data via cookies.
    </p>
    <p>
      Herunder ser du en oversigt over alle de 1. parts cookies NORD sætter samt
      de 3. parts tjenester der kan sætte cookies når du besøger NORD
      hjemmeside:
    </p>
    <table className="table table-bordered table-sm">
      <thead>
        <tr>
          <th>Navn</th>
          <th>Beskrivelse</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            Cookie-policy-accepted
            <br />
            1. parts cookies
          </td>
          <td>
            For at huske, at du har accepteret cookies, så sætter vi en cookie.
          </td>
        </tr>
        <tr>
          <td>
            UserID
            <br />
            1. parts cookies
          </td>
          <td>Bruges til at identificere dig som bruger af NORD.</td>
        </tr>
        <tr>
          <td>
            _session_id
            <br />
            1. parts cookies
          </td>
          <td>
            Vores session ID som bruges til at identificere dig som
            tilbagevendende brugere.
          </td>
        </tr>
        <tr>
          <td>
            Highcharts
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger Highcharts cookies til at fremvise grafer, som f.eks. kan
            vise dit potentielle afkast.
          </td>
        </tr>
        <tr>
          <td>
            Facebook
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger Facebook cookies til at holde øje med effektiviteten af
            vores Facebook-annoncer.
          </td>
        </tr>
        <tr>
          <td>
            Trustpilot
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger Trustpilots cookie til at fremvise et element som viser
            kunders anmeldelser af NORD.
          </td>
        </tr>
        <tr>
          <td>
            Google Analytics
            <br />
            3. parts cookie
          </td>
          <td>
            Vi bruger Google Analytics til at se hvordan folk agerer på
            hjemmesiden. Vi kan f.eks. se hvor mange der har besøgt vores side.
            <br />
            Al data er dog helt anonymiseret.
          </td>
        </tr>
        <tr>
          <td>
            Google Tag Manager
            <br />
            3. parts cookie
          </td>
          <td>
            i bruger Google Tag Manager cookies til at spore diverse handlinger
            på hjemmesiden.
          </td>
        </tr>
        <tr>
          <td>
            Doubleclick
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger Doubleclick cookies til at holde øje med effektiviteten af
            diverse reklameformer.
          </td>
        </tr>
        <tr>
          <td>
            Google Ads
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger Google Ads cookies til at holde øje med effektiviteten af
            vores annoncer.
          </td>
        </tr>
        <tr>
          <td>
            LinkedIn
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger LinkedIn cookies til at holde øje med effektiviteten af
            vores LinkedIn annoncer.
          </td>
        </tr>
        <tr>
          <td>
            Intercom
            <br />
            3. parts cookies
          </td>
          <td>
            Vi bruger Intercom til at kommunikere med folk der besøger vores
            hjemmeside.
          </td>
        </tr>
      </tbody>
    </table>
    <p>
      Hvis du vil slette dine cookies, skal du gøre det via din internetbrowser.
      Herunder har vi samlet vejledningerne til de mest populære browsertyper.
    </p>
    <p>
      Du kan her finde vejledninger til at fjerne cookies fra henholdsvis{' '}
      <a href="https://support.microsoft.com/da-dk/help/278835/how-to-delete-cookie-files-in-internet-explorer">
        Internet Explorer
      </a>
      ,{' '}
      <a href="https://support.apple.com/da-dk/guide/safari/sfri11471/mac">
        Safari
      </a>{' '}
      eller{' '}
      <a href="https://support.google.com/chrome/answer/95647?hl=da">Chrome</a>.
    </p>
    <p>
      Ønsker du at afvise brugen af cookies på din computer, kan du vælge at
      gøre det under indstillinger i din browser.
    </p>
    <p>
      Du skal dog være opmærksom på, at hvis du sletter eller blokerer cookies
      vil annoncer kunne blive mindre relevante for dig og optræde hyppigere. Du
      kan desuden risikere at hjemmesiden ikke fungerer optimalt samt at der er
      indhold, du ikke kan få adgang til.
    </p>
  </>
)

export default Cookies
