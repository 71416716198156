import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import VideoModal from '@nordinvestments/nord-storybook/dist/components/VideoModal'
import React, { useState } from 'react'
import Play from 'react-feather/dist/icons/play'

import BaseSection from '../../../BaseSection'

import BackgroundImage from './BackgroundImage'

const AboutHero = () => {
  const [showTVSpot, setShowTVSpot] = useState(false)

  const handleShowTVSpot = (event) => {
    event.preventDefault()
    setShowTVSpot(true)
  }

  const handleHideTVSpot = () => setShowTVSpot(false)

  return (
    <BaseSection.Hero
      backgroundImage={<BackgroundImage />}
      className="text-center"
      withCut
    >
      <BaseSection.Header.HeroTop className="text-white" scrollReveal>
        Om os
      </BaseSection.Header.HeroTop>
      <BaseSection.Header.Base isHeroHeader scrollReveal>
        Et team med mange års erfaring indenfor investering og vellykkede
        digitale tjenester
      </BaseSection.Header.Base>
      <ScrollTransition.Item
        as={IconButton}
        variant="success"
        className="mb-3"
        icon={Play}
        onClick={handleShowTVSpot}
      >
        Video om NORD
      </ScrollTransition.Item>
      <VideoModal
        show={showTVSpot}
        onHide={handleHideTVSpot}
        videoID="TZxM2DgGTFM"
        id="tvspot"
      />
    </BaseSection.Hero>
  )
}

export default AboutHero
