import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import formatDate from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './ArticleLink.module.scss'

const ArticleLink = ({ title, pressImage, url, date }) => (
  <li className={classNames('mb-4', styles.articleLink)}>
    <a href={url} target="_blank" rel="noopener noreferrer">
      <ScrollTransition.Item>
        <div>
          {React.cloneElement(pressImage, { className: styles.pressImage })}
        </div>
        <Text as="h4" variant="white" className="m-0" size="lg">
          {title}
        </Text>
        <div className="text-monospace font-size-sm text-body">
          {formatDate(date)}
        </div>
      </ScrollTransition.Item>
    </a>
  </li>
)

ArticleLink.propTypes = {
  title: PropTypes.string.isRequired,
  pressImage: PropTypes.element.isRequired,
  url: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired
}

export default ArticleLink
