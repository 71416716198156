import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Lock from 'react-feather/dist/icons/lock'
import Server from 'react-feather/dist/icons/server'
import TrendingUp from 'react-feather/dist/icons/trending-up'

import BaseSection from '../../../BaseSection'

const SecureItem = ({ children, icon }) => (
  <Col xs={12} sm={4} className="text-center">
    <ScrollTransition.Item>
      <Circle className="mx-auto mb-4">
        <Icon icon={icon} />
      </Circle>
    </ScrollTransition.Item>
    <ScrollTransition.Item>
      <BaseSection.Text>{children}</BaseSection.Text>
    </ScrollTransition.Item>
  </Col>
)

SecureItem.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ render: PropTypes.func.isRequired })
  ]).isRequired
}

const SecureSection = () => (
  <BaseSection className="bg-primary text-white" height="large" scrollReveal>
    <BaseSection.Header.SectionTop scrollReveal>
      Din sikkerhed
    </BaseSection.Header.SectionTop>
    <Row>
      <SecureItem icon={Lock}>
        Du bliver kunde i NORDs partnerbank, Saxo Bank, hvor dit depot og
        afkastkonto bliver oprettet.
      </SecureItem>
      <SecureItem icon={TrendingUp}>
        Dine kontanter er beskyttet af Garantiformuen, og dine investeringer er
        stadig dine, hvis vi eller Saxo Bank må lukke.
      </SecureItem>
      <SecureItem icon={Server}>
        Din portefølje er investeret i tusindvis af værdipapirer og optimal i
        forhold til din risikoprofil.
      </SecureItem>
    </Row>
  </BaseSection>
)

export default SecureSection
