import CardWithRippleImage from '@nordinvestments/nord-storybook/dist/components/CardWithRippleImage'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import AnimatedLinkWithArrow from '../../../../AnimatedLinkWithArrow'
import BaseSection from '../../../../BaseSection'
import GatsbyInternalLink from '../../../../GatsbyInternalLink'
import TreesImage from '../../../../TreesImage'
import styles from '../../../CardWithImage.module.scss'

const PortfolioCard = () => (
  <Row>
    <ScrollTransition.Item as={Col}>
      <CardWithRippleImage
        as={GatsbyInternalLink}
        to="/ansvarlig-investering"
        className={classNames(
          'shadow',
          'text-decoration-none',
          styles.cardBackground,
          styles.cardScale
        )}
        image={<TreesImage />}
        imageAlign="right"
      >
        <BaseSection.Header.Base variant="white" scrollReveal>
          Bidrag til en bedre verden
        </BaseSection.Header.Base>
        <BaseSection.Text variant="body" scrollReveal>
          Hos NORD kan du enten vælge mellem vores standard- eller ansvarlige
          porteføljer. Med vores ansvarlige porteføljer kan du investere med
          dine værdier i behold.
        </BaseSection.Text>
        <AnimatedLinkWithArrow icon={ArrowRight} href="/ansvarlig-investering">
          Læs mere
        </AnimatedLinkWithArrow>
      </CardWithRippleImage>
    </ScrollTransition.Item>
  </Row>
)

export default PortfolioCard
