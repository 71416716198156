import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import NordText from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'

const Text = React.forwardRef(
  ({ children, className, scrollReveal, ...props }, ref) => {
    let content = (
      <NordText as="p" size="lg" className={className} ref={ref} {...props}>
        {children}
      </NordText>
    )

    if (scrollReveal) {
      content = <ScrollTransition.Item>{content}</ScrollTransition.Item>
    }

    return content
  }
)

Text.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  scrollReveal: PropTypes.bool
}

Text.defaultProps = {
  className: undefined,
  scrollReveal: undefined
}

Text.displayName = 'BaseSection.Text'

export default Text
