import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const MoreSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <BaseSection.Header.Base
      className="mt-6"
      variant="white"
      align="center"
      scrollReveal
    >
      Beskatning af ETF’er
    </BaseSection.Header.Base>
    <Row>
      <Col xs={12} lg={6} className="offset-lg-3">
        <BaseSection.Text align="center" scrollReveal>
          Fra 2020, beskattes aktiebaserede EFT’er generelt som aktieindkomst og
          obligationsbaserede som kapitalindkomst. Begge beskattes efter
          lagerbeskatningsprincippet, hvor der årligt afregnes med skat. Læs
          mere om{' '}
          <a
            href="https://faq.nord.investments/da/articles/425138-etf-ere-og-skat"
            target="_blank"
            rel="noopener noreferrer"
            className="link-white"
          >
            ETF og skat her
          </a>
          .
        </BaseSection.Text>
      </Col>
    </Row>
  </BaseSection>
)

export default MoreSection
