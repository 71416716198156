import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import React from 'react'

import BaseSection from '../../../../../BaseSection'

const InfoOverlay = () => (
  <>
    <BaseSection.Header.Base level={5}>
      Udvælgelse af aktier virker ikke
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Mange drømmer om at finde den næste vinderaktie. De håber måske også på at
      købe eller sælge den på det rigtige tidspunkt.
    </BaseSection.Text>
    <BaseSection.Text>
      Problemet er dog, at kun 1 ud af 27 professionelle investorer klarer sig
      bedre end aktiemarkedet generelt. Hvis de professionelle investorer ikke
      kan få et bedre afkast end aktiemarkedet generelt, hvorfor skulle private
      så kunne?
    </BaseSection.Text>
    <BaseSection.Text>
      I forsøget på at slå markedet, kan man endda risikere at få et afkast som
      ligger under markedsafkastet. Man løber altså en øget risiko ved at
      forsøge at slå markedet.
    </BaseSection.Text>
    <BaseSection.Header.Base level={5}>
      Forbrugerrådet Tænk anbefaler fonde der følger markedet
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Forbrugerrådet Tænk advarer imod at sætte penge i de danske
      investeringsforeninger som forsøger at slå markedet. I stedet anbefaler
      de, at man sætter sine penge i billige fonde der følger aktiemarkedet
      generelt.
    </BaseSection.Text>
    <BaseSection.Text>
      Seniorøkonom i Forbrugerrådet, Troels Hauer Holmberg, udtaler:
    </BaseSection.Text>
    <BaseSection.Text>
      <cite className="italic">
        &quot;Når vi ser på, hvordan det historisk er gået for danske aktivt
        forvaltede fonde, så må man bare konstatere, er det som giver størst
        chance for et godt afkast er indeksfonde med lave omkostninger.&quot;
      </cite>
    </BaseSection.Text>
    <BaseSection.Header.Base level={5}>
      Hos NORD bliver du eksponeret i billige indeksfonde
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Hos NORD.investments bliver du investeret i en række passive fonde, som
      følger aktiemarkedet. Vi er opmærksomme på vigtigheden af lave
      omkostninger. Som kunde i NORD er dine totale årlige omkostninger på
      maksimalt {prices.privateAndCompany.costs.esg.high.format}.
    </BaseSection.Text>
  </>
)

export default InfoOverlay
