/* eslint-disable react/jsx-no-target-blank */
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import React from 'react'

const onboardingUrl = getConfig('domains.onboarding')

const questionsData = [
  {
    index: '0',
    title: 'Hvordan er jeg sikret?',
    content:
      'Dine kontanter er beskyttet af Garantiformuen og dine investeringer er stadig dine, hvis vi eller Saxo Bank måtte lukke.'
  },
  {
    index: '1',
    title: 'Hvad investerer jeg i?',
    content: (
      <>
        Din portefølje består af tusindvis af aktier og obligationer, som er
        sammensat af syv udvalgte ETF’er. Du kan se den specifikke sammensætning
        af værdipapirer under din{' '}
        <a href={onboardingUrl} target="_blank" className="link-light">
          {' '}
          investeringsplan
        </a>
        .
      </>
    )
  },
  {
    index: '2',
    title: 'Hvad koster det?',
    content: (
      <>
        En standard portefølje koster{' '}
        {prices.privateAndCompany.costs.base.high.format} i årlige omkostninger.{' '}
        En ansvarlig portefølje koster{' '}
        {prices.privateAndCompany.costs.esg.high.format}. Du kan læse, hvad
        prisen dækker over{' '}
        <a href="/priser" target="_blank" className="link-light">
          {' '}
          her
        </a>
        .
      </>
    )
  },
  {
    index: '3',
    title: 'Hvordan beskattes min investering?',
    content: (
      <>
        Fra 2020 beskattes alle aktiebaserede ETF’er hos NORD.investments som
        aktieindkomst, men stadig efter lagerbeskatningsprincippet, hvor der
        årligt afregnes med SKAT. Du kan{' '}
        <a
          href="https://www.beregnaktieskat.dk/"
          target="_blank"
          className="link-light"
        >
          {' '}
          beregne din aktieskat her
        </a>
        .
      </>
    )
  },
  {
    index: '4',
    title: 'Hvordan trækker jeg mine penge ud?',
    content: (
      <>
        Du kan altid sælge hele, eller en del af, din portefølje. Den bliver
        solgt samme dag som du kontakter os (senest næstkommende bankdag).
        Derefter kan du overføre pengene til den konto, du ønsker.{' '}
        <a
          href="https://faq.nord.investments/da/articles/1171648-hvor-lang-opsigelse-er-der-pa-min-portefolje"
          target="_blank"
          className="link-light"
        >
          Læs mere og kontakt os her
        </a>
        .
      </>
    )
  }
]

export default questionsData
