import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../BaseSection'
import HeroShapesVideo from '../../../HeroShapesVideo'
import OnboardingLink from '../../../OnboardingLink'

import styles from './CompanyHero.module.scss'
import mp4Video from './media/virksomheder.mp4'
import posterSrc from './media/virksomheder.png'
import webmVideo from './media/virksomheder.webm'

const CompanyHero = () => (
  <BaseSection.Hero
    backgroundClass={classNames('bg-nord-gradient', styles.companyHero)}
    withCut
  >
    <Row>
      <Col
        xs={12}
        lg={5}
        className="offset-lg-1 mt-4 mt-lg-0 my-lg-auto text-center text-lg-left"
      >
        <BaseSection.Header.HeroTop className="text-white" scrollReveal>
          Virksomhed
        </BaseSection.Header.HeroTop>
        <BaseSection.Header.Base
          className="text-white"
          scrollReveal
          isHeroHeader
        >
          Invester med din virksomhed
        </BaseSection.Header.Base>
        <BaseSection.Text scrollReveal>
          Betaler din virksomhed negative renter? Investér din virksomheds
          likvide midler og få dem til at vokse.
        </BaseSection.Text>
        <ScrollTransition.Item>
          <OnboardingLink
            component={BaffleButton}
            variant="success"
            path="virksomhed"
          >
            Læg en investeringsplan
          </OnboardingLink>
        </ScrollTransition.Item>
      </Col>
      <Col xs={12} lg={5} className="mt-5 mt-lg-0">
        <HeroShapesVideo
          variant="B"
          mp4Video={mp4Video}
          posterSrc={posterSrc}
          webmVideo={webmVideo}
        />
      </Col>
    </Row>
  </BaseSection.Hero>
)

export default CompanyHero
