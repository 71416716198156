import React from 'react'

import FAQSection from '../../../FAQSection'

import AdvantageSection from './AdvantageSection'
import IntroSection from './IntroSection'
import InvestSection from './InvestSection'
import PensionSection from './PensionSection'
import questionsData from './questionsData'

const Sections = () => (
  <>
    <IntroSection />
    <AdvantageSection />
    <PensionSection />
    <InvestSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
