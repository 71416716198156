import React from 'react'

import SEO from '../../SEO'

import PrivatHero from './PrivatHero'
import Sections from './Sections'

const PrivatPage = () => (
  <>
    <SEO
      title="En digital investeringsløsning med lave omkostninger"
      description="Investér dine frie midler gennem til lave omkostninger. Få en gratis investeringsplan og kom i gang med at investere."
      keywords={[
        'investering',
        'frie midler investering',
        'passiv investering'
      ]}
      pathname="/investering"
    />
    <PrivatHero />
    <Sections />
  </>
)

export default PrivatPage
