import React from 'react'

import ETFSection from './ETFSection'
import RebalancingSection from './RebalancingSection'
import RiskSection from './RiskSection'
import StockSection from './StockSection'

// TODO: finished animations with slide-in
const StoryBoard = () => (
  <>
    <StockSection />
    <ETFSection />
    <RiskSection />
    <RebalancingSection />
  </>
)

export default StoryBoard
