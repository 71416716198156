import React from 'react'

import {
  Anders,
  David,
  Nikolaj,
  Ketil,
  Malene,
  Jeppe,
  Michael,
  Christopher,
  Henrik
} from '../../../AboutPage/Sections/TeamSection/images'

const boardData = [
  {
    name: 'Anders Hartmann',
    email: 'anders@nord.investments',
    jobTitle: 'CEO',
    image: Anders,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/andershartmann/',
      Twitter: 'https://twitter.com/hartmann_anders'
    },
    education: [
      'BSc. Business Administration (Academy economist) International Marketing from Copenhagen Business School'
    ],
    experience: (
      <>
        <p>
          As a founder of Denmark’s first robo-advisor platform challenging
          traditional banks’ complex and over-priced investment solutions and
          CEO, since 2015, Anders has gained general and up-to-date knowledge of
          financial regulation, investment advice and digital transformation in
          the wealth management space.
        </p>
        <p>
          With approximately 20 years of experience as an entrepreneur and
          management in tech startups and scaleups, Anders is a seasoned
          entrepreneur with a successful track record. In 2000, Anders was CEO
          and co-founded the first online auction platform in Denmark which in
          2002 was acquired by the publishing division of Tele Denmark (TDC /
          Yellow pages) in an asset transaction. From 2003 until 2014, Anders
          held different Nordic management positions in Retail Internet which
          was acquired by the French counterpart Come&Stay turning the combined
          group into a European social media agency with offices in 8 countries
          and 150 employees. Later, Anders performed a management buyout of the
          Nordic part of Come&Stay which he and his partners later exited in
          2014 to a Swedish family office.
        </p>
      </>
    ),
    otherPositions: ['Owner and CEO of No Risc Media ApS (since March 2007)'],
    ownership: 2999470,
    warrants: 666640,
    startDate: '1. November 2015'
  },
  {
    name: 'David Harboe',
    email: 'david@nord.investments',
    jobTitle: 'CTO',
    image: David,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/davidharboe/',
      Twitter: 'https://twitter.com/DavidHarboe'
    },
    education: [
      'BSc. Computer Science and Engineering from Technical University of Denmark'
    ],
    experience: (
      <>
        <p>
          Co-founder and CTO in NORD.investments since 2016. David built the
          first version of the Company’s platform and the corresponding
          processes. David's deep technical background and commercial mindset
          will take NORD’s platform to the next level.
        </p>
        <p>
          David has over 10 years’ experience with startups and building
          software solutions. He finished his studies in Computer Science at The
          Technical University of Denmark in 2013 and went to one of Europe’s
          noteworthy accelerator programs, Ignite, in the United Kingdom. At
          Ignite, he joined the company Rentecarlo (June 2014 – June 2015) as
          CTO and Partner. The company was later sold to Admiral Group. Before
          he joined the Company, David worked for Airtame after their hugely
          successful crowdfunding campaign as lead iOS and backend developer.
        </p>
        <p>
          Forbes acknowledged his work, especially regarding the Company, and
          listed him as Forbes 30 under 30 Europe, Class of 2020 in Finance.
        </p>
      </>
    ),
    otherPositions: [
      'Owner and CEO and Chairman of the Board in David Harboe ApS (since May 2016)'
    ],
    formerPositions: [
      'Lead iOS and backend developer in AIRTAME (November 2014 – August 2016)'
    ],
    ownership: 938200,
    warrants: 395140,
    startDate: '1. August 2016'
  },
  {
    name: 'Nikolaj Bomann Mertz',
    email: 'nikolaj@nord.investments',
    jobTitle: 'Head of Marketing',
    image: Nikolaj,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/nikolajbomann/'
    },
    education: [
      'BSc. Business Administration and Information Systems from Copenhagen Business School'
    ],
    experience: (
      <>
        <p>
          As Head of Marketing Nikolaj is responsible for marketing and growth
          in the Company. Nikolaj joined the team February 2018 and has been
          leading the marketing efforts since then.
        </p>
        <p>
          Nikolaj has a background from Copenhagen Business School, where he
          studied Business Administration and Information Systems. Nikolaj has
          more than nine years of experience working with digital marketing and
          customer experience at international agencies in both London and
          Copenhagen including the listed agency Wunderman/WPP. Nikolaj has also
          worked as a Partnership Manager at Bloggers Delight (September 2013 –
          February 2015), accelerating their growth through affiliate marketing.
          Nikolaj has also previously started his own ecommerce business, Curo
          Media (September 2009 – August 2011), which was later exited.
        </p>
      </>
    ),
    otherPositions: ['Owner and CEO of BomannMertz ApS (since March 2018)'],
    formerPositions: [
      'User Experience Architect in Nitro Digital (May 2016 – February 2018)',
      'User Experience Architect in Wunderman (November 2015 – May 2016)'
    ],
    ownership: 27710,
    warrants: 66910,
    startDate: '1. February 2018'
  },
  {
    name: 'Ketil Petersen',
    jobTitle: 'Investeringsdirektør / CIO',
    image: Ketil,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/ketil-petersen-8352746/'
    },
    education: ['HD (F) from Copenhagen Business School'],
    experience: (
      <>
        <p>
          With more than 30 years of experience in the financial services
          industry in Denmark, the Nordic area and Europe, Ketil has through his
          Executive and Board Member positions a deep understanding of financial
          markets and asset management, covering all major asset classes as well
          as business modelling, accounting, economics and company valuations.
          Ketil worked from 1987 to 2000 in several positions ranging from
          portfolio manager in international equities and currency risk
          management at Alm. Brand Insurance, to Head of Equities at BG Bank
          A/S. In 2001 Ketil joined Schroders Investment Management Ltd, where
          he became CEO and Head of the Nordics in 2006. During his career at
          Schroders he was instrumental in developing the Intermediary business
          across the Nordic Region. At the same time he served large and complex
          institutional clients in the region. He also made a successful
          turnaround in the Benelux business from 2009 to 2012, was a member of
          the European Country Head team, served as Chairman for the European
          Institutional Management Team and was a board-member for 8 years of
          Schroder International Selection Fund (SICAV) in Luxembourg, which was
          the company’s flagship fund range for both retail and institutional
          investors with about €80bn of Funds under management. Since 2017 he
          has focused on Board assignments as well as advisory board positions
          with companies in the financial service industry and firms in the
          Information Technology sector.
        </p>
        <p>
          As CIO of NORD, Ketil’s main responsibilities are to ensure a
          competent investment board and consistency in the portfolios forming
          part of the investment services to the customers, as well as
          participating in strategic product development.
        </p>
      </>
    ),
    otherPositions: [
      'Chairman of the board in ChangeGroup ApS (since June 2020)',
      'Board member in Ress Life Management A/S (since August 2018)'
    ],
    formerPositions: [
      'Advisory board member in The General Pension Fund (August 2018 – November 2020)',
      'Board member in Schroder International Selection Fund, SICAV (February 2009 – April 2017)',
      'Managing Director, Chairman of the European Institutional Management and Country Head Nordic Region of Schroders (January 2001 – April 2017)'
    ],
    ownership: 358240,
    startDate: '1. September 2019'
  },
  {
    name: 'Jeppe Blom-Hansen',
    email: 'jeppe@nord.investments',
    jobTitle: 'Head of Customer Success',
    image: Jeppe,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/jeppe-b-26ba46143/'
    },
    education: [
      'MSc. Management of Innovation and Business Development from Copenhagen Business School',
      'BSc. International Business in Asia from Copenhagen Business School'
    ],
    experience: (
      <>
        <p>
          Jeppe is Head of Customer Success in the Company since the beginning
          of 2020. Jeppe helped built the customer success team in the Company
          and shape the sales processes due to his background within sales areas
          of multi-asset sales, securities, insurance, and his educational
          background.
        </p>
        <p>
          He has more than five years of experience in sales having worked with
          sales operations in both larger corporations like Saxo Bank and The
          Engineers' Association, IDA, but has also founded a smaller
          educational startup company in Shenyang, China where he lived and
          worked for more than two years between 2014 and 2016.
        </p>
        <p>
          Jeppe is currently enrolled in a Master’s program in Management of
          Innovation and Business Development from Copenhagen Business School,
          which supports his enthusiasm for startups as well as his passion for
          innovating and challenging traditional and over-complex investment
          solutions.
        </p>
      </>
    ),
    formerPositions: [
      'Supervisor in IDA Forsikring (August 2018 – September 2019)',
      'Private Sales Manager in Saxo Bank (March 2018 – September 2018)',
      'Student Assistant and Team Supervisor in IDA Forsikring (June 2017 – April 2018)',
      'Co-Founder in Shirley Culture Exchange Co. (January 2015 – July 2016)'
    ],
    warrants: 10080,
    startDate: '1. September 2019'
  },
  {
    name: 'Michael Albrechtslund',
    email: 'michael@albrechtslund.dk',
    jobTitle: 'Bestyrelsesformand',
    isBoardMember: true,
    isDependentBoardMember: true,
    image: Michael,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/michael-albrechtslund-391316/'
    },
    education: ['BSc. Business Analysis from Indiana University'],
    experience: (
      <>
        <p>
          With more than 30 years of experience in the asset management
          industry, through Executive, Non-Executive and Chairman positions,
          Michael offers a deep knowledge into all aspects of investments.
        </p>
        <p>
          From 1987 to 1997 Michael served in several positions at Alm. Brand
          Bank/Brooker, ranging from Head of Equity and Equity Research to
          Executive Assistant. In 1997 Michael founded Alm. Brand Invest and
          served as CEO until 2000. From 2002-2014 Michael was CEO (Sparinvest
          Asset Management), CIO and Co-owner of Sparinvest, and instrumental in
          the development of Sparinvest and Sparindex’ highly successful track
          record with several successful product and partnership launches, also
          on an international scale. Since 2014 he has focused on Board
          assignments, primarily as Chairman within finance companies and
          startups, alongside serving as CEO in Finansforeningen - CFA Society
          Denmark.
        </p>
        <p>
          As Co-founder of NORD, Michael has played an active role in the
          development of NORD’s Strategic Asset Allocation model and product
          range, as well as regulatory setup.
        </p>
      </>
    ),
    otherPositions: [
      'CEO in Finansforeningen - CFA Society Denmark (since May 2017)',
      'Chairman of the Board in Airpay Denmark A/S (since May 2018)',
      'Chairman of the Board in Forza Leasing A/S (since July 2014)',
      'Chairman of the Board in Lind Capital Fondsmæglerselskab A/S (since April 2014)',
      'Board member in Air-Invest Holding A/S (since July 2016)',
      'Board member in ETU Forsikring A/S (since April 2014)',
      'Board member in Basisbank A/S (since July 2013)',
      'Board member in Lth Holding 2 ApS (since March 2021)',
      'Board Member in Roskilde KFUM A/S (since March 2021)'
    ],
    startDate: '13. November 2015',
    ownership: 786850,
    warrants: 170830
  },
  {
    name: 'Malene Ehrenskjöld',
    email: 'malene@nord.investments',
    jobTitle: 'Bestyrelsesmedlem',
    profession: 'Non-Executive Board Member, Independent Consultant',
    image: Malene,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/malene-ehrenskj%C3%B6ld-2960dk/'
    },
    isBoardMember: true,
    isDependentBoardMember: false,
    education: ['MSc. Law from University of Aarhus'],
    experience: (
      <>
        <p>
          Malene is an experienced Manager at Executive level with proven track
          records on strategic- and business driven decisions. Malene has 20
          years of experience within the financial sector with focus on asset
          management from larger and smaller organizations and has strong legal,
          compliance- and operational risk competences and experience in driving
          organizations to comply within a shifting regulatory landscape in a
          Nordic and European context as well as ensuring best practice.
        </p>
        <p>
          Malene has substantial board experience both from serving company- and
          fund boards as well as being chairman/member of the board of directors
          in financial institutions, i.e. investment firms, AIF and UCITS as
          well as private institutions.
        </p>
      </>
    ),
    otherPositions: [
      'Non-executive Board member in ROOF Management A/S (since November 2020)'
    ],
    formerPositions: [
      'Managing Director at BI Management A/S (June 2018 - May 2020)',
      'Interim Managing Director at BI Management A/S (January 2018 – June 2018)',
      'Head of Compliance and Legal at the BankInvest Group (October 2015 – January 2018)'
    ],
    startDate: '22. February 2021'
  },
  {
    name: 'Christopher Hjelseth',
    jobTitle: 'Bestyrelsesmedlem',
    profession: 'Investment Manager at Finstart Nordic AS',
    isBoardMember: true,
    isDependentBoardMember: true,
    image: Christopher,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/christopherhjelseth/'
    },
    education: [
      'MSc. International Corporate Finance from The University of Salford, Manchester',
      'BSc. Business Administration (BBA) Accounting and Finance from Hult International Business School'
    ],
    experience: (
      <p>
        Christopher has close to 10 years’ experience from equity sales serving
        institutional clients on equity investments, private placements, and
        advising corporates on capital market transactions, latest at ABG Sundal
        Collier in Oslo, Norway. Before leading the strategic investments in
        Finstart, SpareBank 1 SR-Bank’s venture capital firm, Christopher gained
        experience as management consultant in Accenture for financial services
        on technology-led M&A, corporate venture strategy.
      </p>
    ),
    otherPositions: [
      'Board member in ZData AS (since May 2020)',
      'Swiipe ApS (since November 2019)'
    ],
    formerPositions: [
      'Board member in MyRent AS (January 2020 – May 2020)',
      'Interim CEO / Founding team in Beaufort Solutions AS (March 2019 – August 2019)',
      'Analyst in Accenture Consulting (May 2017 – August 2018)',
      'Equity Sales in ABG Sundal Collier ASA (August 2016 – April 2017)'
    ],
    startDate: '10. April 2019'
  },
  {
    name: 'Henrik Hasselbalch Busch',
    jobTitle: 'Bestyrelsesmedlem',
    profession: 'CEO at Blackwood Seven (since October 2014)',
    isBoardMember: true,
    isDependentBoardMember: false,
    image: Henrik,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/busch/'
    },
    education: [
      'The Higher Commercial Examination Programme',
      'Shipping Trainee',
      'Singularity University Executive Program'
    ],
    experience: (
      <p>
        Henrik is a business professional with a successful track record of
        commercial execution and management in leading companies and has more
        than 15 years’ experience in leadership, leading digital change,
        creating profitable growth, leveraging digital platforms, and creating
        media strategies. Henrik has been in charge of business development and
        strategy, turnarounds, start-ups, entrepreneurship, M&A, and has
        overseen daily business operations (100+ FTEs) in addition to chairing
        boards. Henrik has experience with management of companies and business
        units, including responsibility of results, strategy development and
        implementation, organization development, change management, efficiency
        improvements, process optimization, and optimization of profitability.
      </p>
    ),
    otherPositions: [
      'Board Member at Nørgård Mikkelsen A/S (since April 2011)',
      'Executive at Zephyr Invest ApS (since February 2012)',
      'Chairman at SimpleSite A/S (since November 2012)',
      'Board Member at PNO Holding A/S (since April 2019)'
    ],
    startDate: '22. February 2021',
    warrants: 10000
  }
]

export default boardData
