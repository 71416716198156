import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'

import ManWithAppImageIE from './images/IE-app.png'
import ManWithAppImage from './ManWithAppImage'

const ETFContent = () => (
  <SectionWithOverflowImage
    className="my-6"
    variant="C"
    image={<ManWithAppImage />}
    imageForIE={ManWithAppImageIE}
    imageAlign="right"
    hasLines
  >
    <BaseSection.Header.Base variant="white" scrollReveal>
      Exchange Traded Funds
    </BaseSection.Header.Base>
    <BaseSection.Text scrollReveal>
      ETF står for “Exchange-Traded Fund” og er blot en engelsk betegnelse for
      børshandlede fonde. Der findes i 2020 mere end 5.000 forskellige ETF’er
      globalt set. Læs mere om{' '}
      <a
        href="https://blog.nord.investments/etf"
        // eslint-disable-next-line react/jsx-no-target-blank
        target="_blank"
        className="link-white"
      >
        hvad en Exchange Traded Fund er her
      </a>
      .
    </BaseSection.Text>
  </SectionWithOverflowImage>
)

export default ETFContent
