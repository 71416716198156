import React from 'react'

import BaseSection from '../../../../BaseSection'

import AdvantageCard from './AdvantageCard'

const ResponsibleSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <AdvantageCard />
  </BaseSection>
)

export default ResponsibleSection
