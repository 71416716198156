import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import React from 'react'

const Disclaimer = () => (
  <>
    <Text
      as="p"
      transform="uppercase"
      variant="warning"
      size="xs"
      className="text-center text-lg-left"
    >
      Ovenstående omkostninger opkræves automatisk og månedsvis på din konto I
      saxo bank, på nær gebyr for ordreformidling, handelsomkostninger og
      valutavekslingsgebyrer, der opkræves ved handler. Derudover fragår
      fondsomkostninger direkte I fondene. Priser er altid inklusiv moms.
    </Text>
    <Text
      className="text-center text-lg-left"
      as="p"
      transform="uppercase"
      variant="warning"
      size="xs"
    >
      Senest opdateret 20. oktober 2020.
    </Text>
  </>
)

export default Disclaimer
