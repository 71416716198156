import React from 'react'

import KeywordsSection from '../../../KeywordsSection'

import AboutSection from './AboutSection'
import InvestorsSection from './InvestorsSection'
import PressSection from './PressSection'
import TeamSection from './TeamSection'
import TestimonialsSection from './TestimonialsSection'

const Sections = () => (
  <>
    <AboutSection />
    <TestimonialsSection />
    <KeywordsSection light withRipples />
    <TeamSection />
    <InvestorsSection />
    <PressSection />
  </>
)

export default Sections
