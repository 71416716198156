import React from 'react'

const Confidentiality = () => (
  <>
    <h2>Fortrolige oplysninger</h2>
    <p>
      Efter du har taget en gratis investeringsplan, har du muligheden for at
      etablere et kundeforhold i Saxo Bank A/S. I forbindelse med en oprettelse
      i Saxo Bank A/S, deler du de data du har oplyst til os med Saxo Bank A/S.
      Dette er nødvendigt for at du kan blive oprettet som kunde i banken.
    </p>
    <p>
      Vi vil i det omfang det er nødvendigt for at levere vores ydelser,
      indsamle og behandle fortrolige oplysninger. Opbevaring vil ligeledes kun
      ske i det omfang det er nødvendigt for at levere vores ydelser.
    </p>
    <p>
      Hvis der skulle være forhold hvor NORD er nødsaget til at videregive
      fortrolige oplysninger om eventuelle kundeforhold til Saxo Bank A/S, er
      både NORD og Saxo Bank A/S naturligvis underlagt den lovpligtige
      tavshedspligt.
    </p>
  </>
)

export default Confidentiality
