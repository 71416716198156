import React from 'react'

import SEO from '../../SEO'

import AboutHero from './AboutHero'
import Sections from './Sections'

const AboutPage = () => (
  <>
    <SEO
      title="Datadreven service til langsigtet investering"
      description="Alle vores porteføljer er uvildigt sammensat, og vi modtager ingen provision. Alle investeringsplaner bliver sammensat af nøje udvalgte ETF'ere."
      keywords={[
        'investerinsgsplan',
        'investeringsforeninger',
        'fonde',
        'uafhængig',
        'uvildige',
        'transparente',
        're-balancere',
        'opsparing',
        'portefølje',
        'nord invest'
      ]}
      pathname="/om"
    />
    <AboutHero />
    <Sections />
  </>
)

export default AboutPage
