import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import { numberToPercent } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const EnviromentSection = () => {
  const data = useStaticQuery(graphql`
    query {
      file(
        relativePath: {
          eq: "components/pages/ResponsibleInvestmentPage/Sections/EnviromentSection/document/Matter.pdf"
        }
      ) {
        publicURL
        name
      }
    }
  `)

  return (
    <BaseSection fullScreen={false} scrollReveal>
      <Row className="my-md-6 py-6 text-center text-lg-left">
        <Col xs={12} lg={5}>
          <BaseSection.Header.Base variant="white" scrollReveal>
            En ansvarlig investering
          </BaseSection.Header.Base>
          <BaseSection.Text scrollReveal>
            Mindre CO2 og våben. Mere grøn energi og etik. Vil du lære mere om
            vores ansvarlige porteføljer? Læs rapporten fra{' '}
            <a
              href={data.file.publicURL}
              target="_blank"
              rel="noreferrer noopener"
              className="link-white"
            >
              Matter Insights om NORDs porteføljer.
            </a>
          </BaseSection.Text>
        </Col>
        <Col xs={12} lg={6} className="offset-lg-1">
          <CheckList>
            <ScrollTransition.Item as={CheckList.Item}>
              Udleder {numberToPercent(0.5, { fractionDigits: 0 })} mindre vand
              end en standardportefølje
            </ScrollTransition.Item>
            <ScrollTransition.Item as={CheckList.Item}>
              {numberToPercent(0.32, { fractionDigits: 0 })} mindre CO2
            </ScrollTransition.Item>
            <ScrollTransition.Item as={CheckList.Item}>
              {numberToPercent(0.5, { fractionDigits: 0 })} mindre olie, kul og
              gas
            </ScrollTransition.Item>
          </CheckList>
        </Col>
      </Row>
    </BaseSection>
  )
}

export default EnviromentSection
