import React from 'react'

import FAQSection from '../../../FAQSection'

import AdvantageSection from './AdvantageSection'
import InvestmentSection from './InvestmentSection'
import MoreSection from './MoreSection'
import NobelPriceSection from './NobelPriceSection'
import questionsData from './questionsData'
import WorldSection from './WorldSection'

const Sections = () => (
  <>
    <AdvantageSection />
    <WorldSection />
    <NobelPriceSection />
    <InvestmentSection />
    <MoreSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
