import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import styleConfiguration from '@nordinvestments/nord-storybook/dist/configuration/styleConfiguration'
import getFees, {
  calculateFees,
  calculatePensionFees
} from '@nordinvestments/nord-storybook/dist/utilities/getFees'
import {
  numbersToPercentRange,
  numberToPercent
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import React, { useContext } from 'react'
import BootstrapAccordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import ChrevronDown from 'react-feather/dist/icons/chevron-down'

import CostsContext from '../CostsContext'

import styles from './Accordion.module.scss'
import Dot from './Dot'

const {
  privateAndCompany: {
    fees: {
      base: { saxo: saxoFees }
    }
  },
  pension: {
    fees: { indirect: pensionIndirectFees }
  }
} = prices

const { blues } = styleConfiguration

const Accordion = () => {
  const {
    costs,
    isESG,
    isStocksOnly,
    investmentAmount,
    isPension
  } = useContext(CostsContext)

  const fees = getFees({ isESG, isStocksOnly })
  const {
    nordAdvisoryFees,
    otherFees,
    otherTotalFeesFormatted,
    nordTotalFeesFormattedvalue,
    pensionFeesRange
  } = isPension
    ? calculatePensionFees({ investmentAmount })
    : calculateFees({ fees, investmentAmount, isESG, isStocksOnly })

  return (
    <>
      <div>
        <BootstrapAccordion className="d-flex flex-column w-100">
          <Card className={styles.accordionCard}>
            <BootstrapAccordion.Toggle
              as={Card.Header}
              eventKey="1"
              className={classNames(
                styles.accordionCardHeader,
                'd-flex flex-wrap justify-content-start justify-content-lg-between align-items-center w-100 bg-white'
              )}
            >
              <Dot fill={blues[100]} width="10px" height="10px" />
              <Text
                size="md"
                transform="uppercase"
                className="d-none d-lg-block mr-auto ml-2"
                monospace
              >
                NORD.investments
              </Text>
              <Text
                size="md"
                transform="uppercase"
                className="d-block d-lg-none ml-2"
                monospace
              >
                NORD
              </Text>
              <Text className="d-block d-lg-none ml-auto">
                {nordTotalFeesFormattedvalue} %
              </Text>
              <Text
                as="p"
                size="sm"
                transform="uppercase"
                variant="secondary"
                className="m-0 ml-2 mr-1 d-block d-lg-none"
              >
                pr. år
              </Text>
              <Icon
                className="d-block d-lg-none"
                icon={ChrevronDown}
                size={1}
              />
              <div className="d-none d-lg-flex flex-row align-items-center mr-0 mr-lg-2">
                <Text className="d-none d-lg-block pl-3 mr-2">
                  {nordTotalFeesFormattedvalue} %
                </Text>
                <Text
                  as="p"
                  size="sm"
                  transform="uppercase"
                  variant="secondary"
                  className="m-0 mr-1"
                >
                  pr. år
                </Text>
                <Icon icon={ChrevronDown} size={1} />
              </div>
            </BootstrapAccordion.Toggle>
            <BootstrapAccordion.Collapse eventKey="1">
              <Card.Body className={styles.accordionCardBody}>
                <ul className="text-left">
                  <li>
                    Rådgivningsgebyrer og overvågningsomkostning på{' '}
                    {isPension
                      ? `${pensionFeesRange}
                        (afhænger af investeringens størrelse og af de 6
                        forskellige pensionsporteføljer)`
                      : nordAdvisoryFees}{' '}
                    (afhænger af investeringens størrelse).
                  </li>
                  <li>
                    Gebyr for ordreformidling på {fees.nord.trading.format}.
                  </li>
                </ul>
              </Card.Body>
            </BootstrapAccordion.Collapse>
          </Card>
          <Card className={styles.accordionCard}>
            <BootstrapAccordion.Toggle
              as={Card.Header}
              eventKey="0"
              className={classNames(
                styles.accordionCardHeader,
                'd-flex flex-wrap justify-content-start justify-content-lg-between align-items-center w-100 bg-white'
              )}
            >
              <Dot fill={blues[300]} width="10px" height="10px" />
              <Text
                size="md"
                transform="uppercase"
                className="d-none d-lg-block mr-auto ml-2"
                monospace
              >
                Saxo Bank
              </Text>
              <Text
                size="md"
                transform="uppercase"
                className="d-block d-lg-none ml-2"
                monospace
              >
                Saxo
              </Text>
              <Text className="d-block d-lg-none ml-auto">
                {saxoFees.total.string} %
              </Text>
              <Text
                as="p"
                size="sm"
                transform="uppercase"
                variant="secondary"
                className="m-0 ml-2 mr-1 d-block d-lg-none"
              >
                pr. år
              </Text>
              <Icon
                className="d-block d-lg-none"
                icon={ChrevronDown}
                size={1}
              />
              <div className="d-none d-lg-flex flex-row align-items-center mr-0 mr-lg-2">
                <Text className="d-none d-lg-block mr-2">
                  {fees.saxo.total.string} %
                </Text>
                <Text
                  as="p"
                  size="sm"
                  transform="uppercase"
                  variant="secondary"
                  className="m-0 mr-1"
                >
                  pr. år
                </Text>
                <Icon icon={ChrevronDown} size={1} />
              </div>
            </BootstrapAccordion.Toggle>
            <BootstrapAccordion.Collapse eventKey="0">
              <Card.Body className={styles.accordionCardBody}>
                <ul className="text-left">
                  <li>Depotgebyr på {fees.saxo.custody.format}.</li>
                  <li>Handelsomkostninger på {fees.saxo.trading.format}.</li>
                  <li>
                    Valutavekslingsgebyr på {fees.saxo.valutaExchange.format}.
                  </li>
                </ul>
              </Card.Body>
            </BootstrapAccordion.Collapse>
          </Card>
          <Card className={styles.accordionCard}>
            <BootstrapAccordion.Toggle
              as={Card.Header}
              eventKey="2"
              className={classNames(
                styles.accordionCardHeader,
                'd-flex flex-wrap justify-content-start justify-content-lg-between align-items-center w-100 bg-white'
              )}
            >
              <Dot fill={blues[500]} width="10px" height="10px" />
              <Text
                size="md"
                transform="uppercase"
                className="d-none d-lg-block mr-auto ml-2"
                monospace
              >
                Øvrige omkostninger
              </Text>
              <Text
                size="md"
                transform="uppercase"
                className="d-block d-lg-none ml-2"
                monospace
              >
                Øvrige
              </Text>
              <Text className="d-block d-lg-none ml-auto">
                {otherTotalFeesFormatted} %
              </Text>
              <Text
                as="p"
                size="sm"
                transform="uppercase"
                variant="secondary"
                className="m-0 ml-2 mr-1 d-block d-lg-none"
              >
                pr. år
              </Text>
              <Icon
                className="d-block d-lg-none"
                icon={ChrevronDown}
                size={1}
              />
              <div className="d-none d-lg-flex flex-row align-items-center mr-0 mr-lg-2">
                <Text className="d-none d-lg-block pl-3 mr-2">
                  {otherTotalFeesFormatted} %
                </Text>
                <Text
                  as="p"
                  size="sm"
                  transform="uppercase"
                  variant="secondary"
                  className="m-0 mr-1"
                >
                  pr. år
                </Text>
                <Icon icon={ChrevronDown} size={1} />
              </div>
            </BootstrapAccordion.Toggle>
            <BootstrapAccordion.Collapse eventKey="2">
              <Card.Body className={styles.accordionCardBody}>
                <ul className="text-left">
                  <li>
                    Totale årlige omkostninger i fondene på{' '}
                    {isPension
                      ? `${numbersToPercentRange(
                          pensionIndirectFees.funds.low.value,
                          pensionIndirectFees.funds.high.value
                        )} (afhængigt af din risikoprofil).`
                      : otherFees}
                  </li>
                  <li>
                    Indirekte omkostninger (forskellen mellem købs- og
                    salgskurser) på de handlede fonde på{' '}
                    {fees.indirect.spread.format}.
                  </li>
                </ul>
              </Card.Body>
            </BootstrapAccordion.Collapse>
          </Card>
        </BootstrapAccordion>
      </div>
      <div className="mt-2 mr-1 d-flex flex-row justify-content-end">
        <h6>Total : {numberToPercent(costs)}</h6>
        <Text
          as="p"
          size="sm"
          transform="uppercase"
          variant="secondary"
          className="ml-2 mr-4 mr-lg-5"
        >
          pr. år
        </Text>
      </div>
    </>
  )
}

export default Accordion
