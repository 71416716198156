import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const CBSLesson = (props) => {
  const data = useStaticQuery(graphql`
    query {
      cbsLesson: file(
        relativePath: {
          eq: "components/pages/HiringPage/Sections/GallerySection/images/CBS-lesson.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, maxHeight: 536, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.cbsLesson.childImageSharp.fluid} {...props} />
}

export default CBSLesson
