import React from 'react'

import SEO from '../../SEO'

import CompanyHero from './CompanyHero'
import Sections from './Sections'

const CompanyPage = () => (
  <>
    <SEO
      title="Investering af dine virksomhedsmidler til lave omkostninger"
      description="Undgå negative renter på selskabskontoen. Investér din virksomheds likvide midler til markedets måske laveste omkostninger. Få en gratis investeringsplan."
      keywords={[
        'investering via virksomhed',
        'virksomhedsskatteordning',
        'vso'
      ]}
      pathname="/virksomhed"
    />
    <CompanyHero />
    <Sections />
  </>
)

export default CompanyPage
