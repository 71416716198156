import React from 'react'

import BaseSection from '../../../../BaseSection'

import AdvantagesContent from './AdvantagesContent'

const AdvantagesSection = () => (
  <BaseSection scrollReveal>
    <AdvantagesContent />
  </BaseSection>
)

export default AdvantagesSection
