import RipplesShape from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import useDetectBrowser from '@nordinvestments/nord-storybook/dist/hooks/useDetectBrowser'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import AndersImage from './AndersImage'
import AndersSignatureImage from './AndersSignatureImage'
import teamImage from './images/team.png'
import styles from './index.module.scss'
import KetilImage from './KetilImage'
import MichaelImage from './MichaelImage'

const TeamSection = () => {
  const isIE = useDetectBrowser('ie')

  return (
    <BaseSection fullScreen={false} scrollReveal>
      <Row className="py-5 my-5">
        <Col xs={12} lg={{ span: 6, offset: 3 }}>
          <BaseSection.Header.Base variant="white" align="center" scrollReveal>
            Et dedikeret team der holder øje med din formue
          </BaseSection.Header.Base>
          <BaseSection.Text align="center" scrollReveal>
            Vi er et team med mange års erfaring indenfor investering. Teamet og
            ejerne har tilsammen mere end 10M kr. investeret.
          </BaseSection.Text>
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 10, offset: 1 }} className="p-lg-0">
          <div className="d-flex">
            {isIE ? (
              <Image src={teamImage} alt="team-image" fluid />
            ) : (
              <>
                <div className={styles.leftImageWrapper}>
                  <RipplesShape.Mask variant="A" hasLines>
                    <MichaelImage className={styles.sideImage} />
                  </RipplesShape.Mask>
                </div>
                <div className={styles.middleImageWrapper}>
                  <RipplesShape.Mask variant="A" hasLines>
                    <AndersImage />
                  </RipplesShape.Mask>
                </div>
                <div className={styles.rightImageWrapper}>
                  <RipplesShape.Mask variant="A" hasLines>
                    <KetilImage className={styles.sideImage} />
                  </RipplesShape.Mask>
                </div>
              </>
            )}
          </div>
        </Col>
        <Col xs={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
          <AndersSignatureImage />
          <ul className="list-unstyled font-size-lg text-center">
            <li>Anders Hartmann</li>
            <li>Administrerende direktør</li>
          </ul>
        </Col>
      </Row>
    </BaseSection>
  )
}

export default TeamSection
