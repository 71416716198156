import React from 'react'

import SEO from '../../SEO'

import PensionHero from './PensionHero'
import Sections from './Sections'

const PensionPage = () => (
  <>
    <SEO
      title="Digital pension til lave omkostninger"
      description="Få din privattegnede pension til at voksem med lave omkostninger. Få en gratis pensionsplan."
      keywords={['pension', 'privattegnet pension', 'investering']}
      pathname="/pension"
    />
    <PensionHero />
    <Sections />
  </>
)

export default PensionPage
