import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const BoardMembersImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      boardMembersImage: file(
        relativePath: {
          eq: "components/pages/WealthPage/Sections/ImpartialitySection/images/board-members.jpg"
        }
      ) {
        childImageSharp {
          fluid(
            maxWidth: 2000
            maxHeight: 2000
            quality: 100
            cropFocus: CENTER
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.boardMembersImage.childImageSharp.fluid} {...props} />
}

export default BoardMembersImage
