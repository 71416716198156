import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const Henrik = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(
        relativePath: {
          eq: "components/pages/AboutPage/Sections/TeamSection/images/henrik.jpg"
        }
      ) {
        childImageSharp {
          fixed(width: 92, height: 92, quality: 100, cropFocus: ATTENTION) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)

  return <Img fixed={data.file.childImageSharp.fixed} {...props} />
}

export default Henrik
