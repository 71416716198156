import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const FinstartNordic = (props) => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(
        relativePath: {
          eq: "components/pages/AboutPage/Sections/InvestorsSection/images/finstart-nordic.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 540, maxHeight: 61, trim: 0, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(
        relativePath: {
          eq: "components/pages/AboutPage/Sections/InvestorsSection/images/finstart-nordic.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 300, maxHeight: 34, trim: 0, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 540px)`
    }
  ]

  return <Img fluid={sources} {...props} />
}

export default FinstartNordic
