import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import {
  numberToCurrency,
  numberToPercent
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import React, { useContext } from 'react'
import Card from 'react-bootstrap/Card'

import CostsContext from '../CostsContext'

import Chart from './Chart'
import styles from './index.module.scss'

const ExpectedReturn = () => {
  const { costs, investmentAmount } = useContext(CostsContext)

  return (
    <Card className="mb-5">
      <Card.Body
        className={classNames(styles.chartCard, 'text-center', 'text-lg-left')}
      >
        <div className="px-5">
          <Card.Title as="h4">Forventet afkast</Card.Title>
          <Text as="p" variant="secondary">
            Grafen illustrerer forskellen på en investering på{' '}
            {numberToCurrency(investmentAmount)} via NORD ved{' '}
            {numberToPercent(costs)} i omkostninger og andre ved 2,5 % i
            omkostninger. Beregningen antager et årligt afkast på 6,5 % for
            begge investeringer.
          </Text>
        </div>
        <Chart />
      </Card.Body>
    </Card>
  )
}

export default ExpectedReturn
