import React from 'react'

const Introduction = () => (
  <>
    <h2>Introduktion</h2>

    <p>
      Denne Cookie- og Privatlivspolitik ejes og drives af NORD.investments A/S,
      herefter omtalt ved ”NORD”, “vi”, “vores” eller “os”.
    </p>
    <p>
      NORD er en digital investeringsrådgiver med tilladelse fra Finanstilsynet.
      Vi rådgiver og overvåger din personlige investeringsportefølje. Det sker i
      samarbejde med Saxo Bank A/S hvor vi faciliterer at du får depot og konto
      til din investering, ligesom vi videreformidler dine værdipapirordrer til
      Saxo Bank A/S.
    </p>
    <p>
      Vi tager behandlingen af din data meget alvorligt, hvilket vi håber at
      tydeliggøre i dette dokument, hvor vi beskriver vores cookie- og
      privatlivspolitik.
    </p>
    <p>
      Vi forbeholder os retten til at ændre dette dokument, med henblik på at
      leve op til den pågældende lovgivning.
    </p>
  </>
)

export default Introduction
