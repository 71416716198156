const questionsData = [
  {
    index: '0',
    title: 'Hvad er ikke inkludereret i prisen?',
    content: 'Alt er inkluderet i prisen.'
  },
  {
    index: '1',
    title: 'Hvornår og hvordan bliver omkostningerne trukket?',
    content:
      'Nogle bliver trukket løbende på dagsbasis og andre løbende på månedsbasis. Derudover bliver visse trukket på selve handelstidspunktet.'
  },
  {
    index: '2',
    title: 'Hvad koster det at sælge sin investering?',
    content:
      '0,31 % i samlede handelsomkostninger - disse handelsomkostninger er dog inkluderet i dine totale årlige omkostninger.'
  },
  {
    index: '3',
    title: 'Er der nogle skjulte omkostninger?',
    content:
      'Nej, alt er inkluderet. Også de skjulte omkostninger såsom de indirekte handelsomkostninger samt bid/ask spreads.'
  },
  {
    index: '4',
    title: 'Bliver det billigere jo længere tid og jo mere du sparer op?',
    content:
      'Ja og ja. Hvis dine investeringer er med en tidshorisont på mere end 10 år, bliver det billigere end de angivne totale årlige omkostninger. Derudover bliver det 0,15 % billigere i årlige omkostninger, hvis man har investeret mindst 2 millioner kr. hos NORD.'
  }
]

export default questionsData
