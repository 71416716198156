import RipplesShapes from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import appImageIE from '../../../../../assets/images/app.png'
import AppImage from '../../../../AppImage'
import BaseSection from '../../../../BaseSection'

const AppSection = () => (
  <BaseSection scrollReveal>
    <Row className="mt-6 mt-md-0 pt-0 pt-lg-6 overflow-hidden">
      <Col
        xs={12}
        md={6}
        className="text-center text-md-left order-md-12 d-flex flex-column justify-content-center"
      >
        <BaseSection.Header.Base variant="white" scrollReveal>
          Dine investeringer i en app
        </BaseSection.Header.Base>
        <BaseSection.Text scrollReveal>
          NORD.investments er en digital investeringsløsning, til dig der vil
          investere pension, frie-, eller virksomhedsmidler.
        </BaseSection.Text>
      </Col>
      <Col xs={12} md={6} className="order-md-1 my-6">
        <RipplesShapes.Background
          variant="A"
          className="w-75"
          imageForIE={appImageIE}
        >
          <div className="w-50">
            <AppImage />
          </div>
        </RipplesShapes.Background>
      </Col>
    </Row>
  </BaseSection>
)

export default AppSection
