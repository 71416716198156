import Berlingske from './Berlingske'
import Boersen from './Boersen'
import Politiken from './Politiken'

const Images = {
  Berlingske,
  Boersen,
  Politiken
}

export default Images
