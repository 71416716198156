import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Ripples from '@nordinvestments/nord-storybook/dist/components/Ripples'
import VersionB from '@nordinvestments/nord-storybook/dist/components/Ripples/graphics/B'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import {
  numbersToPercentRange,
  numberToPercent
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../BaseSection'
import GatsbyInternalLink from '../../../GatsbyInternalLink'

import styles from './CostsSection.module.scss'

const CostsSection = () => (
  <BaseSection
    className={classNames(
      'bg-white',
      'text-dark',
      'position-relative',
      styles.section
    )}
    xl="large"
    scrollReveal
  >
    <ScrollTransition.Item className={styles.ripplesWrapper}>
      <Ripples variant="base" className={styles.ripples} version={VersionB} />
    </ScrollTransition.Item>
    <Row className="text-center text-md-left">
      <Col>
        <ScrollTransition.Item>
          <Text as="h1" size="lg" className="mb-6">
            Priser
          </Text>
        </ScrollTransition.Item>
      </Col>
    </Row>
    <Row className="text-center text-md-left">
      <Col xs={12} md={6}>
        <BaseSection.Header.Base>
          <ScrollTransition.Item as="div">
            {numbersToPercentRange(
              prices.privateAndCompany.costs.base.low.value,
              prices.privateAndCompany.costs.esg.high.value
            )}
          </ScrollTransition.Item>
          <ScrollTransition.Item as="div">i omkostninger</ScrollTransition.Item>
        </BaseSection.Header.Base>
      </Col>
      <Col xs={12} md={6}>
        <ScrollTransition.Item as={BaseSection.Header.Base}>
          Høje omkostninger er gift for din opsparing
        </ScrollTransition.Item>
        <ScrollTransition.Item as={BaseSection.Text}>
          Selv 1&nbsp;-&nbsp;{numberToPercent(0.02, { fractionDigits: 0 })} for
          meget i omkostninger kan koste dig millioner i mistet afkast. Vores
          digitale platform og de automatiserede processer sørger for at holde
          udgifterne nede på et minimum.
        </ScrollTransition.Item>
        <ScrollTransition.Item>
          <BaffleButton to="/priser" as={GatsbyInternalLink} className="mt-2">
            Se vores priser
          </BaffleButton>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default CostsSection
