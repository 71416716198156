import React from 'react'

import BaseSection from '../../../../BaseSection'

import InvestmentCard from './InvestmentCard'

const InvestmentSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <InvestmentCard />
  </BaseSection>
)

export default InvestmentSection
