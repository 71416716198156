import React from 'react'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import ProductCard from './ProductCard'

const Products = () => (
  <Row className="mt-n5">
    <ProductCard urlLink="/investering" onboardingPath="privat">
      <BaseSection.Header.Base variant="white" scrollReveal>
        Privat
      </BaseSection.Header.Base>
      <BaseSection.Text variant="body" scrollReveal>
        Investér dine frie midler og få din formue til at vokse.
      </BaseSection.Text>
    </ProductCard>
    <ProductCard urlLink="/pension" onboardingPath="pension">
      <BaseSection.Header.Base variant="white" scrollReveal>
        Pension
      </BaseSection.Header.Base>
      <BaseSection.Text variant="body" scrollReveal>
        Investér din aldersopsparing, rate- eller kapitalpension.
      </BaseSection.Text>
    </ProductCard>
    <ProductCard urlLink="/virksomhed" onboardingPath="virksomhed">
      <BaseSection.Header.Base variant="white" scrollReveal>
        Virksomhed
      </BaseSection.Header.Base>
      <BaseSection.Text variant="body" scrollReveal>
        Investér og få mere ud af din formue.
      </BaseSection.Text>
    </ProductCard>
  </Row>
)

export default Products
