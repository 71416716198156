import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import classNames from 'classnames'
import React, { useContext } from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import CostsContext from '../CostsContext'

import Accordion from './Accordion'
import Chart from './Chart'
import styles from './index.module.scss'

const TotalCosts = () => {
  const { depot, isPension, isStocksOnly } = useContext(CostsContext)

  return (
    <Card className="mb-5">
      <Card.Body
        className={classNames(styles.chartCard, 'text-center', 'text-lg-left')}
      >
        <div className="px-5">
          <Card.Title as="h4">
            Fordelingen af dine totale omkostninger for {depot}
          </Card.Title>
          <Text as="p" variant="secondary">
            {isStocksOnly ? (
              <>
                De totale årlige omkostninger er baseret på en 10-årig
                tidshorisont. Har dine investeringer en længere tidshorisont, så
                bliver din pris endnu lavere.
              </>
            ) : (
              <>
                De totale årlige omkostninger er baseret på en 10-årig
                tidshorisont og herudover antages det at{' '}
                {isPension ? '5 %' : '3 %'} af porteføljen årligt rebalanceres.
                Har dine investeringer en længere tidshorisont, så bliver din
                pris endnu lavere.{' '}
                {isPension &&
                  'Risikoprofilskifte (udover ved nedtrapning) er ikke inkluderet i prisen.'}
              </>
            )}
          </Text>
        </div>
        <Row>
          <Col xs={12} lg={4}>
            <Chart />
          </Col>
          <Col
            xs={12}
            lg={8}
            className="d-flex flex-column justify-content-end px-5"
          >
            <Accordion />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default TotalCosts
