import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import * as images from './images'
import Investor from './Investor'

const InvestorCol = ({ className, ...props }) => (
  <ScrollTransition.Item
    as={Col}
    xs={12}
    sm={6}
    className={classNames(
      'd-flex',
      'justify-content-center',
      'align-items-center',
      className
    )}
    md
    {...props}
  />
)

InvestorCol.propTypes = {
  className: PropTypes.string
}

InvestorCol.defaultProps = {
  className: undefined
}

const InvestorsSection = () => (
  <BaseSection
    className="bg-white text-dark text-center position-relative"
    scrollReveal
  >
    <BaseSection.Header.SectionTop scrollReveal>
      Vores investorer
    </BaseSection.Header.SectionTop>
    <Row>
      <InvestorCol className="mb-6 mb-md-0">
        <Investor
          title="Finstart Nordic"
          image={images.FinstartNordic}
          className="w-50 w-sm-75"
        />
      </InvestorCol>
      <InvestorCol className="my-6 my-md-0">
        <Investor
          title="Capnova"
          image={images.Capnova}
          className="w-50 w-sm-75"
        />
      </InvestorCol>
      <InvestorCol className="my-6 my-md-0">
        <Investor
          title="North-East Venture"
          image={images.NorthEastVenture}
          className="w-50 w-sm-75"
        />
      </InvestorCol>
      <InvestorCol
        className="mt-6 mt-md-0"
        sm={{
          offset: 3,
          span: 6
        }}
        md={{
          offset: 0,
          span: true
        }}
      >
        <Investor
          title="AB Ventures"
          image={images.ABVentures}
          className="w-25 w-sm-75"
        />
      </InvestorCol>
    </Row>
  </BaseSection>
)

export default InvestorsSection
