import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'
import OnboardingLink from '../../../../OnboardingLink'

const MoreSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <BaseSection.Header.Base
      className="mt-6"
      variant="white"
      align="center"
      scrollReveal
    >
      Nem og hurtig oprettelse
    </BaseSection.Header.Base>
    <Row>
      <Col xs={12} lg={6} className="offset-lg-3 mb-6">
        <BaseSection.Text align="center" scrollReveal>
          Vi har gjort det nemt og simpelt at investere via NORD.investments. Vi
          klarer din oprettelse hurtigt, så du kan komme i gang.
        </BaseSection.Text>
        <ScrollTransition.Item className="text-center">
          <OnboardingLink
            component={BaffleButton}
            variant="success"
            path="privat"
          >
            Kom i gang
          </OnboardingLink>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default MoreSection
