import React from 'react'

import BaseSection from '../../../../BaseSection'

import ETFContent from './ETFContent'

const ETFSection = () => (
  <BaseSection fullScreen={false} className="overflow-hidden" scrollReveal>
    <ETFContent />
  </BaseSection>
)

export default ETFSection
