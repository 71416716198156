import Ripples from '@nordinvestments/nord-storybook/dist/components/Ripples'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../BaseSection'

const Keyword = ({ title, text, dark }) => (
  <Col xs={12} md={6} className="mb-6 mt-2">
    <BaseSection.Header.Base
      className={classNames({ 'text-white': dark })}
      level={3}
      scrollReveal
    >
      {title}
    </BaseSection.Header.Base>
    <BaseSection.Text scrollReveal>{text}</BaseSection.Text>
  </Col>
)

Keyword.defaultProps = {
  dark: false
}

Keyword.propTypes = {
  dark: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

const KeywordsSection = ({ light, dark, withRipples }) => (
  <BaseSection
    className={classNames('text-center', {
      'position-relative': withRipples,
      'bg-white': light,
      'text-dark': light,
      'bg-deep-dark': dark
    })}
    scrollReveal
  >
    {withRipples && <Ripples variant="base" fade="white" bottom />}
    <BaseSection.Header.SectionTop
      className={classNames({ 'text-white': dark })}
      scrollReveal
    >
      NORD er...
    </BaseSection.Header.SectionTop>
    <Row className="children-px-md-5">
      <Keyword
        title="Transparent"
        text={`
        Vi siger tingene som de er. Der er ingen skjulte gebyrer og ingen
        bagsider, der bliver gemt væk.
      `}
        dark={dark}
      />
      <Keyword
        title="Intelligent"
        text={`
        Vores service bygger på en gennemtestet model og er fuldstændig datadreven. Vi lader intet være op til gæt og gode fornemmelser.
      `}
        dark={dark}
      />
    </Row>
    <Row className="children-px-md-5">
      <Keyword
        title="For alle"
        text={`
        Vi har en let og brugervenlig platform, der gør alle i stand til at
        investere med samme strategi, som succesrige investorer og store
        virksomheder har gjort i mange år.
      `}
        dark={dark}
      />
      <Keyword
        title="Uafhængige"
        text={`
        Alle vores porteføljer er uvildigt sammensat, og vi modtager ingen
        provision. Der er ingen skjult dagsorden, og vores anbefalinger er
        altid i kundens bedste interesse.
      `}
        dark={dark}
      />
    </Row>
  </BaseSection>
)

KeywordsSection.defaultProps = {
  dark: false,
  light: false,
  withRipples: false
}

KeywordsSection.propTypes = {
  dark: PropTypes.bool,
  light: PropTypes.bool,
  withRipples: PropTypes.bool
}

export default KeywordsSection
