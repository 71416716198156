import CardWithRippleImage from '@nordinvestments/nord-storybook/dist/components/CardWithRippleImage'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'
import styles from '../../../CardWithImage.module.scss'

import BoardMembersImage from './BoardMembersImage'

const InvestmentAdvisorSection = () => (
  <BaseSection height="half" scrollReveal>
    <Row>
      <ScrollTransition.Item as={Col}>
        <CardWithRippleImage
          image={<BoardMembersImage />}
          imageAlign="right"
          className="bg-deep-dark"
          fullHeightImage
        >
          <BaseSection.Header.Base variant="white" scrollReveal>
            NORD er din digitale
            <span className={classNames('d-block', styles.notBreakingWord)}>
              investeringsrådgiver
            </span>
          </BaseSection.Header.Base>
          <BaseSection.Text variant="body" className="mb-0" scrollReveal>
            NORD.investments er en digital investeringsløsning, til dig der vil
            investere pension, frie- eller virksomhedsmidler. Vi har gjort det
            nemt og simpelt at investere til lave omkostninger.
          </BaseSection.Text>
        </CardWithRippleImage>
      </ScrollTransition.Item>
    </Row>
  </BaseSection>
)

export default InvestmentAdvisorSection
