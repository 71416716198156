const openPositionsData = [
  {
    index: 0,
    title: 'Junior Investments Account Manager',
    employment: 'Deltid',
    link: 'https://thehub.io/jobs/60bdee76166b7d41a52b1fd7',
    defaultOpenPosition: false
  },
  {
    index: 1,
    title: 'Head of Legal & Compliance',
    employment: 'Fuldtid',
    link: 'https://thehub.io/jobs/60b0cb5324f1ce6b37bee848',
    defaultOpenPosition: false
  },
  {
    index: 2,
    title: 'Søg uopfordret',
    employment: 'Deltid / Fuldtid',
    link: 'mailto:hello@nord.investments',
    defaultOpenPosition: true
  }
]

export default openPositionsData
