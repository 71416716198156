import React from 'react'

import FAQSection from '../../../FAQSection'

import CostsSection from './CostsSection'
import questionsData from './questionsData'

const Sections = () => (
  <>
    <CostsSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
