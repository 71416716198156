import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

const HeroTop = React.forwardRef(
  ({ children, className, level, scrollReveal, ...props }, ref) => {
    let content = (
      <h1
        className={classNames('mb-4', 'h6', 'font-size-md', className)}
        ref={ref}
        {...props}
      >
        {children}
      </h1>
    )

    if (scrollReveal) {
      content = <ScrollTransition.Item>{content}</ScrollTransition.Item>
    }

    return content
  }
)

HeroTop.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string.isRequired,
  level: PropTypes.number,
  scrollReveal: PropTypes.bool
}

HeroTop.defaultProps = {
  className: undefined,
  level: 2,
  scrollReveal: undefined
}

HeroTop.displayName = 'BaseSection.Header.HeroTop'

export default HeroTop
