import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'

import BoardMembersImage from './BoardMembersImage'
import boardMembersImageIE from './images/board-members-ie.png'

const ImpartialitySection = () => (
  <BaseSection scrollReveal>
    <SectionWithOverflowImage
      image={<BoardMembersImage />}
      imageForIE={boardMembersImageIE}
      imageAlign="right"
      variant="C"
      hasLines
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Uvildighed
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        Hos NORD.investments har vi ikke egne produkter. Vi får heller ingen
        provision for de produkter vi rådgiver om. Det gør vores rådgivning 100%
        uvildig.
      </BaseSection.Text>
    </SectionWithOverflowImage>
  </BaseSection>
)

export default ImpartialitySection
