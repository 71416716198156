import anime from 'animejs'
import React, { useEffect, useState } from 'react'

import Section from '../Section'

import { ReactComponent as Animation } from './Animation.svg'
import InfoContent from './InfoContent'

const RebalancingSection = () => {
  const [animationParams, setAnimationParams] = useState([])

  useEffect(() => {
    const containerElement = document.getElementById('price-animation')
    const kursOuterCircle = containerElement
      .getElementById('price-outer-circle')
      .getElementsByTagName('line')
    const kursInnerCircle = containerElement
      .getElementById('price-inner-circle')
      .getElementsByTagName('line')
    const kursKompas = containerElement.getElementById('price-commas')

    const coordinates = kursKompas.getBBox()
    const centerX = coordinates.x + coordinates.width / 2
    const centerY = coordinates.y + coordinates.height / 2
    const center = `${centerX}px ${centerY}px`

    setAnimationParams([
      {
        targets: kursOuterCircle,
        opacity: [0, 0.33],
        duration: 250,
        delay: anime.stagger(10)
      },
      {
        targets: kursInnerCircle,
        opacity: [0, 0.33],
        duration: 250
      },
      {
        targets: kursKompas,
        opacity: [0, 1],
        rotate: [90 * 6, 0],
        scale: [0, 1],
        transformOrigin: [center, center],
        duration: 750
      }
    ])
  }, [])

  return (
    <Section
      animationParams={animationParams}
      animationElement={<Animation />}
      infoElement={<InfoContent />}
      title="Vedligeholdes løbende"
      contentText={`
        Din portefølje overvåges, og når der opstår større stigninger og/eller
        fald i fondene, rebalanceres den systematisk. Der rebalanceres
        som udgangspunkt 1 gang om året - rebalancering sker når det er
        nødvendigt og kan teoretisk set også være oftere end 1 gang.
      `}
      buttonText="Hvad er rebalancering?"
    />
  )
}

export default RebalancingSection
