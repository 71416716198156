import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaseSection from '../../../BaseSection'

const AboutSection = () => (
  <BaseSection height="full" scrollReveal>
    <Row>
      <Col xs={12} md={6} className="pr-md-5 text-center text-md-left">
        <ScrollTransition.Item as={BaseSection.Header.Base} variant="white">
          NORD er en datadreven service til langsigtet investering.
        </ScrollTransition.Item>
      </Col>
      <Col xs={12} md={6} className="pl-md-5 text-center text-md-left">
        <BaseSection.Text scrollReveal>
          NORD.investments A/S er et dansk firma, der blev startet som en
          reaktion på de høje omkostninger banker og investeringsforeninger
          tager for at hjælpe deres kunder med at investere.
        </BaseSection.Text>
        <BaseSection.Text scrollReveal>
          I stedet for at have dyre rådgivere og et stort filialnetværk har NORD
          en simpel digital platform, som afvikles i samarbejde med
          partnerbanken Saxo Bank. Det meste klares automatisk, og de praktiske
          opgaver af eksperter kan derfor holdes på et minimum.
        </BaseSection.Text>
        <BaseSection.Text scrollReveal>
          Alle investeringsplaner bliver sammensat af nøje udvalgte ETF’ere. Når
          du investerer, er det derfor ikke i enkelte aktier eller obligationer,
          men i stedet i en række fonde, der spreder din risiko og følger
          markedet.
        </BaseSection.Text>

        <ScrollTransition.Item
          as={IconButton}
          icon={ArrowRight}
          variant="success"
          className="mt-4"
          href="https://calendly.com/nord-investments/intro"
          target="_blank"
          rel="noopener noreferrer"
        >
          Book Intro
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default AboutSection
