import RipplesShapes from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import appImageIE from '../../../../../assets/images/app.png'
import AppImage from '../../../../AppImage'
import BaseSection from '../../../../BaseSection'
import styles from '../../../RippleShapeOverflowFix.module.scss'

const SolutionSection = () => (
  <BaseSection scrollReveal>
    <Row
      className={classNames(
        styles.overflow,
        'my-lg-6',
        'py-lg-6',
        'flex-column-reverse',
        'flex-lg-row'
      )}
    >
      <Col xs={12} lg={6} className="my-6 py-6">
        <RipplesShapes.Background
          variant="B"
          className="w-75"
          imageForIE={appImageIE}
        >
          <div className="w-50">
            <AppImage />
          </div>
        </RipplesShapes.Background>
      </Col>
      <Col
        xs={12}
        lg={6}
        className="text-center text-lg-left d-flex flex-column justify-content-center"
      >
        <BaseSection.Header.Base variant="white" scrollReveal>
          En enkel løsning
        </BaseSection.Header.Base>
        <BaseSection.Text scrollReveal>
          NORD.investments er en digital investeringsløsning, til dig der vil
          investere pensions-, frie-, og virksomhedsmidler.
        </BaseSection.Text>
      </Col>
    </Row>
  </BaseSection>
)

export default SolutionSection
