import React from 'react'

import AndersImage from './AndersImage'
import { ReactComponent as Boersen } from './images/Boersen.svg'
import { ReactComponent as BT } from './images/BT.svg'
import business from './images/business.png'
import ekstrabladet from './images/ekstrabladet.png'
import { ReactComponent as Forbes } from './images/Forbes.svg'
import { ReactComponent as Politiken } from './images/Politiken.svg'
import techsavvy from './images/techsavvy.png'
import tv2 from './images/tv2.png'

const articles = [
  {
    pressImage: <img src={techsavvy} alt="TechSavvy" />,
    title:
      'Robotrådgiveren Nord Investments går på børsen med Nordens største fintech-fond i ryggen',
    url:
      'https://techsavvy.media/robotraadgiveren-nord-investments-gaar-paa-boersen-med-nordens-stoerste-fintech-fond-i-ryggen/',
    date: new Date(2021, 4, 6),
    isHighlighted: false
  },
  {
    pressImage: <img src={ekstrabladet} alt="Ekstrabladet" />,
    title:
      'Danskerne har syv milliarder stående: - Et oldnordisk og bøvlet system',
    url:
      'https://ekstrabladet.dk/nyheder/samfund/danskerne-har-syv-milliarder-staaende-et-oldnordisk-og-boevlet-system/8553585',
    date: new Date(2021, 4, 5),
    isHighlighted: false
  },
  {
    pressImage: <img src={tv2} alt="TV2" />,
    title:
      'Aktier er blevet allemandseje – Vinaftener, kvindeklubber og robotter er en del af årsagen',
    url:
      'https://nyheder.tv2.dk/penge/2021-04-25-aktier-er-blevet-allemandseje-vinaftener-kvindeklubber-og-robotter-er-en-del-af',
    date: new Date(2021, 3, 25),
    isHighlighted: false
  },
  {
    pressImage: <BT />,
    title:
      'Fik nok af minusrenter – nu svulmer Saras formue: Jeg er ret godt tilfreds',
    url:
      'https://www.bt.dk/samfund/fik-nok-af-minusrenter-nu-svulmer-saras-formue-jeg-er-ret-godt-tilfreds',
    date: new Date(2020, 11, 23),
    isHighlighted: false
  },
  {
    pressImage: <Boersen />,
    title: 'Minusrenter skubber penge ind i investeringsrobot',
    url:
      'https://borsen.dk/nyheder/finans/minusrenter-skubber-penge-ind-i-investeringsrobot',
    date: new Date(2020, 11, 4),
    isHighlighted: false
  },
  {
    pressImage: <Forbes />,
    title: '29-årig stormer ind på Forbes-liste',
    url:
      'https://www.forbes.com/sites/daviddawkins/2020/03/17/forbes-under-30-europe-meet-the-european-finance-leaders-of-tomorrow',
    date: new Date(2020, 2, 17),
    isHighlighted: false
  },
  {
    pressImage: <Boersen />,
    title: 'Kunder strømmer til robotløsninger: Leverede store afkast i 2019',
    url:
      'https://borsenpro.dk/finans/nyheder/kunder-strommer-til-robotlosninger-leverede-store-afkast-i-2019',
    date: new Date(2020, 0, 24),
    isHighlighted: true,
    description:
      'De mange forskellige digitale investeringsrobotter leverede solide afkast i 2019, som da også var et sandt jubelår for de globale aktiemarkeder og dermed gav gode muligheder for at skabe afkast for passivt forvaltede ordninger.',
    image: AndersImage
  },
  {
    pressImage: <img src={business} alt="Berlingske Business" />,
    title:
      'Robotrådgiverne vrimler frem – kan bankernes nemme løsning betale sig?',
    url:
      'https://www.berlingske.dk/dine-penge/robotraadgiverne-vrimler-frem-kan-bankernes-nemme-loesning-betale-sig',
    date: new Date(2019, 5, 11),
    isHighlighted: false
  },
  {
    pressImage: <Politiken />,
    title:
      'Nemt, hurtigt og (relativt) billigt: Få en robot til at investere dine sparepenge',
    url:
      'https://politiken.dk/forbrugogliv/art6669593/F%C3%A5-en-robot-til-at-investere-dine-sparepenge',
    date: new Date(2018, 7, 25),
    isHighlighted: false
  }
]

export default articles
