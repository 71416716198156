import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const Description = () => (
  <Row className="pb-lg-5">
    <Col>
      <ScrollTransition.Item as={Row}>
        <Col xs={12} lg={6} className="text-center text-lg-left">
          <BaseSection.Header.Base variant="white" scrollReveal>
            Fordele ved wealth
          </BaseSection.Header.Base>
          <BaseSection.Text variant="body" className="pr-5" scrollReveal>
            Med NORD Wealth får du endnu lavere omkostninger og en dedikeret
            personlig kontaktperson, der prioriterer dig og dine spørgsmål.
          </BaseSection.Text>
        </Col>
        <Col xs={12} lg={6}>
          <CheckList className="text-uppercase">
            <ScrollTransition.Item as={CheckList.Item}>
              Lavere omkostninger
            </ScrollTransition.Item>
            <ScrollTransition.Item as={CheckList.Item}>
              Personlig kontaktperson
            </ScrollTransition.Item>
            <ScrollTransition.Item as={CheckList.Item}>
              Prioriteret kundeservice
            </ScrollTransition.Item>
          </CheckList>
        </Col>
      </ScrollTransition.Item>
    </Col>
  </Row>
)

export default Description
