import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const TreesImage = (props) => {
  /* 1667 / 1782 = 0.935 - ratio needed to fit the image into the shape */
  const data = useStaticQuery(graphql`
    query {
      treesImage: file(
        relativePath: { eq: "components/TreesImage/trees.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1782, maxHeight: 1667) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.treesImage.childImageSharp.fluid} {...props} />
}

export default TreesImage
