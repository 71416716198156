import { numberToPercent } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import anime from 'animejs'
import React, { useEffect, useState } from 'react'

import Section from '../Section'

import { ReactComponent as Animation } from './Animation.svg'
import InfoContent from './InfoContent'

const StockSection = () => {
  const [animationParams, setAnimationParams] = useState([])

  useEffect(() => {
    const containerElement = document.getElementById('stock-animation')

    const aktierGraphPaths = containerElement
      .getElementById('stock-graph')
      .getElementsByTagName('polyline')
    const aktierGraphsPaths = containerElement
      .getElementById('stock-graphs')
      .getElementsByTagName('polyline')
    const aktierChartPaths = containerElement
      .getElementById('stock-horizontal-lines')
      .getElementsByTagName('line')

    setAnimationParams([
      {
        targets: aktierGraphPaths,
        strokeDashoffset: [0, 770],
        opacity: [0, 1],
        duration: 1250
      },
      {
        targets: aktierGraphsPaths,
        opacity: [0, 0.25],
        duration: 750,
        delay: anime.stagger(100)
      },
      {
        targets: aktierChartPaths,
        translateX: ['-100%', '0%'],
        opacity: [0, 0.33],
        duration: 250,
        delay: anime.stagger(100)
      }
    ])
  }, [])

  return (
    <Section
      animationParams={animationParams}
      animationElement={<Animation />}
      infoElement={<InfoContent />}
      title="Udvælgelse af aktier virker ikke"
      contentText={`
        Mange drømmer om at finde og time købet af den næste vinder aktie.
        Problemet er at hverken professionelle eller private kan det. Kun ${numberToPercent(
          0.01,
          { fractionDigits: 0 }
        )}
        lykkedes med at slå markedets afkast.
      `}
      buttonText="Derfor slår ingen markedet"
    />
  )
}

export default StockSection
