import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import { fullPageTransitionDuration } from '@nordinvestments/nord-storybook/dist/components/FullPageTransition'
import Hero from '@nordinvestments/nord-storybook/dist/components/Hero'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import SaxoLogo from '@nordinvestments/nord-storybook/dist/components/SaxoLogo'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import VideoModal from '@nordinvestments/nord-storybook/dist/components/VideoModal'
import { ref as refPropType } from 'airbnb-prop-types'
import anime from 'animejs'
import classNames from 'classnames'
import React, { useState, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Eye from 'react-feather/dist/icons/eye'
import Play from 'react-feather/dist/icons/play'

import BaseSection from '../../../BaseSection'
import OnboardingLink from '../../../OnboardingLink'

import styles from './index.module.scss'
import NasdaqLogo from './NasdaqLogo'
import VideoThumbnail from './VideoThumbnail'

const HomeHero = React.forwardRef(({ animationRef, ...props }, ref) => {
  const [showTVSpot, setShowTVSpot] = useState(false)
  const graphicID = 'graphic-container'
  const graphicWrapperID = 'graphic-wrapper'

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    animationRef.current = anime({
      targets: `#${graphicWrapperID}`,
      scale: [1, 1.5],
      easing: 'linear',
      autoplay: false
    })

    anime({
      targets: `#${graphicID}`,
      scale: [0, 1],
      opacity: [0, 1],
      rotate: [22.5, 0],
      duration: 2500,
      delay: fullPageTransitionDuration,
      easing: 'easeOutCubic'
    })
    anime({
      targets: `#${graphicID} path`,
      opacity: [0, 1],
      duration: 750,
      delay: anime.stagger(50, { start: fullPageTransitionDuration }),
      easing: 'easeOutCirc'
    })

    return () => {
      anime.remove(`#${graphicWrapperID}`)
      anime.remove(`#${graphicID}`)
      anime.remove(`#${graphicID} path`)
    }
  }, [animationRef])

  const handleShowTVSpot = (event) => {
    event.preventDefault()
    setShowTVSpot(true)
  }

  const handleHideTVSpot = () => setShowTVSpot(false)

  return (
    <BaseSection.Hero
      ref={ref}
      backgroundClass={styles.heroBg}
      backgroundImage={
        <Hero.Background
          graphicID={graphicID}
          graphicWrapperID={graphicWrapperID}
        />
      }
      imageClassName={classNames(
        styles.backgroundImage,
        'd-none',
        'd-lg-block'
      )}
      fullWidth
      {...props}
    >
      <Row>
        <Col xs={12} lg={6}>
          <ScrollTransition.Item>
            <BaseSection.Header.Base
              level={1}
              className="text-center text-lg-left"
              variant="white"
              isHeroHeader
            >
              Tryghed for din investering <br /> i gode og dårlige tider
            </BaseSection.Header.Base>
            <BaseSection.Text className="text-center text-lg-left">
              Tag vores test og se hvordan du bør investere.{' '}
              <br className="d-none d-xl-block" />
              Det er helt gratis og uforpligtende.
            </BaseSection.Text>
            <div className="d-none d-lg-block">
              <OnboardingLink variant="success" className="my-4 font-size-md" />
            </div>
            <div className="text-center d-lg-none">
              <OnboardingLink variant="success" className="mb-4" />
            </div>
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} lg={6}>
          <ScrollTransition.Item as="div" className="position-relative">
            <a href="#tvspot" onClick={handleShowTVSpot}>
              <div className={styles.videoThumbnailImage} id="tvspot">
                <VideoThumbnail className="shadow-lg" />
                <Text
                  as="p"
                  size="md"
                  variant="white"
                  transform="uppercase"
                  className={styles.topLeftText}
                >
                  se vores tv spot:
                  <br />
                  din ekstra million
                </Text>
                <Circle variant="success" className={styles.videoPlay}>
                  <Icon icon={Play} className="text-sucess" />
                </Circle>
              </div>
            </a>
            <VideoModal
              show={showTVSpot}
              onHide={handleHideTVSpot}
              videoID="TZxM2DgGTFM"
              id="tvspot"
            />
          </ScrollTransition.Item>
        </Col>
      </Row>
      <Row
        className={classNames(
          'justify-content-around',
          'children-my-4',
          'children-my-lg-0',
          styles.partnersRow
        )}
      >
        <Col xs={12} md={6} lg={3} className="text-center">
          <ScrollTransition.Item>
            <div className="text-monospace text-uppercase">
              <div className="font-size-sm text-light-white mb-3">
                Under tilsyn af
              </div>
              <div
                className={classNames(
                  styles.finanstilsynetLogoContainer,
                  'text-white',
                  'd-flex',
                  'flex-column',
                  'justify-content-center',
                  'align-items-center'
                )}
              >
                <Icon
                  icon={Eye}
                  size={3}
                  strokeWidth={1}
                  className={styles.finanstilsynetLogo}
                />
                <span className="mt-4">Finanstilsynet</span>
              </div>
            </div>
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} md={6} lg={3} className="text-center">
          <ScrollTransition.Item>
            <div className="text-monospace text-uppercase text-light-white font-size-sm mb-3">
              I samarbejde med
            </div>
            <SaxoLogo height={64} />
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} md={6} lg={3} className="text-center">
          <ScrollTransition.Item>
            <div className="text-monospace text-uppercase text-light-white font-size-sm mb-3">
              Bedst i kategorien
            </div>
            <div className={styles.trustpilotContainer}>
              <div
                className="trustpilot-widget"
                data-locale="da-DK"
                data-template-id="53aa8807dec7e10d38f59f32"
                data-businessunit-id="58f9cd750000ff0005a0e899"
                data-style-height="115px"
                data-style-width="100%"
                data-theme="dark"
                data-stars="1,2,3,4,5"
              >
                <a
                  href="https://dk.trustpilot.com/review/nord.investments"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Trustpilot
                </a>
              </div>
            </div>
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} md={6} lg={3} className="text-center">
          <ScrollTransition.Item>
            <div className="text-monospace text-uppercase text-light-white font-size-sm mb-3">
              Børsnoteret på first north
            </div>
            <NasdaqLogo />
          </ScrollTransition.Item>
        </Col>
      </Row>
    </BaseSection.Hero>
  )
})

HomeHero.propTypes = {
  animationRef: refPropType()
}

HomeHero.defaultProps = {
  animationRef: undefined
}

HomeHero.displayName = 'HomeHero'

export default HomeHero
