import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const AppImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      appImage: file(relativePath: { eq: "components/AppImage/app.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.appImage.childImageSharp.fluid} {...props} />
}

export default AppImage
