import React from 'react'

import BaseSection from '../../../../BaseSection'

import PensionContent from './PensionContent'

const AdvantagesSection = () => (
  <BaseSection scrollReveal>
    <PensionContent />
  </BaseSection>
)

export default AdvantagesSection
