import React from 'react'

const Collection = () => (
  <>
    <h2>Indsamling</h2>

    <p>
      NORD indsamler, behandler og lagrer dine persondata i det omfang du
      indtaster, sender eller på anden måde giver NORD dine persondata.
      <br />I visse tilfælde vil NORD også indsamle data fra offentlige
      registre. Dette kan f.eks. være hvorvidt et selskab er momsregistreret
      eller ej.
    </p>
    <p>
      Den persondata vi indsamler kan kategoriseres i følgende kategorier, men
      er ikke begrænset til:
    </p>
    <ul>
      <li>Personlige oplysninger, heriblandt eks. navn og CPR-nummer.</li>
      <li>Oplysninger om din privatøkonomi, eks. månedlig indkomst.</li>
      <li>Billedlegitimation, eks. kørekort.</li>
    </ul>

    <p>
      Formålet med indsamling af personoplysninger kan være et eller flere af
      følgende, men er ikke begrænset til:
    </p>
    <ul>
      <li>
        Etablering, vedligeholdelse og eventuel afslutning af bruger- og
        kundeforhold hos henholdsvis NORD og Saxo Bank A/S.
      </li>
      <li>Tilpasning af kommunikation og markedsføring til dig.</li>
      <li>
        Tilpasning af samarbejdspartneres kommunikation og markedsføring til
        dig.
      </li>
      <li>Generel administration af din relation til NORD.</li>
      <li>
        Opfyldelse af lovkrav, herunder den gældende lov som fx Lov om finansiel
        virksomhed, Markedsføringsloven samt Databehandlingsloven.
      </li>
    </ul>
    <p>NORD er ansvarlig for behandlingen af dine oplysninger.</p>
  </>
)

export default Collection
