import CardWithRippleImage from '@nordinvestments/nord-storybook/dist/components/CardWithRippleImage'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import WindmillImage from './WindmillImage'

const InvestmentCard = () => (
  <Row>
    <ScrollTransition.Item as={Col}>
      <CardWithRippleImage
        className="bg-deep-dark shadow overflow-hidden"
        image={<WindmillImage />}
        imageAlign="left"
      >
        <BaseSection.Header.Base variant="white">
          Mere end en grøn investering
        </BaseSection.Header.Base>
        <BaseSection.Text variant="body">
          Med en ansvarlig portefølje, investerer du ikke kun i{' '}
          <a
            href="https://blog.nord.investments/baeredygtig-investering/"
            className="link-white"
          >
            bæredygtighed
          </a>{' '}
          og den grønne omstilling. Du investerer også i virksomheder, der
          prioriterer etik og menneskerettigheder. Samtidigt fravælges
          virksomheder der ikke tager afstand til kontroversielle emner som
          bl.a. atomkraft, våben og børnearbejde.
        </BaseSection.Text>
      </CardWithRippleImage>
    </ScrollTransition.Item>
  </Row>
)

export default InvestmentCard
