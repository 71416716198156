import React from 'react'

import BaseSection from '../../../../BaseSection'

const ReturnText = () => (
  <>
    <BaseSection.Header.Base
      variant="white"
      className="text-center text-lg-left"
    >
      Den bedste måde at forbedre dit afkast
    </BaseSection.Header.Base>
    <BaseSection.Text className="text-center text-lg-left">
      En af de sikreste veje til højere afkast er lavere omkostninger. Man
      investerer trods alt, for at opnå det højeste afkast. Jo mindre du betaler
      i omkostninger, desto flere penge havner i din lomme. Hvad der er sparet,
      er tjent.
    </BaseSection.Text>
  </>
)

export default ReturnText
