import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import SideOverlay from '@nordinvestments/nord-storybook/dist/components/SideOverlay'
import useId from '@nordinvestments/nord-storybook/dist/hooks/useId'
import useInView from '@nordinvestments/nord-storybook/dist/hooks/useInView'
import anime from 'animejs'
import { times, clamp, omit } from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState, useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import styles from './Section.module.scss'

const THRESHOLD_STEPS = 20
const THRESHOLDS = times(
  THRESHOLD_STEPS + 1,
  (index) => index / THRESHOLD_STEPS
)

const checkAnimation = (anim, scrollProgress, buffer = 0) => {
  if (
    (anim.direction === 'normal' &&
      anim.progress > scrollProgress.current + buffer) ||
    (anim.direction === 'reverse' &&
      anim.progress < scrollProgress.current - buffer)
  ) {
    return false
  }

  return true
}

const Section = ({
  animationParams,
  animationElement,
  infoElement,
  title,
  contentText,
  buttonText
}) => {
  const animation = useRef(null)
  const contentAnimation = useRef(null)
  const scrollProgress = useRef(0)
  const [showSideOverlay, setShowSideOverlay] = useState(false)
  const id = useId('story-board-section')

  useEffect(() => {
    if (animationParams.length === 0) return () => {}

    animation.current = anime.timeline({
      easing: 'easeOutQuad',
      autoplay: false,
      change: (anim) => {
        if (!checkAnimation(anim, scrollProgress)) anim.pause()
      }
    })

    animationParams.forEach((animationParam) =>
      animation.current.add(animationParam, 0)
    )

    return () =>
      animationParams.forEach((animationParam) =>
        anime.remove(animationParam.targets)
      )

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [
    JSON.stringify(
      animationParams.map((animationParam) => omit(animationParam, 'targets'))
    )
  ])
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    const targets = `#${id}`
    contentAnimation.current = anime({
      targets,
      easing: 'easeOutQuad',
      duration: 100,
      autoplay: false,
      opacity: [0, 1],
      translateY: ['12.5%', 0]
    })

    return () => anime.remove(targets)
  }, [id])

  const handleInView = useCallback(({ inView, entry, direction }) => {
    const { intersectionRatio } = entry
    const ratio = clamp(intersectionRatio * 2, 0, 1)
    const outOfView =
      inView === false ||
      intersectionRatio <= 0 ||
      (direction === 'bottom' && intersectionRatio <= 0.5)

    if (outOfView) {
      scrollProgress.current = direction === 'top' ? 0 : 100
    } else {
      scrollProgress.current = ratio * 100

      contentAnimation.current.seek(contentAnimation.current.duration * ratio)
    }

    if (!checkAnimation(animation.current, scrollProgress, 10))
      animation.current.reverse()

    if (!animation.current.playing) {
      animation.current.completed = false
      animation.current.play()
    }
  }, [])

  const containerRef = useInView(handleInView, {
    threshold: THRESHOLDS,
    offsetRatio: 0.5,
    animationParams
  })

  const handleShowSideOverlay = () => setShowSideOverlay(true)
  const handleHideSideOverlay = () => setShowSideOverlay(false)

  return (
    <BaseSection md="large" ref={containerRef} className="position-relative">
      <Row>
        <div className={styles.animationContainer}>{animationElement}</div>
        <Col
          xs={12}
          md={{ offset: 6, span: 6 }}
          className="text-center text-md-left"
          id={id}
        >
          <BaseSection.Header.Base level={2} variant="white">
            {title}
          </BaseSection.Header.Base>
          <BaseSection.Text>{contentText}</BaseSection.Text>
          <BaffleButton
            variant="success"
            className="mt-3"
            onClick={handleShowSideOverlay}
          >
            {buttonText}
          </BaffleButton>
        </Col>
        <SideOverlay show={showSideOverlay} onHide={handleHideSideOverlay}>
          {infoElement}
        </SideOverlay>
      </Row>
    </BaseSection>
  )
}

Section.propTypes = {
  animationParams: PropTypes.arrayOf(PropTypes.object).isRequired,
  animationElement: PropTypes.element.isRequired,
  infoElement: PropTypes.element.isRequired,
  title: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired
}

export default Section
