import React from 'react'

import BaseSection from '../../../../../BaseSection'

const InfoOverlay = () => (
  <>
    <BaseSection.Header.Base>Hvad er en ETF?</BaseSection.Header.Base>
    <BaseSection.Text>
      ETF står for ’Exchange Traded Fund’ – fonde, der kan handles på børsen,
      som var de et børsnoteret firma. Når du investerer i en ETF, får du en
      lille del af tusindvis værdipapirer, uden du skal ud og købe hver enkelt
      af dem. Det betyder, at du kan sprede dine investeringer uden at skulle
      betale en masse købsgebyrer.
    </BaseSection.Text>
    <BaseSection.Text>
      ETF’erne er en passiv investeringsform, da en ETF lægger sig fast op ad et
      indeks eller en pulje af værdipapirer – f.eks. statsobligationer, et
      bestemt land eller regions aktier eller en industri. Der findes f.eks. en
      ETF for 500 virksomheder i USA, en for de 50 største virksomheder i Europa
      og der kunne også være en for det danske C20-indeks (selvom den ikke
      findes endnu). Der er mere end 2500 forskellige ETF’ere på børserne over
      hele verden.
    </BaseSection.Text>
    <BaseSection.Header.Base>
      Hvorfor investere i ETF’ere?
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Et marked stiger og falder, og der kan være store ændringer fra den ene
      dag til den anden. Der kan også opstå finanskriser og IT-bobler, som slår
      markedet helt i bund i en flerårig periode. Hvis vi zoomer helt ud og
      kigger på kurserne over en længere årrække eller måske helt tilbage til
      starten af markederne, er der dog ingen tvivl om, hvilken retning kurserne
      er gået i. De stiger langsomt med større eller mindre bump på vejen.
    </BaseSection.Text>
    <BaseSection.Text>
      ETF’ere følger markederne, og hvis udviklingen forsætter, som den altid
      har gjort, vil ETF’erne også stige tilsvarende i værdi.
    </BaseSection.Text>
    <BaseSection.Header.Base>Sådan er ETF’erne udvalgt</BaseSection.Header.Base>
    <BaseSection.Text>
      Din portefølje vil indeholde 8 forskellige ETF’ere, der dækker forskellige
      aktier, obligationer og fast ejendom. Derudover holder vi konstant øje
      med, om der er flere eller andre ETF’ere, som passer bedre til
      investeringsplanerne.
    </BaseSection.Text>
    <BaseSection.Text>
      Når der vælges en ETF, er det vigtigt at:
    </BaseSection.Text>
    <BaseSection.Text as="ul" className="children-my-2">
      <li>
        Værdipapirerne i ETF’en er attraktive – især dem, der fylder meget i
        indekset
      </li>
      <li>ETF’en matcher indeksets afkast så præcist som muligt</li>
      <li>Omkostningerne er lave</li>
      <li>
        Forskellen i omkostninger ved køb og salg af ETF’en er den mindste
      </li>
      <li>ETF’en er fysisk – så den er sikrere for investorerne</li>
      <li>Finanstilsynet har godkendt ETF’en</li>
    </BaseSection.Text>
    <BaseSection.Header.Base>Risici ved ETF’er</BaseSection.Header.Base>
    <BaseSection.Text>
      Det kan påvirke din opsparing hvis de værdipapirer du ejer er i en anden
      valuta end danske kroner.
    </BaseSection.Text>
    <BaseSection.Text>
      De ETF’er, der bruges, bliver handlet i Euro, som er tæt bundet op på den
      danske krones kurs. De værdipapirer som ligger inde i ETF´ en handles i
      forskellige valutaer. Den primære valuta eksponering ud over Euro er
      derfor amerikanske dollars, hvilket betyder at din portefølje vil blive
      påvirket i kursen mellem amerikanske dollars og Euro’en. Det er den samme
      valutarisiko, som hvis du for eksempel køber amerikanske aktier som Apple
      og Google.
    </BaseSection.Text>
    <BaseSection.Text>
      Man kan eliminere valutarisikoen ved at anvende en såkaldt ”hedge”. Vi
      anvender ikke hedging, da valutaeksponeringen bidrager med en øget
      spredning i porteføljen. Derudover er hedging forbundet med yderligere
      omkostninger.
    </BaseSection.Text>
    <BaseSection.Text>
      Det er usandsynligt, men hvis en ETF-udbyder skulle gå konkurs, er der en
      risiko for, at du må sælge ud, før du havde regnet med. Dine investeringer
      stadig dine, men alle papirerne bliver solgt, og du får værdien udbetalt.
    </BaseSection.Text>
  </>
)

export default InfoOverlay
