import Base from './Base'
import HeroTop from './HeroTop'
import SectionTop from './SectionTop'

const Header = {
  Base,
  HeroTop,
  SectionTop
}

export default Header
