import React from 'react'

import BoardSection from './BoardSection'
import DocumentsSection from './DocumentsSection'
import FinanceCalendarSection from './FinanceCalendarSection'
import InvestmentAdvisorSection from './InvestmentAdvisorSection'
import KnowMoreSection from './KnowMoreSection'
import LatestAnnouncementsSection from './LatestAnnouncementsSection'

const Sections = () => (
  <>
    <InvestmentAdvisorSection />
    <DocumentsSection />
    <LatestAnnouncementsSection />
    <FinanceCalendarSection />
    <BoardSection />
    <KnowMoreSection />
  </>
)

export default Sections
