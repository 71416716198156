import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import Testimonial from './Testimonial'
import { ReactComponent as Trustpilot } from './Trustpilot.svg'
import { ReactComponent as TrustpilotStar } from './TrustpilotStar.svg'

const TestimonialsSection = () => (
  <BaseSection className="bg-primary text-white" height="large" scrollReveal>
    <BaseSection.Header.SectionTop>
      Kunderne mener...
    </BaseSection.Header.SectionTop>
    <div>
      <Row className="mx-md-n5">
        <Testimonial
          text={`
          Havde fået betalt for meget ind på min Ratepension. Mette hjalp mig
          meget kompetent igennem det. Super service og gode investeringer med
          godt afkast!
        `}
          author="Pia"
          date={new Date(2021, 4, 20)}
          rating={5}
          index={0}
        />
        <Testimonial
          text={`
          Godt afkast og nem håndtering. Har benyttet det i et par år med meget
          fint afkast, og jeg behøver ikke bekymre mig om noget af det. Klar
          anbefaling herfra.
        `}
          author="Thomas"
          date={new Date(2021, 4, 11)}
          rating={5}
          index={1}
        />
        <Testimonial
          text={`
          Rigtig fint afkast - noget bedre end jeg selv har kunnet præstere ved
          egen aktiehandel. Supporten svarer hurtigt på spørgsmål og kommer
          altid med fyldestgørende svar.
        `}
          author="Nill"
          date={new Date(2021, 4, 7)}
          rating={5}
          index={2}
        />
        <Testimonial
          text={`
          God og top professionel kundeservice - fornøjelse at arbejde sammen
          med. Produktet er let gennemskueligt, transparent - uden negative
          overraskelser. Kan varmt anbefales.
        `}
          author="Zoltan"
          date={new Date(2021, 3, 29)}
          rating={5}
          index={3}
        />
        <Testimonial
          text={`
          Nord tilbyder et fremragende investeringsprodukt som alle kan anvende,
          uanset om man har forstand på børsmarkedet eller ej. Jeg har anvendt
          produktet i et år og opnået nogle rigtig fine resultater. I forbindelse
          med et huskøb skulle jeg sælge noget af min portefølje, hvilket skete
          helt problemfrit og 5 dage senere havde jeg pengene på min bankkonto.
          Desuden er deres support virkelig god og de er hurtige til at
          respondere, så 5-stjerner herfra.
        `}
          author="Thomas"
          date={new Date(2021, 3, 22)}
          rating={5}
          index={4}
        />
        <Testimonial
          text={`
          Fine afkast og altid super hurtig service. De er klar til at tage
          telefonen og besvare alt fra de helt dumme spørgsmål der skal
          forklares i lægmandstermer, til de meget komplicerede spørgsmål for
          dem der gerne vil gå mere i dybden. Og så snakker deres resultater jo
          for sig selv.
        `}
          author="Frederik"
          date={new Date(2021, 3, 15)}
          rating={5}
          index={5}
        />
      </Row>
    </div>
    <Row>
      <Col className="text-center">
        <ScrollTransition.Item as="div">
          <a
            href="https://www.trustpilot.com/review/nord.investments?languages=all"
            target="_blank"
            rel="noopener noreferrer"
            className="text-white text-uppercase text-decoration-none"
          >
            <div>
              <Trustpilot />
            </div>
            <div className="font-size-sm text-monospace mt-3">
              Vores samlede vurdering:
            </div>
            <div className="h4 mb-2">&quot;Fremragende&quot;</div>
            <div>
              <TrustpilotStar className="mx-1" />
              <TrustpilotStar className="mx-1" />
              <TrustpilotStar className="mx-1" />
              <TrustpilotStar className="mx-1" />
              <TrustpilotStar className="mx-1" />
            </div>
          </a>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default TestimonialsSection
