import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'

import styles from './index.module.scss'

const TestimonialCard = ({
  name,
  position,
  content: testimonialContent,
  image: Image,
  className,
  ...props
}) => (
  <ScrollTransition.Item
    as={Col}
    xs={12}
    lg={{ span: 8, offset: 2 }}
    className={classNames('d-flex', 'flex-column', className)}
    {...props}
  >
    <div className="flex-grow-1 d-flex align-items-center justify-content-center">
      <Card>
        <Card.Body>
          <Text as="p" align="center">
            “{testimonialContent}”
          </Text>
        </Card.Body>
        <Card.Circle className={styles.cardCircle}>
          <Image
            alt={name}
            className="w-100 h-100"
            objectFit="cover"
            objectPosition="top center"
          />
        </Card.Circle>
      </Card>
    </div>
    <div className="py-4">
      <Text as="h6" variant="white" align="center" className="mb-0 mt-4">
        {name}
      </Text>
      <Text as="p" variant="white" align="center" transform="uppercase">
        {position}
      </Text>
    </div>
  </ScrollTransition.Item>
)

TestimonialCard.defaultProps = {
  className: undefined
}

TestimonialCard.propTypes = {
  content: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.elementType.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired
}

export default TestimonialCard
