import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const AdvantageSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <Row className="mt-6">
      <Col>
        <ScrollTransition.Item as={Card} className="shadow bg-deep-dark">
          <Card.Body>
            <Row className="mx-lg-4">
              <Col xs={12} lg={6} className="text-center text-lg-left">
                <BaseSection.Header.Base variant="white" scrollReveal>
                  Fordele ved ETF'er
                </BaseSection.Header.Base>
                <BaseSection.Text variant="body" scrollReveal>
                  Fordelen ved ETF'er er, at de har meget lave årlige
                  omkostninger (har årlige omkostninger helt ned
                  til&nbsp;0,05&nbsp;%)&nbsp;samtidig med at du opnår stor
                  spredning på din investering.
                </BaseSection.Text>
              </Col>
              <Col xs={12} lg={5} className="offset-lg-1">
                <CheckList>
                  <ScrollTransition.Item as={CheckList.Item}>
                    Lave omkostninger
                  </ScrollTransition.Item>
                  <ScrollTransition.Item as={CheckList.Item}>
                    Stor spredning
                  </ScrollTransition.Item>
                  <ScrollTransition.Item as={CheckList.Item}>
                    Områdespecifik investering
                  </ScrollTransition.Item>
                </CheckList>
              </Col>
            </Row>
          </Card.Body>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default AdvantageSection
