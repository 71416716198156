import useCosts from '@nordinvestments/nord-storybook/dist/hooks/useCosts'
import React, { useState, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import Costs from './Costs'
import CostsContext from './CostsContext'
import Disclaimer from './Disclaimer'
import ExpectedInvestment from './ExpectedInvestment'
import ExpectedReturn from './ExpectedReturn'
import NordWealth from './NordWealth'
import PriceText from './PriceText'
import ReturnText from './ReturnText'
import TotalCosts from './TotalCosts'

const CostsSection = () => {
  const [investmentAmount, setInvestmentAmount] = useState(500000)
  const [depot, setDepot] = useState('frie midler')
  const [isESG, setIsESG] = useState(false)
  const [isStocksOnly, setIsStocksOnly] = useState(false)
  const isPension = depot === 'pension'

  const handleInvestmentAmountChange = (value) => setInvestmentAmount(value)
  const handleIsESGChange = (value) => setIsESG(value)
  const handleDepotChange = (value) => setDepot(value)
  const handleIsStocksOnlyChange = (value) => setIsStocksOnly(value)

  const memoizedCosts = useCosts(
    investmentAmount,
    isESG,
    isPension,
    isStocksOnly
  )

  const context = useMemo(
    () => ({
      costs: memoizedCosts,
      investmentAmount,
      isESG,
      isStocksOnly,
      isPension,
      depot,
      onInvestmentAmountChange: handleInvestmentAmountChange,
      onIsESGChange: handleIsESGChange,
      onIsStocksOnlyChange: handleIsStocksOnlyChange,
      onDepotChange: handleDepotChange
    }),
    [memoizedCosts, depot, investmentAmount, isESG, isPension, isStocksOnly]
  )

  const isNordWealth = investmentAmount >= 5e6

  return (
    <CostsContext.Provider value={context}>
      <BaseSection scrollReveal>
        <Row className="justify-content-center">
          <Col xs={12} lg={4} xl={3} className="my-4 my-lg-0">
            <ExpectedInvestment />
          </Col>
          <Col xs={12} lg={8} xl={9}>
            <NordWealth isActive={isNordWealth} />
            <Costs />
            <ReturnText />
            <ExpectedReturn />
            <PriceText />
            <TotalCosts />
            <Disclaimer />
          </Col>
        </Row>
      </BaseSection>
    </CostsContext.Provider>
  )
}

export default CostsSection
