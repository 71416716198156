/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ description, keywords, meta, title, image, pathname }) => {
  const {
    site: { siteMetadata }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            siteUrl
            siteName
            image
          }
        }
      }
    `
  )

  const metaAttributes = {
    description: description || siteMetadata.description,
    keywords: keywords || siteMetadata.keywords,
    imagePath: image || siteMetadata.image,
    imageUrl: `${siteMetadata.siteUrl}${image || siteMetadata.image}`,
    url: `${siteMetadata.siteUrl}${pathname || '/'}`,
    siteName: siteMetadata.siteName
  }

  return (
    <Helmet
      defaultTitle={siteMetadata.title}
      defer={false}
      meta={[
        {
          name: 'description',
          content: metaAttributes.description
        },
        {
          name: 'keywords',
          content: metaAttributes.keywords.join(', ')
        },
        {
          property: 'og:title',
          content: metaAttributes.title
        },
        {
          property: 'og:description',
          content: metaAttributes.description
        },
        {
          property: 'og:site_name',
          content: metaAttributes.url
        },
        {
          property: 'og:url',
          content: metaAttributes.url
        },
        {
          property: 'og:locale',
          content: 'da_DK'
        },
        {
          property: 'og:type',
          content: 'website'
        },
        {
          property: 'og:image',
          content: metaAttributes.imageUrl
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: siteMetadata.author
        },
        {
          name: 'twitter:title',
          content: metaAttributes.title
        },
        {
          name: 'twitter:description',
          content: metaAttributes.description
        }
      ].concat(meta)}
    >
      <html lang="da" />
      <title>{title}</title>
      <meta
        name="google-site-verification"
        content="2yqj4-kUwNqI0_68RvymoDwEevVqgA9RVT-Kkcv5qko"
      />
      <link rel="image_src" href={metaAttributes.imagePath} />
      <link rel="canonical" href={metaAttributes.url} />
    </Helmet>
  )
}

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  keywords: PropTypes.arrayOf(PropTypes.string),
  image: PropTypes.string,
  pathname: PropTypes.string
}

SEO.defaultProps = {
  meta: [],
  description: '',
  keywords: [],
  image: undefined,
  pathname: undefined
}

export default SEO
