import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const IntroSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <BaseSection.Header.Base
      variant="white"
      align="center"
      className="pt-6"
      scrollReveal
    >
      En bedre nutid og fremtid
    </BaseSection.Header.Base>
    <Row>
      <Col xs={12} lg={6} className="offset-lg-3">
        <BaseSection.Text align="center" scrollReveal>
          Hos NORD.investments kan du starte en ny ratepension eller flytte din
          eksisterende aldersopsparing, rate- og kapitalpension. Sørg for din
          fremtid. Investér din pension hos NORD.investments i dag.
        </BaseSection.Text>
      </Col>
    </Row>
  </BaseSection>
)

export default IntroSection
