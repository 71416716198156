import React from 'react'

import AdvantageSection from './AdvantageSection'
import ETFSection from './ETFSection'
import InvestmentSection from './InvestmentSection'
import TaxationSection from './TaxationSection'
import WhatSection from './WhatSection'

const Sections = () => (
  <>
    <WhatSection />
    <AdvantageSection />
    <TaxationSection />
    <ETFSection />
    <InvestmentSection />
  </>
)

export default Sections
