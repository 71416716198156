import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import { numbersToPercentRange } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React from 'react'

import BoardImageIE from '../../../../../assets/images/board-image-IE.png'
import BaseSection from '../../../../BaseSection'
import BoardImage from '../../../../BoardImage'

const AdvantagesContent = () => (
  <SectionWithOverflowImage
    image={<BoardImage />}
    imageAlign="right"
    imageForIE={BoardImageIE}
    variant="C"
    hasLines
  >
    <BaseSection.Header.Base
      variant="white"
      className="d-none d-lg-block"
      scrollReveal
    >
      Dine fordele ved nord
    </BaseSection.Header.Base>
    <BaseSection.Header.Base variant="white" className="d-lg-none" scrollReveal>
      Dine fordele
    </BaseSection.Header.Base>
    <BaseSection.Text scrollReveal>
      Vi rådgiver ikke om egne produkter. Vi sammensætter derimod porteføljer
      bestående af markedets bedste investeringsprodukter. Det kan vi, fordi vi
      er en uvildig rådgiver.
    </BaseSection.Text>
    <CheckList>
      <ScrollTransition.Item as={CheckList.Item}>
        Historiske afkast på op til{' '}
        {prices.privateAndCompany.historicalReturn.format} årligt (siden 1999)
      </ScrollTransition.Item>
      <ScrollTransition.Item as={CheckList.Item}>
        Den størst mulige spredning, der giver den lavest mulige risiko
      </ScrollTransition.Item>
      <ScrollTransition.Item as={CheckList.Item}>
        Altid lave omkostninger på{' '}
        {numbersToPercentRange(
          prices.privateAndCompany.costs.base.low.value,
          prices.privateAndCompany.costs.esg.high.value
        )}
      </ScrollTransition.Item>
    </CheckList>
  </SectionWithOverflowImage>
)

export default AdvantagesContent
