import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import { numbersToPercentRange } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const AdvantageSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <Row className="my-md-6 py-4">
      <Col xs={12} lg={5} className="text-center text-lg-left">
        <BaseSection.Header.Base
          variant="white"
          className="d-none d-lg-block"
          scrollReveal
        >
          Dine fordele ved nord
        </BaseSection.Header.Base>
        <BaseSection.Header.Base variant="white" className="d-lg-none">
          Dine fordele
        </BaseSection.Header.Base>
        <BaseSection.Text scrollReveal>
          Vi har gjort det nemt og enkelt at investere din virksomheds
          overskydende likviditet. Investeringen er langsigtet, men fleksibel.
          Du kan nemlig til enhver tid sælge din investering og få pengene
          tilbage på din virksomhedskonto.
        </BaseSection.Text>
      </Col>
      <Col xs={12} lg={6} className="offset-lg-1">
        <CheckList>
          <ScrollTransition.Item as={CheckList.Item}>
            Historiske afkast på op til{' '}
            {prices.privateAndCompany.historicalReturn.format} årligt (siden
            1999)
          </ScrollTransition.Item>
          <ScrollTransition.Item as={CheckList.Item}>
            Den størst mulige spredning, der giver den lavest mulige risiko
          </ScrollTransition.Item>
          <ScrollTransition.Item as={CheckList.Item}>
            Altid lave omkostninger på{' '}
            {numbersToPercentRange(
              prices.privateAndCompany.costs.base.low.value,
              prices.privateAndCompany.costs.esg.high.value
            )}
          </ScrollTransition.Item>
        </CheckList>
      </Col>
    </Row>
  </BaseSection>
)

export default AdvantageSection
