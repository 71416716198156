import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ExpandableList from '@nordinvestments/nord-storybook/dist/components/ExpandableList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../BaseSection'

const FAQCard = ({ questions }) => (
  <Row className="mb-4">
    <Col>
      <ScrollTransition.Item as={Card} bg="deep-dark" className="shadow">
        <Card.Body as={Row}>
          <Col xs={12} lg={6}>
            <BaseSection.Header.Base
              variant="white"
              className="text-center text-lg-left mb-5 mb-lg-0"
              scrollReveal
            >
              Ofte stillede spørgsmål
            </BaseSection.Header.Base>
            <BaffleButton
              variant="success"
              as="a"
              href="https://faq.nord.investments/"
              className="d-none d-lg-inline-block mt-4"
            >
              Se alle
            </BaffleButton>
          </Col>
          <Col xs={12} lg={6}>
            <ExpandableList data={questions} className="font-size-lg" />
            <div className="d-flex justify-content-center mt-5 d-lg-none">
              <BaffleButton
                variant="success"
                as="a"
                href="https://faq.nord.investments/"
              >
                Se alle
              </BaffleButton>
            </div>
          </Col>
        </Card.Body>
      </ScrollTransition.Item>
    </Col>
  </Row>
)

FAQCard.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.shape
      ]).isRequired
    })
  ).isRequired
}

export default FAQCard
