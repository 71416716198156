import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const AndersSignatureImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      andersSignatureImage: file(
        relativePath: {
          eq: "components/pages/WealthPage/Sections/TeamSection/images/anders-signature.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 2722, maxHeight: 986) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img fluid={data.andersSignatureImage.childImageSharp.fluid} {...props} />
  )
}

export default AndersSignatureImage
