import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BoardImageIE from '../../../../../assets/images/board-image-IE.png'
import BaseSection from '../../../../BaseSection'
import BoardImage from '../../../../BoardImage'

const NobelPriceContent = () => (
  <SectionWithOverflowImage
    className="my-6"
    image={<BoardImage />}
    imageAlign="right"
    imageForIE={BoardImageIE}
    variant="C"
    hasLines
  >
    <BaseSection.Header.Base variant="white" scrollReveal>
      Nobelprisvindende investeringsmodel
    </BaseSection.Header.Base>
    <BaseSection.Text scrollReveal>
      Vi benytter den anerkendte og nobelprisvindende
      <span>&nbsp;</span>
      <a
        href="https://faq.nord.investments/da/articles/425147-hvilken-investeringsmetode-anvendes"
        className="link-white"
      >
        Markowitz-model
      </a>
      , som benyttes over hele verden. Det sikrer, at du får det bedst mulige
      afkast til den lavest mulige risiko.
    </BaseSection.Text>
  </SectionWithOverflowImage>
)

export default NobelPriceContent
