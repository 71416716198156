import React from 'react'

import FAQSection from '../../../FAQSection'

import AppSection from './AppSection'
import ChartSection from './ChartSection'
import ETFSection from './ETFSection'
import questionsData from './questionsData'

const Sections = () => (
  <>
    <ChartSection />
    <ETFSection />
    <AppSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
