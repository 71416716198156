import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import AvatarCard from '../../../../AvatarCard'
import BaseSection from '../../../../BaseSection'
import GatsbyInternalLink from '../../../../GatsbyInternalLink'

import teamData from './teamData'

const TeamSection = () => (
  <BaseSection className="text-center" height="large" scrollReveal>
    <Row className="mb-6">
      <Col>
        <BaseSection.Header.Base variant="white" scrollReveal>
          Teamet
        </BaseSection.Header.Base>
        <BaseSection.Text className="w-75 mx-auto" scrollReveal>
          Vi er et team med mange års erfaring indenfor formuepleje og
          vellykkede digitale tjenester. Teamet og ejerne har tilsammen mere end
          10M kr. investeret som kunder.
        </BaseSection.Text>
      </Col>
    </Row>
    <Row>
      {teamData.map((teamMember, index) => {
        const { email, socialAccounts } = teamMember
        const accountsArray = Object.entries(socialAccounts)
        if (email) accountsArray.unshift(['Email', `mailto:${email}`])

        return (
          <AvatarCard
            {...teamMember}
            key={teamMember.name}
            index={index}
            indexOffset={2}
          >
            <Card.Text className="text-monospace text-uppercase font-size-sm">
              {accountsArray
                .map(([account, link]) => (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={link}
                  >
                    {account}
                  </a>
                ))
                .reduce((prev, curr) => [prev, '/', curr])}
            </Card.Text>
          </AvatarCard>
        )
      })}
    </Row>
    <Row>
      <Col>
        <BaseSection.Text className="w-75 mx-auto" scrollReveal>
          Vil du med på teamet? Læs mere om NORD og vores åbne stillinger her.
        </BaseSection.Text>
        <ScrollTransition.Item>
          <BaffleButton
            as={GatsbyInternalLink}
            to="/karriere"
            variant="success"
          >
            Søg job
          </BaffleButton>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default TeamSection
