import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const VideoThumbnail = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(
        relativePath: {
          eq: "components/pages/InvestorPage/InvestorHero/images/thumbnail.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fluid={data.file.childImageSharp.fluid}
      alt="TV spot"
      loading="eager"
      {...props}
    />
  )
}

export default VideoThumbnail
