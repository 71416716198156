import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import CardWithRippleImage from '@nordinvestments/nord-storybook/dist/components/CardWithRippleImage'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import StocksImage from './StocksImage'

const InvestCard = () => (
  <Row>
    <ScrollTransition.Item as={Col}>
      <CardWithRippleImage
        className="bg-deep-dark shadow overflow-hidden"
        image={<StocksImage />}
        imageAlign="left"
      >
        <BaseSection.Header.Base variant="white" scrollReveal>
          Dit afkast hos Nord
        </BaseSection.Header.Base>
        <BaseSection.Text variant="body">
          Det historiske afkast ligger årligt på op til{' '}
          {prices.privateAndCompany.historicalReturn.format}, altså langt højere
          end ved at have pengene stående på en bankkonto.
        </BaseSection.Text>
        <ScrollTransition.Item as="a" href="/afkast">
          <BaffleButton variant="success">Læs mere</BaffleButton>
        </ScrollTransition.Item>
      </CardWithRippleImage>
    </ScrollTransition.Item>
  </Row>
)

export default InvestCard
