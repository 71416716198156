import CardWithRippleImage from '@nordinvestments/nord-storybook/dist/components/CardWithRippleImage'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from '../pages/CardWithImage.module.scss'

import TeamImage from './TeamImage'

const TeamCard = ({ children, ...props }) => (
  <CardWithRippleImage
    className={classNames('shadow', styles.cardBackground)}
    image={<TeamImage />}
    imageAlign="left"
    fullHeightImage
    {...props}
  >
    {children}
  </CardWithRippleImage>
)

TeamCard.propTypes = {
  children: PropTypes.node.isRequired
}

export default TeamCard
