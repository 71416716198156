import BooleanCollection from '@nordinvestments/nord-storybook/dist/components/BooleanCollection'
import BooleanInput from '@nordinvestments/nord-storybook/dist/components/BooleanInput'
import CurrencyInput from '@nordinvestments/nord-storybook/dist/components/CurrencyInput'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import RangeInput from '@nordinvestments/nord-storybook/dist/components/RangeInput'
import Select from '@nordinvestments/nord-storybook/dist/components/Select'
import StickyCard from '@nordinvestments/nord-storybook/dist/components/StickyCard'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import {
  numberToPercent,
  numberToCurrency
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import React, { useContext } from 'react'
import Card from 'react-bootstrap/Card'
import FormControl from 'react-bootstrap/FormControl'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import ArrowRight from 'react-feather/dist/icons/arrow-right'
import Info from 'react-feather/dist/icons/info'

import OnboardingLink from '../../../../../OnboardingLink'
import CostsContext from '../CostsContext'

import { MINIMUM_INVESTMENT, MAXIMUM_INVESTMENT, STEPS } from './constants'
import styles from './index.module.scss'

const ExpectedInvestment = () => {
  const {
    depot,
    costs,
    investmentAmount,
    isESG,
    isPension,
    isStocksOnly,
    onInvestmentAmountChange,
    onIsESGChange,
    onIsStocksOnlyChange,
    onDepotChange
  } = useContext(CostsContext)

  const handleOnlyStocksChange = (value) => onIsStocksOnlyChange(value)

  if (isPension) onIsStocksOnlyChange(false)

  let investmentValidation
  if (investmentAmount < MINIMUM_INVESTMENT) {
    investmentValidation = 'Min beløb 30.000 DKK'
  } else if (investmentAmount >= 1000000000) {
    onInvestmentAmountChange('1000000000')
  } else {
    investmentValidation = null
  }

  const monthlyCosts = Math.round((investmentAmount * costs) / 12)

  return (
    <StickyCard
      content={
        <>
          <Card.Title as="h4" className="text-center text-lg-left">
            Din investering
          </Card.Title>
          <div className="mb-4">
            <h6>Vælg eller skriv beløb</h6>
            <CurrencyInput
              value={investmentAmount}
              onValueChange={onInvestmentAmountChange}
              className={classNames('font-size-md', styles.investmentCardInput)}
            />
            <RangeInput
              className="mt-3"
              min={MINIMUM_INVESTMENT}
              max={MAXIMUM_INVESTMENT}
              defaultValue={investmentAmount}
              value={investmentAmount}
              steps={STEPS}
              onValueChange={onInvestmentAmountChange}
              fill
              snapToSteps
            />
            {investmentValidation && (
              <FormControl.Feedback type="invalid" className="d-block">
                {investmentValidation}
              </FormControl.Feedback>
            )}
          </div>
          <hr className="d-none d-lg-block" />
          <div className="mb-4">
            <h6>Vælg depot</h6>
            <BooleanCollection
              className={classNames(styles.radioCheck, 'd-none', 'd-lg-block')}
              name="depot"
              type="radio"
              value={depot}
              options={[
                { label: 'Frie midler', value: 'frie midler' },
                { label: 'Virksomhed', value: 'virksomhed' },
                { label: 'Pension', value: 'pension' }
              ]}
              onValueChange={onDepotChange}
            />
            <Select
              className="d-lg-none"
              name="depot"
              value={depot}
              options={[
                { label: 'Frie midler', value: 'frie midler' },
                { label: 'Virksomhed', value: 'virksomhed' },
                { label: 'Pension', value: 'pension' }
              ]}
              onValueChange={onDepotChange}
              isSearchable={false}
            />
          </div>
          <hr className="d-none d-lg-block" />
          <div>
            <BooleanInput
              type="switch"
              label={
                <>
                  Ansvarlig investering
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Hvis du vælger en ansvarlig portefølje, bliver du ikke
                        investeret i virksomheder, der ikke lever op til vores
                        krav ift. miljø, arbejdsforhold mm. Du vil f.eks. ikke
                        blive investeret i virksomheder der producerer våben
                        eller forurener i særlig grad.
                      </Tooltip>
                    }
                  >
                    <IconButton icon={Info} variant="link" className="p-0" />
                  </OverlayTrigger>
                </>
              }
              name="esg"
              onValueChange={onIsESGChange}
              checked={isESG || isPension || isStocksOnly}
              disabled={isPension || isStocksOnly}
            />
            <BooleanInput
              name="stocks"
              type="switch"
              label={
                <>
                  Ekstra høj risiko
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        Hvis du vælger en ansvarlig portefølje med ekstra høj
                        risiko, bliver du kun investeret i aktier - du får altså
                        ingen obligationer. Denne portefølje bliver ikke
                        rebalanceret.
                      </Tooltip>
                    }
                  >
                    <IconButton icon={Info} variant="link" className="p-0" />
                  </OverlayTrigger>
                </>
              }
              className="mt-2"
              onValueChange={handleOnlyStocksChange}
              checked={isStocksOnly}
              disabled={isPension}
            />
          </div>
          <div className="d-lg-none mt-4">
            <hr />
            <div>
              <h4 className="text-center">Din totalomkostning</h4>
              <div
                className={classNames(
                  'font-size-lg',
                  'd-flex',
                  'flex-column',
                  'justify-content-between',
                  'align-items-center',
                  styles.costCardInput
                )}
              >
                <Text as="p" transform="uppercase">
                  {numberToPercent(costs)}{' '}
                  <Text variant="secondary" transform="lowercase">
                    årligt
                  </Text>
                </Text>
                <Text as="p" transform="uppercase">
                  Svarende til
                </Text>
                <Text>
                  {numberToCurrency(monthlyCosts)}{' '}
                  <Text variant="secondary">pr.måned</Text>
                </Text>
              </div>
            </div>
          </div>
        </>
      }
      footer={
        <div className="text-center mt-3">
          <Text as="p" variant="white" size="md" className="m-2">
            Få en gratis investeringsplan og find din personlige risikoprofil
          </Text>
          <div className="d-flex justify-content-center mb-4">
            <OnboardingLink
              component={IconButton}
              variant="success"
              icon={ArrowRight}
            >
              Kom i gang
            </OnboardingLink>
          </div>
        </div>
      }
      withTopFixedNavigation
    />
  )
}

export default ExpectedInvestment
