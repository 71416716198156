import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'

import manWithSeaImageIE from './images/man-with-sea-ie.png'
import ManWithSeaImage from './ManWithSeaImage'

const YouSection = () => (
  <BaseSection scrollReveal>
    <SectionWithOverflowImage
      image={<ManWithSeaImage />}
      imageForIE={manWithSeaImageIE}
      imageAlign="right"
      variant="C"
      hasLines
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Hvem er du?
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        NORD Wealth er for dig med en formue på +5M, hvad end din formue står i
        en pension, et selskab, under virksomhedsskatteordningen eller som frie
        midler på din private konto.
      </BaseSection.Text>
    </SectionWithOverflowImage>
  </BaseSection>
)

export default YouSection
