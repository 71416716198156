import React from 'react'

import SEO from '../../SEO'

import ProductsHero from './ProductsHero'
import Sections from './Sections'

const ProductsPage = () => (
  <>
    <SEO
      title="En digital investeringsløsning til din hele din formue"
      description="NORD.investments er en digital investeringsløsning, til dig der vil investere pension, frie-, og virksomhedsmidler. Start med en gratis investeringsplan."
      keywords={['investering', 'formuepleje', 'investeringsløsning']}
      pathname="/vi-tilbyder"
    />
    <ProductsHero />
    <Sections />
  </>
)

export default ProductsPage
