import React from 'react'

import BaseSection from '../../../../BaseSection'

const PriceText = () => (
  <>
    <BaseSection.Header.Base
      variant="white"
      className="text-center text-lg-left"
    >
      En lav, samlet pris
    </BaseSection.Header.Base>
    <BaseSection.Text className="text-center text-lg-left">
      Vores priser indeholder omkostninger til NORD.investments, Saxo Bank og de
      fonde man bliver investeret i - ja, faktisk alle omkostninger. Det gør det
      nemt for dig, at vide, hvad du betaler.
    </BaseSection.Text>
  </>
)

export default PriceText
