import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import Loader from '@nordinvestments/nord-storybook/dist/components/Loader'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useApi, {
  useApiRequest
} from '@nordinvestments/nord-storybook/dist/hooks/useApi'
import isEmpty from 'lodash/isEmpty'
import React, { useCallback, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'
import RefreshCw from 'react-feather/dist/icons/refresh-cw'

import BaseSection from '../../../../BaseSection'

import Announcement from './Announcement'
import SubscriptionModal from './SubscriptionModal'

const announcementsUrl =
  'https://via.ritzau.dk/nyhedsrum/nordinvestments-as/a?publisherId=13560369'

const LatestAnnouncementsSection = () => {
  const [showModal, setShowModal] = useState(false)
  const [hasError, setHasError] = useState(false)

  const handleOpenModal = () => setShowModal(true)
  const handleCloseModal = () => setShowModal(false)
  const handleError = useCallback(() => setHasError(true), [])
  const handleReload = () => window.location.reload()

  const fetchAnnouncements = useApi('/ritzau_announcements?per_page=5', {
    method: 'GET',
    onError: handleError
  })

  const {
    data: announcementsData,
    loading: loadingAnnouncements
  } = useApiRequest(fetchAnnouncements)

  const isAnnouncementsDataEmpty =
    !loadingAnnouncements && isEmpty(announcementsData)

  return (
    <BaseSection height="half" scrollReveal>
      <Row>
        <Col xs={12} lg={5} className="py-lg-6 text-center text-lg-left">
          <BaseSection.Header.Base variant="white" scrollReveal>
            Seneste Selskabsmeddelser
          </BaseSection.Header.Base>
          <ScrollTransition.Item
            as={BaffleButton}
            onClick={handleOpenModal}
            variant="success"
            className="mt-2 mt-lg-5"
          >
            Abonner på selskabsmeddelelser
          </ScrollTransition.Item>
          <SubscriptionModal
            showModal={showModal}
            onCloseModal={handleCloseModal}
          />
        </Col>
        {loadingAnnouncements && (
          <Col
            xs={12}
            lg={{ span: 5, offset: 2 }}
            className="d-flex flex-row align-items-center text-white font-size-xxl"
          >
            <Loader />
          </Col>
        )}
        {isAnnouncementsDataEmpty ? (
          <>
            <Col
              xs={12}
              lg={{ span: 5, offset: 2 }}
              className="d-flex flex-column align-items-center justify-content-center text-white"
            >
              {hasError ? (
                <div className="d-flex flex-column">
                  <Text size="md" className="ml-3">
                    Vi beklager, der er sket en fejl...
                  </Text>
                  <IconButton
                    icon={RefreshCw}
                    onClick={handleReload}
                    className="mt-4"
                  >
                    Genindlæs
                  </IconButton>
                </div>
              ) : (
                <>
                  <div className="d-flex flex-row align-items-center">
                    <Text size="md" className="my-5 my-lg-0">
                      Der er ingen selskabsmeddelser at vise.
                    </Text>
                  </div>
                </>
              )}
            </Col>
          </>
        ) : (
          announcementsData &&
          announcementsData.map((announcementData, index) => {
            const isEven = index % 2 === 0

            return (
              <Announcement
                key={announcementData.id}
                data={announcementData}
                isEven={isEven}
              />
            )
          })
        )}
        <Col xs={12} className="d-flex justify-content-center">
          <ScrollTransition.Item
            as={IconButton}
            icon={ArrowRight}
            variant="success"
            href={announcementsUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-4"
          >
            Se alle
          </ScrollTransition.Item>
        </Col>
        {!isAnnouncementsDataEmpty && (
          <Col xs={12} className="d-flex justify-content-center d-lg-none mt-5">
            <ScrollTransition.Item as={BaffleButton} onClick={handleOpenModal}>
              Abonner på selskabsmeddelelser
            </ScrollTransition.Item>
          </Col>
        )}
      </Row>
    </BaseSection>
  )
}

export default LatestAnnouncementsSection
