import React from 'react'

import BaseSection from '../../../../BaseSection'

import InvestCard from './InvestCard'

const InvestSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <InvestCard />
  </BaseSection>
)

export default InvestSection
