import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React from 'react'

const pensionYear = 2021
const ratepensionMaximumYearlyDeposit = 58500
const aldersopsparingMaximumYearlyDeposit = 5400
const aldersopsparingMaximumDepositFinalYears = 52400

const questionsData = [
  {
    index: '0',
    title: 'Hvor meget kan jeg indbetale?',
    content: (
      <>
        Indbetalingsloftet afhænger af, hvilken type pensionsdepot du har. Du
        kan maksimalt indbetale{' '}
        {numberToCurrency(ratepensionMaximumYearlyDeposit)} årligt (
        {pensionYear}) på din ratepension, mens du på din aldersopsparing højst
        kan indbetale {numberToCurrency(aldersopsparingMaximumYearlyDeposit)}{' '}
        årligt ({pensionYear}). Har du dog mindre end 5 år til din forventet
        folkepensionsalder, så må du årligt indbetale op til{' '}
        {numberToCurrency(aldersopsparingMaximumDepositFinalYears)} (
        {pensionYear}) på din aldersopsparing. Man kan ikke længere indbetale
        til kapitalpensionsordninger.
      </>
    )
  },
  {
    index: '1',
    title: 'Tilbyder I forsikringer?',
    content:
      'Nej, der tilbydes pt. ikke forsikringer eller livrente produkter. Dette gælder både ved flytning af pensionsdepot og oprettelse af ny pension.'
  },
  {
    index: '2',
    title: 'Hvordan investeres min pension?',
    content:
      'Med en ansvarlig og bæredygtig pension sikrer du at din opsparing investeres i tråd med dine værdier. Du bliver investereret i tusindvis af værdipapirer, så du ikke behøver, at gå på kompromis med risikoen. Vi sænker løbende din porteføljes risiko, jo tættere du kommer på din pensionsudbetalingsalder.'
  },
  {
    index: '3',
    title: 'Hvordan får jeg min pension udbetalt?',
    content:
      'Udbetalingen af din pension afhænger af din pensionstype. Udbetalingen sker nemt og enkelt efter aftale med dig.'
  },
  {
    index: '4',
    title: 'Hvordan flytter jeg min pension?',
    content:
      'Har du en pensionsopsparing, som du gerne vil flytte, så start med at tag en gratis investeringsplan til din pension. Fra din investeringsplan har du mulighed for at igangsætte flytningen af dit pensionsdepot.'
  }
]

export default questionsData
