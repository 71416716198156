/* eslint-disable react/jsx-no-target-blank */
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import React from 'react'

const onboardingUrl = getConfig('domains.onboarding')

const questionsData = [
  {
    index: '0',
    title: 'Har jeg brug for en LEI-kode?',
    content:
      'Dit selskab har brug for en LEI-kode for at investere hos os. Vi hjælper gerne med at oprette en LEI-kode til dig, men du er også velkommen til selv at sørge for det. Kontakt os, hvis du ønsker hjælp til oprettelsen.'
  },
  {
    index: '1',
    title: 'Hvordan trækker jeg mine penge ud?',
    content: (
      <>
        Du kan altid sælge hele, eller en del af, din portefølje. Den bliver
        solgt samme dag som du kontakter os (senest næstkommende bankdag).
        Derefter kan du overføre pengene til den konto, du ønsker.{' '}
        <a
          href="https://faq.nord.investments/da/articles/1171648-hvor-lang-opsigelse-er-der-pa-min-portefolje"
          target="_blank"
          className="link-light"
        >
          Læs mere og kontakt os her
        </a>
        .
      </>
    )
  },
  {
    index: '2',
    title: 'Kan jeg investere under virksomhedsskatteordningen (VSO)?',
    content:
      'Ja, du kan investere under virksomhedsskatteordningen, da vores porteføljer er optimeret efter ordningen.'
  },
  {
    index: '3',
    title: 'Hvad koster det?',
    content: (
      <>
        En standard portefølje koster{' '}
        {prices.privateAndCompany.costs.base.high.format} i årlige omkostninger.{' '}
        En ansvarlig portefølje koster{' '}
        {prices.privateAndCompany.costs.esg.high.format}. Du kan læse, hvad
        prisen dækker over{' '}
        <a href="/priser" target="_blank" className="link-light">
          her
        </a>
        .
      </>
    )
  },
  {
    index: '4',
    title: 'Hvad investerer jeg i?',
    content: (
      <>
        Din portefølje består af tusindvis af aktier og obligationer, som er
        sammensat af syv udvalgte ETF’er. Du kan se den specifikke sammensætning
        af værdipapirer under din{' '}
        <a href={onboardingUrl} target="_blank" className="link-light">
          investeringsplan
        </a>
        .
      </>
    )
  }
]

export default questionsData
