import RipplesShapes from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import appImageIE from '../../../../../assets/images/app.png'
import AppImage from '../../../../AppImage'
import BaseSection from '../../../../BaseSection'
import styles from '../../../RippleShapeOverflowFix.module.scss'

const InvestmentContent = () => (
  <Row className={classNames(styles.overflow, 'my-lg-6', 'py-lg-6')}>
    <Col
      xs={12}
      lg={6}
      className="text-center text-lg-left order-lg-12 d-flex flex-column justify-content-center"
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Dine investeringer i en app
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        NORD.investments er en digital investeringsløsning, til dig der vil
        investere pension, frie-, eller virksomhedsmidler.
      </BaseSection.Text>
    </Col>
    <Col xs={12} lg={6} className="order-lg-1 my-6 py-6">
      <RipplesShapes.Background
        variant="B"
        className="w-75"
        imageForIE={appImageIE}
      >
        <div className="w-50">
          <AppImage />
        </div>
      </RipplesShapes.Background>
    </Col>
  </Row>
)

export default InvestmentContent
