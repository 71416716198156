import RipplesShapes from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import useDetectBrowser from '@nordinvestments/nord-storybook/dist/hooks/useDetectBrowser'
import PropTypes from 'prop-types'
import React from 'react'

import Video from './Video'

const HeroShapesVideo = ({ variant, mp4Video, webmVideo, posterSrc }) => {
  const notSupportedBrowser = useDetectBrowser(['ie', 'edge'])

  // IE & Edge hack
  if (notSupportedBrowser)
    return <Video mp4VideoSrc={mp4Video} posterSrc={posterSrc} isHero />

  return (
    <RipplesShapes.Mask variant={variant} hasLines hero>
      <Video
        mp4VideoSrc={mp4Video}
        webmVideoSrc={webmVideo}
        posterSrc={posterSrc}
        isHero
      />
    </RipplesShapes.Mask>
  )
}

HeroShapesVideo.propTypes = {
  variant: PropTypes.string.isRequired,
  mp4Video: PropTypes.node.isRequired,
  webmVideo: PropTypes.node.isRequired,
  posterSrc: PropTypes.node.isRequired
}

export default HeroShapesVideo
