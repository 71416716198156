import React from 'react'

import SEO from '../../SEO'

import HiringHero from './HiringHero'
import Sections from './Sections'

const HiringPage = () => (
  <>
    <SEO
      title="Lyst til at arbejde med investering? Bliv en del af vores vækstrejse"
      description="Se vores stillingsopslag og læs omkring hvem vi er og hvorfor NORD.investments er en unik arbejdsplads."
      keywords={['jobs', 'karriere', 'nord.investments', 'investering']}
      pathname="/karriere"
    />
    <HiringHero />
    <Sections />
  </>
)

export default HiringPage
