import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'

const SCROLL_REVEAL_ITEM_COUNT = 5

const AvatarCard = ({
  name,
  jobTitle,
  index,
  indexOffset,
  children,
  image: Image
}) => {
  const startIndex = index * SCROLL_REVEAL_ITEM_COUNT + indexOffset

  return (
    <Col xs={12} md={6} lg={4}>
      <ScrollTransition.Item
        as={Card}
        className="text-center my-6 mx-3"
        index={startIndex}
      >
        <ScrollTransition.Item as={Card.Circle} index={startIndex + 1}>
          <Image
            alt={name}
            className="w-100 h-100"
            objectFit="cover"
            objectPosition="top center"
          />
        </ScrollTransition.Item>
        <Card.Body>
          <ScrollTransition.Item
            as="div"
            className="card-title"
            index={startIndex + 2}
          >
            <Text as="h3" size="lg" className="m-0">
              {name}
            </Text>
            <div>{jobTitle}</div>
          </ScrollTransition.Item>
          <ScrollTransition.Item index={startIndex + 4}>
            <div>{children}</div>
          </ScrollTransition.Item>
        </Card.Body>
      </ScrollTransition.Item>
    </Col>
  )
}

AvatarCard.propTypes = {
  name: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  image: PropTypes.elementType.isRequired,
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  indexOffset: PropTypes.number
}

AvatarCard.defaultProps = {
  indexOffset: 0
}

export default AvatarCard
