import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const Capnova = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(
        relativePath: {
          eq: "components/pages/AboutPage/Sections/InvestorsSection/images/capnova.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 540, maxHeight: 94, trim: 0.1, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return <Img fluid={data.file.childImageSharp.fluid} {...props} />
}

export default Capnova
