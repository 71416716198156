import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'
import GatsbyInternalLink from '../../../../GatsbyInternalLink'

import natureImageIE from './img/nature-ie.png'
import NatureImage from './NatureImage'

const SunstainableSection = () => (
  <BaseSection scrollReveal>
    <SectionWithOverflowImage
      image={<NatureImage />}
      imageForIE={natureImageIE}
      imageAlign="right"
      variant="C"
      hasLines
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Et bæredygtigt afkast
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        Ansvarlige porteføljer kan levere lige så gode afkast – og nogle gange
        bedre – end vores standardporteføljer. Se afkastet for vores ansvarlige
        porteføljer{' '}
        <GatsbyInternalLink to="/afkast" className="link-white">
          her
        </GatsbyInternalLink>
        .
      </BaseSection.Text>
    </SectionWithOverflowImage>
  </BaseSection>
)

export default SunstainableSection
