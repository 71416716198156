import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import { getConfig } from '@nordinvestments/nord-storybook/dist/configuration'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'
import TeamCard from '../../../../TeamCard'

const contactInformation = getConfig('contactInformation')

const { email, emailLink } = contactInformation

const KnowMoreSection = () => (
  <BaseSection height="half" scrollReveal>
    <Row>
      <ScrollTransition.Item as={Col}>
        <TeamCard>
          <BaseSection.Header.Base variant="white" scrollReveal>
            Vil du vide mere?
          </BaseSection.Header.Base>
          <BaseSection.Text variant="body" scrollReveal>
            Har du spørgsmål til NORD.investments, teamet eller en konkret
            stilling, så kontakt os på{' '}
            <a href={emailLink} className="link-white">
              {email}
            </a>
            .
          </BaseSection.Text>
        </TeamCard>
      </ScrollTransition.Item>
    </Row>
  </BaseSection>
)

export default KnowMoreSection
