export const MINIMUM_INVESTMENT = 3e4 // 30 000

export const MAXIMUM_INVESTMENT = 1e7 // 10 000 000
export const DEFAULT_INVESTMENT = 5e5 // 500 000

export const STEPS = {
  0: MINIMUM_INVESTMENT,
  0.05: 5e4, // 50 000
  0.1: 1e5, // 100 000
  0.2: 2e5, // 200 000
  0.3: 3e5, // 300 000
  0.4: 4e5, // 400 000
  0.5: DEFAULT_INVESTMENT,
  0.55: 1e6, // 1 000 000
  0.6: 2e6, // 2 000 000
  0.65: 3e6, // 3 000 000
  0.7: 4e6, // 4 000 000
  0.75: 5e6, // 5 000 000
  0.8: 6e6, // 6 000 000
  0.85: 7e6, // 7 000 000
  0.9: 8e6, // 8 000 000
  0.95: 9e6, // 8 000 000
  1: MAXIMUM_INVESTMENT
}
