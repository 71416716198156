import React from 'react'

import AdvantagesSection from './AdvantagesSection'
import ContactSection from './ContactSection'
import DetailedAdvantagesSection from './DetailedAdvantagesSection'
import ImpartialitySection from './ImpartialitySection'
import PriceSection from './PriceSection'
import SolutionSection from './SolutionSection'
import TeamSection from './TeamSection'
import TestimonialsSection from './TestimonialsSection'
import WeSection from './WeSection'
import YouSection from './YouSection'

const Sections = () => (
  <>
    <AdvantagesSection />
    <YouSection />
    <WeSection />
    <ImpartialitySection />
    <PriceSection />
    <DetailedAdvantagesSection />
    <TestimonialsSection />
    <TeamSection />
    <SolutionSection />
    <ContactSection />
  </>
)

export default Sections
