import React from 'react'

const Management = () => (
  <>
    <h2>Behandling</h2>
    <p>Al data behandles altid i overensstemmelse med gældende lovgivning.</p>
    <p>
      Vi indsamler og behandler altid din data med henblik på at leve op til
      vores forpligtelser til den finansielle og anden lovgivning.
      <br />
      Behandling af data er nødvendigt for NORD at kunne etablere et
      kundeforhold med NORD og vores partnerbank, Saxo Bank A/S. Ligeledes
      behandler vi dine data ved vedligeholdelse og eventuel afslutning af dit
      kundeforhold.
    </p>
    <p>
      Din persondata bruges også i forbindelse med rapportering i vores app, ved
      markedsføring, ved oprettelse af et bruger-forhold hos NORD samt ved
      oprettelse af et eventuelt kundeforhold hos Saxo Bank A/S.
    </p>
    <p>Kun betroede medarbejdere hos NORD har adgang til dine persondata.</p>
  </>
)

export default Management
