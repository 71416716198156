import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ChevronLeft from 'react-feather/dist/icons/chevron-left'
import ChevronRight from 'react-feather/dist/icons/chevron-right'

import BaseSection from '../../../../BaseSection'
import TestimonialCard from '../../../../TestimonialCard'
import {
  Johan,
  Mette,
  Nikolaj
} from '../../../AboutPage/Sections/TeamSection/images'

import styles from './index.module.scss'

const CarrouselIcon = ({ icon }) => {
  const largeAndUp = useBreakpointHandler('lg')

  const circleSize = largeAndUp ? 'sm' : 'xs'

  return (
    <Circle size={circleSize} variant="success">
      <Icon icon={icon} />
    </Circle>
  )
}

CarrouselIcon.propTypes = {
  icon: PropTypes.elementType.isRequired
}

const TeamSection = () => (
  <BaseSection
    height="half"
    className={classNames('bg-primary', styles.teamSection)}
    scrollReveal
  >
    <Row>
      <Col xs={12}>
        <BaseSection.Header.Base variant="white" align="center" scrollReveal>
          Det siger vores team
        </BaseSection.Header.Base>
      </Col>
    </Row>
    <Row className="mt-5">
      <Col
        xs={12}
        className={classNames(styles.carouselWrapper, 'p-0', 'overflow-hidden')}
      >
        <ScrollTransition.Item>
          <Carousel
            nextIcon={<CarrouselIcon icon={ChevronRight} />}
            prevIcon={<CarrouselIcon icon={ChevronLeft} />}
            interval={null}
          >
            <Carousel.Item>
              <TestimonialCard
                className={styles.carouselCard}
                image={Nikolaj}
                name="Nikolaj Bomann Mertz"
                position="Head of Marketing"
                content="Fedt at være en del af et team, hvor der er så god energi, som hos NORD."
                xs={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 3 }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <TestimonialCard
                className={styles.carouselCard}
                image={Johan}
                name="Johan Frølich"
                position="Lead Software Engineer"
                content="Det bedste ved at arbejde hos NORD er at være en del af et stærkt team, i et spændende og dynamisk startup miljø."
                xs={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 3 }}
              />
            </Carousel.Item>
            <Carousel.Item>
              <TestimonialCard
                className={styles.carouselCard}
                image={Mette}
                name="Mette Byssing Larsen"
                position="Customer Success Manager"
                content="Jeg nyder at være en del af et ambitiøst team med plads til alle."
                xs={{ span: 10, offset: 1 }}
                lg={{ span: 6, offset: 3 }}
                // xs={undefined}
                // lg={undefined}
              />
            </Carousel.Item>
          </Carousel>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default TeamSection
