import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const Berlingske = (props) => {
  const data = useStaticQuery(graphql`
    query {
      file(
        relativePath: {
          eq: "components/pages/HomePage/Sections/MediaSection/Images/berlingske.png"
        }
      ) {
        childImageSharp {
          fixed(height: 27, width: 113) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Img
      fixed={data.file.childImageSharp.fixed}
      alt="Berlingske"
      style={{
        width: `${113 / 18}em`,
        height: '1.5em',
        display: 'inline-block'
      }}
      {...props}
    />
  )
}

export default Berlingske
