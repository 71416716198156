import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import useIsClient from '@nordinvestments/nord-storybook/dist/hooks/useIsClient'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'
import TeamCard from '../../../../TeamCard'

import ContactPerson from './ContactPerson'
import contactPersonsData from './contactPersonsData'

const KnowMoreSection = () => {
  const { isClient } = useIsClient()

  if (!isClient) return null

  return (
    <BaseSection height="half" scrollReveal>
      <Row>
        <ScrollTransition.Item as={Col}>
          <TeamCard>
            <>
              <BaseSection.Header.Base
                variant="white"
                align="center"
                scrollReveal
              >
                Vil du vide mere?
              </BaseSection.Header.Base>
              <Row>
                {contactPersonsData.map((contactPerson) => (
                  <ContactPerson {...contactPerson} />
                ))}
              </Row>
            </>
          </TeamCard>
        </ScrollTransition.Item>
      </Row>
    </BaseSection>
  )
}

export default KnowMoreSection
