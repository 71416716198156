import React from 'react'

import FAQSection from '../../../FAQSection'

import AdvantageSection from './AdvantageSection'
import InvestSection from './InvestSection'
import MoreSection from './MoreSection'
import NobelPriceSection from './NobelPriceSection'
import questionsData from './questionsData'

const Sections = () => (
  <>
    <AdvantageSection />
    <InvestSection />
    <NobelPriceSection />
    <MoreSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
