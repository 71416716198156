/* eslint-disable react/jsx-no-target-blank */
import prices from '@nordinvestments/nord-storybook/dist/configuration/data/prices'
import {
  numberToPercent,
  numbersToPercentRange
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import React from 'react'

const questionsData = [
  {
    index: '0',
    title: 'Hvad investerer jeg ikke i?',
    content: (
      <>
        Virksomheder, hvis omsætning fra våben, atomvåben, tobak, kul, olie
        eller diskrimination overstiger{' '}
        {numberToPercent(0.05, { fractionDigits: 0 })}, er sorteret fra i de
        ansvarlige porteføljer.
      </>
    )
  },
  {
    index: '1',
    title: 'ESG - hvad betyder det?',
    content: (
      <>
        ESG står for Environmental, Social and Governance - altså miljø-,
        sociale- og ledelsesmæssige forhold. Læs mere om hvad ESG betyder for
        din portefølje
        <a
          href="https://faq.nord.investments/da/articles/3019353-nord-s-ansvarlige-portefoljer"
          target="_blank"
          className="link-light"
        >
          {' '}
          her
        </a>
        .
      </>
    )
  },
  {
    index: '2',
    title: 'Hvad koster ansvarlig investering?',
    content: (
      <>
        En ansvarlig portefølje koster maksimalt{' '}
        {prices.privateAndCompany.costs.esg.high.format}. i totale årlige
        omkostninger. Vi forventer, at de leverer nogenlunde samme afkast som
        vores standardporteføljer.
        <a
          href="https://faq.nord.investments/da/articles/3019353-nord-s-ansvarlige-portefoljer"
          target="_blank"
          className="link-light"
        >
          {' '}
          Læs mere om ansvarlige porteføljer og forventet afkast her
        </a>
        .
      </>
    )
  },
  {
    index: '3',
    title: 'Hvad er de forventede afkast?',
    content: (
      <>
        De forventede årlige afkast er på mellem{' '}
        {numbersToPercentRange(0.0244, 0.0564)} (alt efter din risikoprofil).
        Læs mere om de forventede afkast{' '}
        <a
          href="https://faq.nord.investments/da/articles/414823-hvad-er-de-forventede-afkast"
          target="_blank"
          className="link-light"
        >
          her
        </a>
        .
      </>
    )
  },
  {
    index: '4',
    title: 'Kan jeg skifte til en ansvarlig portefølje senere?',
    content:
      'Du kan starte med en standardportefølje og senere skifte til en ansvarlig portefølje, men der er omkostninger forbundet med skiftet. Start derfor med den type portefølje, som du forventer at beholde.'
  }
]

export default questionsData
