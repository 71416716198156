import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import Images from './Images'

const MediaItem = ({ children, imageComponent: ImageComponent }) => (
  <Col xs={12} lg={4} className="text-center">
    <ScrollTransition.Item>
      <BaseSection.Text>&quot;{children}&quot;</BaseSection.Text>
    </ScrollTransition.Item>
    <ScrollTransition.Item>
      <div>
        <ImageComponent className="mt-2" />
      </div>
    </ScrollTransition.Item>
  </Col>
)

MediaItem.propTypes = {
  children: PropTypes.node.isRequired,
  imageComponent: PropTypes.elementType.isRequired
}

const MediaSection = () => (
  <BaseSection height="large" className="bg-white text-dark" scrollReveal>
    <BaseSection.Header.SectionTop scrollReveal>
      Læs om NORD
    </BaseSection.Header.SectionTop>
    <Row className="children-my-6 children-my-md-0">
      <MediaItem imageComponent={Images.Politiken}>
        Nemt, hurtigt og billigt: Få en robot til at investere dine sparepenge
      </MediaItem>
      <MediaItem imageComponent={Images.Boersen}>
        Iværksættere gør op med oldnordisk formuepleje
      </MediaItem>
      <MediaItem imageComponent={Images.Berlingske}>
        Sådan vinder du i kampen om billigere investeringsløsninger
      </MediaItem>
    </Row>
  </BaseSection>
)

export default MediaSection
