import useDetectBrowser from '@nordinvestments/nord-storybook/dist/hooks/useDetectBrowser'
import anime from 'animejs'
import React, { useEffect, useRef } from 'react'

import SEO from '../../SEO'

import HomeHero from './HomeHero'
import Sections from './Sections'

const HomePage = () => {
  const animeRef = useRef(null)
  const heroAnimation = useRef(null)
  const isAnimated = useDetectBrowser('ie')

  useEffect(() => {
    if (isAnimated) return
    const animation = anime({
      targets: document.body,
      easing: 'linear',
      autoplay: false
    })

    const currentHeroAnimation = heroAnimation.current

    const handleScroll = () => {
      if (animeRef.current === undefined) return

      const scrollHeight = window.pageYOffset
      const elementHeight = animeRef.current.offsetHeight
      const scrollRatio = scrollHeight / elementHeight
      const progress = scrollRatio * animation.duration

      animation.seek(progress)
      currentHeroAnimation.seek(progress)
    }

    window.addEventListener('scroll', handleScroll)
    handleScroll()

    // eslint-disable-next-line consistent-return
    return () => {
      window.removeEventListener('scroll', handleScroll)
      anime.set(document.body, {
        backgroundColor: null
      })
      anime.remove(document.body)
    }
  }, [isAnimated])

  return (
    <>
      <SEO
        title="NORD er nem opsparing og investering for alle"
        description="Vi gør op med oldnordisk formuepleje, og gør det billigere, transparent og intelligent. Få højere afkast og lavere risiko med portefølje af ETF´ere."
        keywords={[
          'formuepleje',
          'investering',
          'opsparing',
          'ETF',
          'nord',
          'nord invest',
          'passiv investering',
          'index fond',
          'indeks fonde'
        ]}
        pathname="/"
      />
      <HomeHero ref={animeRef} animationRef={heroAnimation} />
      <Sections />
    </>
  )
}

export default HomePage
