import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaseSection from '../../../../BaseSection'

import openPositionsData from './openPositionsData'
import PositionCard from './PositionCard'

const OpenPositionsSection = () => (
  <BaseSection height="half" scrollReveal>
    <Row>
      <Col xs={12}>
        <BaseSection.Header.Base align="center" variant="white" scrollReveal>
          Ledige stillinger
        </BaseSection.Header.Base>
      </Col>
    </Row>
    <Row className="my-5">
      {openPositionsData.slice(0, 3).map((openPosition) => (
        <PositionCard
          indexOffset={2}
          key={openPosition.title}
          {...openPosition}
        />
      ))}
    </Row>
    {openPositionsData.length > 3 && (
      <Row>
        <Col xs={12} className="d-flex justify-content-center">
          <ScrollTransition.Item>
            <IconButton
              icon={ArrowRight}
              href="https://thehub.io/startups/nordinvestments-as"
              variant="success"
              className="mt-3"
            >
              Udforsk alle {openPositionsData.length}
            </IconButton>
          </ScrollTransition.Item>
        </Col>
      </Row>
    )}
  </BaseSection>
)

export default OpenPositionsSection
