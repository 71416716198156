import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const ManWithSeaImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      manWithSeaImage: file(
        relativePath: {
          eq: "components/pages/WealthPage/Sections/YouSection/images/man-with-sea.jpg"
        }
      ) {
        childImageSharp {
          fluid(
            quality: 100
            maxWidth: 800
            maxHeight: 750
            cropFocus: CENTER
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.manWithSeaImage.childImageSharp.fluid} {...props} />
}

export default ManWithSeaImage
