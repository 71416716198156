import React from 'react'

import BaseSection from '../../../../BaseSection'

import InvestContent from './InvestContent'

const InvestSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <InvestContent />
  </BaseSection>
)

export default InvestSection
