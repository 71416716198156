import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import { numberToString } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-bootstrap/Modal'

const BoardMemberModal = ({ memberData, ...props }) => {
  const {
    education,
    email,
    experience,
    formerPositions,
    isBoardMember,
    isDependentBoardMember,
    image: Image,
    jobTitle,
    name,
    otherPositions,
    ownership,
    profession,
    socialAccounts,
    startDate,
    warrants
  } = memberData

  const accountsArray = socialAccounts && Object.entries(socialAccounts)
  if (email) accountsArray.unshift(['Email', `mailto:${email}`])

  return (
    <Modal size="lg" centered {...props}>
      <Modal.Header closeButton />
      <Circle size="lg" className="mx-auto mb-3">
        <Image
          alt={name}
          className="w-100 h-100"
          objectFit="cover"
          objectPosition="top center"
        />
      </Circle>
      <Text as="h4" align="center">
        {name}
      </Text>
      <Text as="h6" align="center">
        {jobTitle}
        {isBoardMember && isDependentBoardMember && ', Dependent'}
        {isBoardMember && !isDependentBoardMember && ', Independent'}
      </Text>
      <Text as="div" transform="uppercase" align="center" monospace>
        {accountsArray &&
          accountsArray
            .map(([account, link]) => (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                key={link}
              >
                {account}
              </a>
            ))
            .reduce((prev, curr) => [prev, '/', curr])}
      </Text>
      <Modal.Body>
        {profession && (
          <div>
            <Text as="div" size="md" weight="bold">
              Profession:
            </Text>
            <p>{profession}</p>
          </div>
        )}
        <div>
          <Text as="div" size="md" weight="bold">
            Experience:
          </Text>
          {experience}
        </div>
        {otherPositions && (
          <div>
            <Text as="div" size="md" weight="bold">
              Other positions:
            </Text>
            <ul>
              {otherPositions.map((position) => (
                <li>{position}</li>
              ))}
            </ul>
          </div>
        )}
        {formerPositions && (
          <div>
            <Text as="div" size="md" weight="bold">
              Former positions:
            </Text>
            <ul>
              {formerPositions.map((position) => (
                <li>{position}</li>
              ))}
            </ul>
          </div>
        )}
        {education && (
          <div>
            <Text as="div" size="md" weight="bold">
              Educational background:
            </Text>
            <ul>
              {education.map((title) => (
                <li>{title}</li>
              ))}
            </ul>
          </div>
        )}
        {startDate && (
          <div>
            <Text as="div" size="md" weight="bold">
              Starting date: <Text weight="normal">{startDate}</Text>
            </Text>
          </div>
        )}
        {ownership && (
          <div>
            <Text as="div" size="md" weight="bold">
              Ownership:{' '}
              <Text weight="normal">{numberToString(ownership)} shares</Text>
            </Text>
          </div>
        )}
        {warrants && (
          <div>
            <Text as="div" size="md" weight="bold">
              Warrants:{' '}
              <Text weight="normal">{numberToString(warrants)} shares</Text>
            </Text>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

BoardMemberModal.propTypes = {
  memberData: PropTypes.shape().isRequired
}

export default BoardMemberModal
