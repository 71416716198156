import React from 'react'

import SEO from '../../SEO'

import ReturnHero from './ReturnHero'
import Sections from './Sections'

const ReturnPage = () => (
  <>
    <SEO
      title="Afkast - Få et højere afkast med lavere omkostninger"
      description="Se det historiske afkast for hver af NORD.investments 20 forskellige porteføljer. Investér og få et højere afkast med lavere omkostninger"
      keywords={['afkast', 'nord.investments', 'historisk afkast']}
      pathname="/afkast"
    />
    <ReturnHero />
    <Sections />
  </>
)

export default ReturnPage
