import PropTypes from 'prop-types'
import React from 'react'

const Investor = ({ image: ImageComponent, title, ...props }) => (
  <ImageComponent alt={title} {...props} />
)

Investor.propTypes = {
  image: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired
}

export default Investor
