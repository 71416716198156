import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import AnimatedLinkWithArrow from '../../../../AnimatedLinkWithArrow'
import GatsbyInternalLink from '../../../../GatsbyInternalLink'
import OnboardingLink from '../../../../OnboardingLink'

import styles from './Products.module.scss'

const ProductCard = ({ children, urlLink, onboardingPath }) => {
  const handleClick = (event) => event.stopPropagation()

  return (
    <ScrollTransition.Item as={Col} xs={12} md={6} lg={4} className="pb-4">
      <Card
        as={GatsbyInternalLink}
        to={urlLink}
        className={classNames(
          'text-center',
          'h-100',
          'shadow',
          styles.productsCard
        )}
      >
        <Card.Body className="d-flex align-items-center flex-column">
          <ScrollTransition.Item className={styles.productWrapper}>
            {children}
          </ScrollTransition.Item>
          <div className="mt-auto">
            <ScrollTransition.Item>
              <OnboardingLink
                component={BaffleButton}
                variant="success"
                className="my-4"
                path={onboardingPath}
                onClick={handleClick}
                filled
              >
                Kom i gang
              </OnboardingLink>
            </ScrollTransition.Item>
            <ScrollTransition.Item>
              <AnimatedLinkWithArrow icon={ArrowRight} href={urlLink}>
                Læs mere
              </AnimatedLinkWithArrow>
            </ScrollTransition.Item>
            <hr className={styles.productLinkUnderline} />
          </div>
        </Card.Body>
      </Card>
    </ScrollTransition.Item>
  )
}

ProductCard.propTypes = {
  children: PropTypes.node.isRequired,
  urlLink: PropTypes.string.isRequired,
  onboardingPath: PropTypes.string.isRequired
}

export default ProductCard
