import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'

const SubscriptionModal = ({ showModal, onCloseModal }) => {
  useEffect(() => {
    if (!showModal) return
    window.prs_scanElements()
  }, [showModal])

  return (
    <Modal show={showModal} onHide={onCloseModal} centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <div
          id="embedded-pressroom-subscription"
          data-publisher="13560369"
          data-subscribe="type=IR"
        />
      </Modal.Body>
    </Modal>
  )
}

SubscriptionModal.propTypes = {
  onCloseModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
}

export default SubscriptionModal
