import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const NatureImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      natureImage: file(
        relativePath: {
          eq: "components/pages/ResponsibleInvestmentPage/Sections/SunstainableSection/img/nature.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 1968, maxHeight: 1841) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.natureImage.childImageSharp.fluid} {...props} />
}

export default NatureImage
