import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'

import TeamImageIE from './images/team-ie.png'
import TeamImage from './Team'

const AdvantagesSection = () => (
  <BaseSection scrollReveal>
    <SectionWithOverflowImage
      image={<TeamImage />}
      imageAlign="left"
      imageForIE={TeamImageIE}
      variant="C"
      hasLines
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Fordele og Goder
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        Vi ønsker, at det skal være sjovt at gå på arbejde for alle! Derfor gør
        vi hvad vi kan for at skabe en god atmosfære. Derudover kommer jobbet
        altid med en række attraktive goder:
      </BaseSection.Text>
      <CheckList>
        <ScrollTransition.Item as={CheckList.Item}>
          Lækker frokostordning, på vores kontor på Christianshavn
        </ScrollTransition.Item>
        <ScrollTransition.Item as={CheckList.Item}>
          Fredagsbar og halvårlige ﬁrmafester
        </ScrollTransition.Item>
        <ScrollTransition.Item as={CheckList.Item}>
          Fleksibilitet - uanset om du skal hente børn eller en tur til
          tandlægen
        </ScrollTransition.Item>
      </CheckList>
    </SectionWithOverflowImage>
  </BaseSection>
)

export default AdvantagesSection
