import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const MichaelImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      michaelImage: file(
        relativePath: {
          eq: "components/pages/WealthPage/Sections/TeamSection/images/board-image.jpg"
        }
      ) {
        childImageSharp {
          fluid(
            quality: 100
            maxWidth: 1350
            maxHeight: 3100
            cropFocus: NORTHWEST
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.michaelImage.childImageSharp.fluid} {...props} />
}

export default MichaelImage
