import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import {
  numberToPercent,
  numberToCurrency
} from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import classNames from 'classnames'
import React, { useContext } from 'react'
import Card from 'react-bootstrap/Card'

import CostsContext from '../CostsContext'

import styles from './index.module.scss'

const Costs = () => {
  const { investmentAmount, costs } = useContext(CostsContext)

  const monthlyCosts = Math.round((investmentAmount * costs) / 12)

  return (
    <Card className="mb-5 d-none d-lg-block">
      <Card.Body>
        <Card.Title as="h4" className="text-center text-xl-left">
          Din totalomkostning
        </Card.Title>
        <div className="d-lg-flex justify-content-center align-items-center flex-column flex-xl-row justify-content-xl-between">
          <div
            className={classNames(
              'font-size-md',
              'd-flex',
              'flex-row',
              'align-items-center',
              styles.costsBox
            )}
          >
            <Text as="p" className="font-size-xl p-2">
              {numberToPercent(costs)}
            </Text>
            <Text as="p" variant="secondary" className="ml-5 font-size-lg p-2">
              årligt
            </Text>
          </div>
          <Card.Title className="pb-xl-3 align-self-xl-end mt-lg-3 mt-xl-0">
            Svarende til
          </Card.Title>
          <div className="mt-4 mt-lg-0">
            <div
              className={classNames(
                'font-size-md',
                'd-flex',
                'flex-row',
                'align-items-center',
                styles.costsBox
              )}
            >
              <Text as="p" className="font-size-xl p-2">
                {numberToCurrency(monthlyCosts)}
              </Text>
              <Text
                as="p"
                variant="secondary"
                className="ml-5 font-size-lg p-2"
              >
                pr. måned
              </Text>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  )
}

export default Costs
