import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import GatsbyInternalLink from '../GatsbyInternalLink'

import styles from './index.module.scss'

const AnimatedLinkWithArrow = ({ children, icon, ...props }) => (
  <GatsbyInternalLink
    className={classNames(
      styles.animatedArrow,
      'link-white',
      'text-uppercase',
      'font-size-md'
    )}
    {...props}
  >
    {children}
    <Icon icon={icon} className="ml-1" />
  </GatsbyInternalLink>
)

AnimatedLinkWithArrow.propTypes = {
  children: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.shape({ render: PropTypes.func.isRequired })
  ]).isRequired
}

export default AnimatedLinkWithArrow
