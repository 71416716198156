import ContentWrapper from '@nordinvestments/nord-storybook/dist/components/ContentWrapper'
import NordHero from '@nordinvestments/nord-storybook/dist/components/Hero'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import PropTypes from 'prop-types'
import React from 'react'
import { Row } from 'react-bootstrap'

const STAGGER = 125
const DELAY = 250

const Hero = React.forwardRef(({ children, ...props }, ref) => (
  <Row>
    <NordHero ref={ref} topSection {...props}>
      <ContentWrapper>
        <ScrollTransition stagger={STAGGER} animation={{ delay: DELAY }}>
          <div>{children}</div>
        </ScrollTransition>
      </ContentWrapper>
    </NordHero>
  </Row>
))

Hero.propTypes = {
  children: PropTypes.node.isRequired
}

Hero.displayName = 'BaseSection.Hero'

export default Hero
