import anime from 'animejs'
import React, { useEffect, useState } from 'react'

import Section from '../Section'

import { ReactComponent as Animation } from './Animation.svg'
import InfoContent from './InfoContent'

const RiskSection = () => {
  const [animationParams, setAnimationParams] = useState([])

  useEffect(() => {
    const containerElement = document.getElementById('risk-animation')
    const risikoPaths = containerElement
      .getElementById('risk-waves')
      .getElementsByTagName('path')
    const badeBold = containerElement.getElementById('risk-bathing-ring')

    const coordinates = badeBold.getBBox()
    const centerX = coordinates.x + coordinates.width / 2
    const centerY = coordinates.y + coordinates.height / 2
    const center = `${centerX}px ${centerY}px`

    setAnimationParams([
      {
        targets: risikoPaths,
        translateX: ['-25%', '0%'],
        opacity: [0, 0.33],
        duration: 750,
        delay: anime.stagger(100, { direction: 'reverse' })
      },
      {
        targets: badeBold,
        scale: [0, 1],
        rotate: [90, 0],
        opacity: [0, 1],
        transformOrigin: [center, center],
        duration: 750
      }
    ])
  }, [])

  return (
    <Section
      animationParams={animationParams}
      animationElement={<Animation />}
      infoElement={<InfoContent />}
      title="Få det optimale afkast i forhold til din risikovillighed"
      contentText={`
        NORDs investeringsteori bygger på en Nobelprisvindende model. Din
        portefølje sammensættes af ETF´ere med aktier, obligationer og andre
        aktivklasser, så den giver det størst mulige afkast i forhold til din
        risikovillighed.
      `}
      buttonText="Sådan virker vores metode"
    />
  )
}

export default RiskSection
