import PropTypes from 'prop-types'
import React from 'react'

import BaseSection from '../BaseSection'

import FAQCard from './FAQCard'

const FAQSection = ({ questions }) => (
  <BaseSection fullScreen={false} scrollReveal>
    <FAQCard questions={questions} />
  </BaseSection>
)

FAQSection.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape,
        PropTypes.node
      ]).isRequired
    })
  ).isRequired
}

export default FAQSection
