import React, { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../BaseSection'

// TODO does not work
const ReviewsSection = () => {
  useEffect(() => {
    if (window.Trustpilot)
      window.Trustpilot.Modules.WidgetManagement.findAndApplyWidgets()
  }, [])

  return (
    <BaseSection className="bg-dark text-white" scrollReveal>
      <BaseSection.Header.SectionTop scrollReveal>
        Vores kunder siger
      </BaseSection.Header.SectionTop>
      <Row>
        <Col>
          <div
            className="trustpilot-widget"
            data-locale="da-DK"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-businessunit-id="58f9cd750000ff0005a0e899"
            data-style-height="140px"
            data-style-width="100%"
            data-theme="dark"
          >
            <div className="text-center">
              <a
                href="https://dk.trustpilot.com/review/nord.investments"
                target="_blank"
                rel="noopener noreferrer"
                className="link-light"
              >
                Gå til Trustpilot
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </BaseSection>
  )
}

export default ReviewsSection
