import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Mail from 'react-feather/dist/icons/mail'
import Phone from 'react-feather/dist/icons/phone'

const ContactPerson = ({
  company,
  email,
  jobTitle,
  name,
  phone,
  image: Image
}) => (
  <ScrollTransition.Item
    as={Col}
    xs={12}
    md={6}
    className="text-center d-flex flex-column my-3"
  >
    <Circle className="mx-auto mb-3">
      <Image
        alt={name}
        className="w-100 h-100"
        objectFit="cover"
        objectPosition="top center"
      />
    </Circle>
    <Text as="h5" variant="white" className="mt-2 mb-0">
      {name}
    </Text>
    <Text as="div" variant="white">
      {jobTitle}
      <br /> {company}
    </Text>
    <Text as="div" variant="warning" className="mt-auto pt-2">
      <a
        href={`mailto:${email}`}
        target="_blank"
        rel="noopener noreferrer"
        className="d-block link-light"
      >
        <Icon icon={Mail} className="mr-2" />
        {email}
      </a>
      <a
        href={`tel:${phone}`}
        target="_blank"
        rel="noopener noreferrer"
        className="d-block link-light"
      >
        <Icon icon={Phone} className="mr-2" />
        {phone}
      </a>
    </Text>
  </ScrollTransition.Item>
)

ContactPerson.propTypes = {
  company: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  image: PropTypes.elementType.isRequired,
  jobTitle: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
}

export default ContactPerson
