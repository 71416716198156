import React from 'react'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'
import TestimonialCard from '../../../../TestimonialCard'

import * as images from './images'

const TestimonialsSection = () => (
  <BaseSection className="bg-primary" scrollReveal>
    <BaseSection.Header.Base
      variant="white"
      align="center"
      className="pb-5"
      scrollReveal
    >
      Hvad siger andre om NORD wealth?
    </BaseSection.Header.Base>
    <Row>
      <TestimonialCard
        content="Jeg valgte NORD.investments på grund af deres uvildighed. Hos bankerne kan det være svært at skelne mellem rådgivning og salg. Hos NORD.investment var det tydeligt, at jeg var i trygge hænder."
        name="Thomas Jensen"
        position="Direktør"
        image={images.Thomas}
      />
      <TestimonialCard
        content="Efter at have solgt min virksomhed, skulle jeg ﬁnde nogen til at varetage min investering. Valget faldt på NORD.investments og jeg har ikke set mig tilbage siden."
        name="Rolf Garde"
        position="Direktør"
        image={images.Rolf}
      />
    </Row>
  </BaseSection>
)

export default TestimonialsSection
