const advantages = [
  {
    title: 'Lavere omkostninger',
    text:
      'Modsat Private Banking inviterer vi dig ikke i Tivoli eller en tur i teatret. Til gengæld kan du selv kan vælge, hvad de sparede omkostninger skal bruges på.'
  },
  {
    title: 'Introduktionsmøde',
    text:
      'Inden du investerer tilbyder vi at holde et møde, hvor du bliver grundigt introduceret til NORD.investments samt hvordan vi kan hjælpe dig.'
  },
  {
    title: 'Investeringsanalyse',
    text:
      'Er du allerede investeret i dag, tilbyder vi en gennemgang af din portefølje. Her kigger vi bl.a. på afkast, risiko og omkostninger. Så har du lidt at sammenligne med.'
  },
  {
    title: 'Personlig kontaktperson',
    text:
      'Som NORD Wealth kunde får du en personlig kontaktperson, der prioriterer dine henvendelser.'
  },
  {
    title: 'Løbende sparring',
    text:
      'Vi er altid til at få fat på. Ønsker du en kort snak på telefonen eller en gennemgang af din portefølje, så står vi altid klar.'
  },
  {
    title: 'Udviddet rapportering',
    text:
      'Hvis du har behov, trækker vi gerne øvrige rapporter, hvad end du vil have mere indsigt i din investering, eller du mangler dokumentation til dit regnskab.'
  }
]

export default advantages
