// meta(name="description" content="Alle vores porteføljer er uvildigt sammensat, og vi modtager ingen provision. Alle investeringsplaner bliver sammensat af nøje udvalgte ETF'ere.")
// meta(name="keywords" content="investerinsgsplan, investeringsforeninger, fonde, uafhængig, uvildige, transparente, re-balancere, opsparing, portefølje, nord invest")
// meta(property="og:title", content="Datadreven service til langsigtet investering")
// meta(property="og:description", content="Alle vores porteføljer er uvildigt sammensat, og vi modtager ingen provision. Alle investeringsplaner bliver sammensat af nøje udvalgte ETF'ere.")
// meta(property="og:url", content="https://www.nord.investments/privatlivspolitik")
// meta(property="og:site_name", content="NORD.investments")
// link(rel="canonical", href="https://www.nord.investments/om")

// meta(charset="utf-8")
// meta(http-equiv="x-ua-compatible" content="ie=edge")
// meta(name="viewport" content="width=device-width, initial-scale=1")
// meta(name="google-site-verification" content="2yqj4-kUwNqI0_68RvymoDwEevVqgA9RVT-Kkcv5qko")

// meta(property="og:locale", content="da_DK")
// meta(property="og:type", content="website")
// meta(property="og:image" content="/img/nordicon1024px.png")

// link(rel="shortcut icon" href="/img/favicon.ico" type="image/x-icon")
// link(rel="icon" href="/img/favicon.ico" type="image/x-icon")
// link(rel="image_src" href="/img/nordicon1024px.png")
// link(rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous")

import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../BaseSection'
import SEO from '../../SEO'

import Collection from './Collection'
import Confidentiality from './Confidentiality'
import Contact from './Contact'
import Cookies from './Cookies'
import Disclosure from './Disclosure'
import Introduction from './Introduction'
import Management from './Management'
import Storage from './Storage'

const PrivacyPolicyPage = () => (
  <>
    <SEO
      title="Danmarks billigste investeringsservice"
      description="Vi anvender lav omkostnings indeks fonde. Online selvbetjeningsplatform."
      keywords={[
        'ÅOP',
        'lave omkostninger',
        'renters rente',
        'årligt afkast',
        'valutaspread',
        'handelsomkostninger',
        'depotomkostninger',
        'fondsomkostninger',
        'vekselomkostninger'
      ]}
      pathname="/priser"
    />
    <BaseSection height="full">
      <Row>
        <Col className="pt-6 mt-6">
          <Card>
            <Card.Body>
              <Card.Title>
                <h1>Cookie- og privatlivspolitik</h1>
              </Card.Title>
              <Introduction />
              <Collection />
              <Management />
              <Disclosure />
              <Storage />
              <Confidentiality />
              <Cookies />
              <Contact />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </BaseSection>
  </>
)

export default PrivacyPolicyPage
