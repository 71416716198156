import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import ExpandableList from '@nordinvestments/nord-storybook/dist/components/ExpandableList'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Download from 'react-feather/dist/icons/download'

const htmlToText = (html) => {
  const htmlToTextRegex = /<\/?[^>]+(>|$)/g
  const text = html.replace(htmlToTextRegex, '')

  return text
}

const extractText = (html) => {
  const extractSubtitleRegex = /^(<p[^>]+>[^<]+<\/p>)/m

  const htmlDescription = html.replace(extractSubtitleRegex, '')
  const htmlSubtitle = html.match(extractSubtitleRegex)[0]

  const description = htmlToText(htmlDescription).slice(0, 150)
  const subtitle = htmlToText(htmlSubtitle)

  return { description, subtitle }
}

const Announcement = ({ data, isEven }) => {
  const { content, id } = data
  const { da } = content
  const { title, attachments, websiteUrl, html } = da

  const { description, subtitle } = extractText(html)

  const handleClick = (event) => event.stopPropagation()

  return (
    <ScrollTransition.Item
      as={Col}
      xs={12}
      md={6}
      lg={5}
      className={classNames(
        'py-6',
        'text-center',
        'text-lg-left',
        'd-flex',
        'flex-column',
        {
          'offset-lg-2': isEven
        }
      )}
    >
      <ScrollTransition.Item as="h3" className="text-white">
        {title}
      </ScrollTransition.Item>
      <ScrollTransition.Item as="div" className="text-light-white mb-3">
        {subtitle}
      </ScrollTransition.Item>
      <ScrollTransition.Item as="p" className="font-size-lg">
        {description}...
      </ScrollTransition.Item>
      <div className="mt-auto d-flex flex-column flex-lg-row align-items-center justify-content-center justify-content-lg-between">
        <div className="align-self-lg-baseline">
          <ScrollTransition.Item
            as={BaffleButton}
            onClick={handleClick}
            href={websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
            variant="success"
            size="sm"
            filled
          >
            Læs mere
          </ScrollTransition.Item>
        </div>
        <div>
          <ExpandableList className="align-self-lg-baseline pt-3 pt-lg-0 mx-auto mx-lg-0">
            <ExpandableList.Item
              eventKey={id}
              title={
                <div className="ml-lg-auto text-uppercase">
                  {attachments.length} Vedhæftede filer
                </div>
              }
              className="d-inline-flex d-lg-flex"
              reversedColorVariant
            >
              <div className="text-center text-lg-right">
                {attachments.map((attachment) => (
                  <a
                    href={attachment.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block link-light"
                  >
                    {attachment.title}
                    <Icon icon={Download} className="ml-1" />
                  </a>
                ))}
              </div>
            </ExpandableList.Item>
          </ExpandableList>
        </div>
      </div>
    </ScrollTransition.Item>
  )
}

Announcement.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape({
      da: PropTypes.shape()
    }).isRequired,
    id: PropTypes.string.isRequired,
    publicationDate: PropTypes.string.isRequired
  }).isRequired,
  isEven: PropTypes.bool.isRequired
}

export default Announcement
