import anime from 'animejs'
import React, { useEffect, useState } from 'react'

import Section from '../Section'

import { ReactComponent as Animation } from './Animation.svg'
import InfoContent from './InfoContent'

const ETFSection = () => {
  const [animationParams, setAnimationParams] = useState([])

  useEffect(() => {
    const containerElement = document.getElementById('model-animation')
    const graphElement = containerElement.getElementById('model-graph')
    const indicatorLines = containerElement
      .getElementById('model-indicators')
      .getElementsByTagName('line')
    const horizontalLines = containerElement
      .getElementById('model-horizontal-lines')
      .getElementsByTagName('line')

    setAnimationParams([
      {
        targets: graphElement,
        strokeDashoffset: [795, 0],
        opacity: [0, 1],
        duration: 1250
      },
      {
        targets: horizontalLines,
        opacity: [0, 0.33],
        duration: 750,
        delay: anime.stagger(100)
      },
      {
        targets: indicatorLines,
        translateX: ['-100%', '0%'],
        opacity: [0, 0.33],
        duration: 250,
        delay: anime.stagger(100)
      }
    ])
  }, [])

  return (
    <Section
      animationParams={animationParams}
      animationElement={<Animation />}
      infoElement={<InfoContent />}
      title="ETF'er følger markedets afkast"
      contentText={
        <>
          Historiske data viser os, at alle papirerne tilsammen - et marked - i
          nogle perioder falder men ellers stiger over tid.{' '}
          <a
            href="https://blog.nord.investments/etf"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            className="link-white"
          >
            ETF´ere
          </a>{' '}
          er en investeringsform der følger markederne og giver dig ejerskab af
          tusinder af værdipapirer.
        </>
      }
      buttonText="Hvad er en ETF?"
    />
  )
}

export default ETFSection
