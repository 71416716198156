import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import HistoricalReturn from '@nordinvestments/nord-storybook/dist/components/HistoricalReturn'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import RiskScoreCard from '@nordinvestments/nord-storybook/dist/components/RiskScoreCard'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import useInView from '@nordinvestments/nord-storybook/dist/hooks/useInView'
import React, { useState, useEffect, useCallback } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaseSection from '../../../../BaseSection'
import OnboardingLink from '../../../../OnboardingLink'

import styles from './index.module.scss'

const ChartSection = () => {
  const [riskScore, setRiskScore] = useState(10)
  const [sectionIsInView, setSectionIsInView] = useState(false)
  const [windowIsBelowInput, setWindowIsBelowInput] = useState(false)

  const handleChangeWindowIsBelowInput = (value) => setWindowIsBelowInput(value)

  const handleChangeRiskScore = ({ riskScore: newRiskScore }) =>
    setRiskScore(newRiskScore)

  const handleInView = useCallback(({ inView }) => {
    setSectionIsInView(inView)
  }, [])
  const refGraphSection = useInView(handleInView)

  const largeAndUp = useBreakpointHandler('lg')

  useEffect(() => {
    if (typeof document === 'undefined') return
    const intercomButton = document.querySelectorAll(
      '#intercom-container .intercom-app iframe'
    )[0]
    const intercomNotificationCircle = document.querySelectorAll(
      '#intercom-container .intercom-app iframe'
    )[1]

    const bottomNavHeight = 100
    const buttonAndNotificationDifference = 45

    if (intercomButton === null || intercomNotificationCircle === null) return
    if (
      intercomButton === undefined ||
      intercomNotificationCircle === undefined
    )
      return

    if (windowIsBelowInput && sectionIsInView && !largeAndUp) {
      intercomButton.style.bottom = `${bottomNavHeight}px`
      intercomNotificationCircle.style.bottom = `${
        bottomNavHeight + buttonAndNotificationDifference
      }px`
    } else {
      intercomButton.style.bottom = null
      intercomNotificationCircle.style.bottom = null
    }

    // eslint-disable-next-line consistent-return
    return () => {
      intercomButton.style.bottom = null
      intercomNotificationCircle.style.bottom = null
    }
  }, [largeAndUp, sectionIsInView, windowIsBelowInput])

  return (
    <BaseSection ref={refGraphSection} scrollReveal>
      <Row className="justify-content-center">
        <Col xs={12} lg={4} xl={3} className="my-4 my-lg-0">
          <RiskScoreCard
            className={styles.riskScoreCard}
            riskScore={riskScore}
            sticky={sectionIsInView}
            onValueChange={handleChangeRiskScore}
            onIsBelowInputChange={handleChangeWindowIsBelowInput}
            footer={
              <div className="text-center mt-3">
                <Text as="p" variant="white" size="md" className="m-2">
                  Find din personlige risikoprofil og få en gratis
                  investeringsplan
                </Text>
                <div className="d-flex justify-content-center mb-4">
                  <OnboardingLink
                    component={IconButton}
                    variant="success"
                    icon={ArrowRight}
                  >
                    Kom i gang
                  </OnboardingLink>
                </div>
              </div>
            }
            enableStocksOnlySwitch
            withTopFixedNavigation
            bottom
          />
        </Col>
        <Col xs={12} lg={8} xl={9}>
          <HistoricalReturn riskScore={riskScore}>
            <Card>
              <Card.Body className="px-2 px-md-4 px-lg-6 mx-lg-4">
                <HistoricalReturn.Header />
                <HistoricalReturn.Chart />
                <HistoricalReturn.Brush />
              </Card.Body>
            </Card>
            <Card className="mt-5">
              <Card.Body>
                <HistoricalReturn.Table />
              </Card.Body>
            </Card>
          </HistoricalReturn>
        </Col>
      </Row>
    </BaseSection>
  )
}

export default ChartSection
