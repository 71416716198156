import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const TeamImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      teamImage: file(
        relativePath: {
          eq: "components/pages/HiringPage/HiringHero/images/team.jpg"
        }
      ) {
        childImageSharp {
          fluid(
            quality: 50
            maxWidth: 2092
            maxHeight: 1050
            cropFocus: SOUTH
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.teamImage.childImageSharp.fluid} {...props} />
}

export default TeamImage
