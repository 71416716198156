import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import advantages from './advantages'

const AdvantagesContent = () => (
  <Row className="pt-lg-3">
    {advantages.map((advantage) => (
      <Col
        key={advantage.title}
        xs={12}
        lg={6}
        className="py-5 text-center text-lg-left"
      >
        <BaseSection.Header.Base
          level={4}
          size="xl"
          variant="white"
          scrollReveal
        >
          {advantage.title}
        </BaseSection.Header.Base>
        <BaseSection.Text variant="body" className="pr-lg-5" scrollReveal>
          {advantage.text}
        </BaseSection.Text>
      </Col>
    ))}
  </Row>
)

export default AdvantagesContent
