import React from 'react'

const Disclosure = () => (
  <>
    <h2>Videregivelse</h2>

    <p>
      Såfremt du vælger at oprette dig hos NORD, giver du os samtidig tilladelse
      til at indsamle og videregive oplysninger om dig til brug for oprettelse
      og vedligeholdelse i vores partnerbank, Saxo Bank A/S.
    </p>
    <p>
      Dette er med henblik på opfyldelse af den til enhver tid gældende
      lovgivning om identifikation til forebyggelse af hvidvaskning. Du giver
      ligeledes tilladelse til, at NORD videregiver disse data til Saxo Bank A/S
      så de til enhver tid lever op til samarbejdsaftalen med NORD samt
      finansiel regulering, herunder hvidvasklovgivningen.
    </p>
    <p>
      I nogle tilfælde deler vi dine data med øvrige samarbejdspartnere. Dette
      sker dog aldrig uden, at NORD har fuld kontrol over dine data, da en sådan
      databehandler alene kan tilgå og behandle dine data efter den foreliggende
      databehandleraftale mellem NORD og den pågældende samarbejdspartner.
    </p>
    <p>
      NORD kan overdrage persondata til en eller flere af følgende kategorier af
      tredjeparter:
    </p>
    <ul>
      <li>
        Markedsførings- og kommunikationssystemer samt andre administrative
        systemer.
      </li>
      <li>Advokater og andre der yder konsulenthjælp.</li>
      <li>Driftsoperatører - f.eks. leverandører af servere.</li>
      <li>Finanstilsynet og andre myndigheder.</li>
    </ul>

    <p>Denne liste er ikke udtømmende.</p>
    <p>
      Jævnfør afsnittet om indsamling og behandling af data, kan formålet med
      videregivelse af din data eksempelvis være etableringen, vedligeholdelse
      eller afslutning af dit kundeforhold. Videregivelse af data sker altså med
      henblik på at kunne levere vores ydelser eller markedsføring.
    </p>
  </>
)

export default Disclosure
