import PropTypes from 'prop-types'
import React from 'react'

const Video = ({ mp4VideoSrc, webmVideoSrc, posterSrc, isHero }) => {
  return (
    <>
      <video
        preload={isHero}
        poster={posterSrc}
        autoPlay
        loop
        muted
        playsInline
      >
        <source src={webmVideoSrc} type="video/webm" />
        <source src={mp4VideoSrc} type="video/mp4" />
      </video>
    </>
  )
}

Video.propTypes = {
  mp4VideoSrc: PropTypes.node.isRequired,
  webmVideoSrc: PropTypes.node.isRequired,
  posterSrc: PropTypes.node.isRequired,
  isHero: PropTypes.bool
}

Video.defaultProps = {
  isHero: false
}

export default Video
