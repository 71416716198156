import React from 'react'

const Contact = () => (
  <>
    <h2>Kontakt og indsigt</h2>

    <p>
      Vi tager din sikkerhed alvorligt og vi gør alt hvad vi kan for at være
      transparente omkring den måde vi behandler data.
    </p>
    <p>
      Du kan altid få indsigt i de data vi behandler om dig. Du har ligeledes
      ret til at få ajourført, rettet eller slettet oplysninger om dig.
    </p>
    <p>
      Helt konkret, har du ifølge Databeskyttelsesloven og
      Persondataforordningen en række rettigheder:
    </p>
    <ul>
      <li>
        Du har ret til at få indsigt i, hvilke personoplysninger vi behandler om
        dig.
      </li>
      <li>
        Du har ret til at få berigtiget og ajourført de personoplysninger, vi
        har registreret om dig.
      </li>
      <li>
        Du har ret til at få slettet de personoplysninger, vi har registreret om
        dig. Ønsker du at få slettet dine personoplysninger, sletter vi alle
        oplysninger, som vi ikke efter lovgivning erpålagt at skulle gemme.
      </li>
      <li>
        Er behandlingen af personoplysninger baseret på et samtykke fra dig, har
        du ret til at trække samtykket tilbage, hvilket betyder, at behandling
        herefter ophører, medmindre vi efter lovgivning er pålagt at skulle
        behandle personoplysningerne.
      </li>
    </ul>

    <p>
      Alle anmodninger herom skal fremsættes skriftligt pr. e-mail. Hvis du har
      spørgsmål, kommentarer eller klager til NORD behandling af
      personoplysninger, så er du altid velkommen til at kontakte os på
      nedenstående e-mail:
    </p>
    <p>
      <a href="mailto:hello@nord.investments">hello@nord.investments</a>
    </p>

    <p>Eller per brev til:</p>
    <p>
      NORD.investments A/S
      <br />
      Applebys Plads 7
      <br />
      1411 København K
      <br />
      Danmark
    </p>
    <p>
      Hvis du ønsker at klage over Saxo Bank A/S bedes du kontakte Saxo Bank
      A/S’ klageansvarlige afdeling på{' '}
      <a href="mailto:complaints@saxobank.com">complaints@saxobank.com</a> eller
      på følgende adresse:
    </p>
    <p>
      Saxo Bank A/S
      <br />
      Att: Complaints
      <br />
      Philip Heymans Allé 15
      <br />
      2900 Hellerup
    </p>
    <p>
      Du har altid ret til at foretage en indsigelse mod den måde vi behandler
      dine persondata, hvis du mener at vi behandler dine persondata i strid med
      gældende lovgivning. Indsigten kan dog være i visse tilfælde være
      begrænset af hensyn til andre personers privatlivsbeskyttelse,
      forretningshemmeligheder eller immaterielle rettigheder.
    </p>
    <p>
      Eventuelle klager kan også rettes til Datatilsynet. Den aktuelle
      kontaktadresse kan findes på{' '}
      <a href="https://www.datatilsynet.dk">www.datatilsynet.dk</a>.
    </p>
    <h2>Lovvalg</h2>

    <p>
      Brugen af denne hjemmeside, samt generel benyttelse af NORD ydelser er
      underlagt dansk ret. Eventuelle tvister skal behandles af de danske
      domstole.
    </p>
  </>
)

export default Contact
