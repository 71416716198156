import styleConfiguration from '@nordinvestments/nord-storybook/dist/configuration/styleConfiguration'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import { numberToCurrency } from '@nordinvestments/nord-storybook/dist/utilities/numberFormatter'
import { getYear } from 'date-fns'
import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { withResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { AreaChart, Area, XAxis, YAxis, Legend } from 'recharts'

import CostsContext from '../CostsContext'

import styles from './Chart.module.scss'

const { themeColors, blues } = styleConfiguration

const YEARLY_COST_BANK = 2.5 / 100 // 2.5 %
const YEARLY_RETURN = 6.5 / 100 // 6 %

const INVESTMENT_MILESTONES = [6, 12, 18, 24, 30]

const numberToMilions = (number) => {
  if (number >= 500000) {
    return `${(number / 1000000).toFixed(1)} M`
  }

  return numberToCurrency(number)
}

const Chart = ({ width }) => {
  const { investmentAmount, costs } = useContext(CostsContext)

  const CURRENT_YEAR = getYear(new Date())

  const YEAR_MILESTONES = INVESTMENT_MILESTONES.map(
    (value) => value + CURRENT_YEAR
  )

  const investmentReturns = INVESTMENT_MILESTONES.map((investmentPeriod) =>
    Math.round(
      investmentAmount * (1 + (YEARLY_RETURN - costs)) ** investmentPeriod,
      10
    )
  )

  const investmentReturnsBank = INVESTMENT_MILESTONES.map((investmentPeriod) =>
    Math.round(
      investmentAmount *
        (1 + (YEARLY_RETURN - YEARLY_COST_BANK)) ** investmentPeriod,
      10
    )
  )

  const mediumAndUp = useBreakpointHandler('md')

  let aspectRatio
  if (mediumAndUp) {
    aspectRatio = 2
  } else {
    aspectRatio = 0.8
  }

  const data = [
    { year: '0 år', NORD: investmentAmount, Andre: investmentAmount },
    {
      year: YEAR_MILESTONES[0],
      NORD: investmentReturns[0],
      Andre: investmentReturnsBank[0]
    },
    {
      year: YEAR_MILESTONES[1],
      NORD: investmentReturns[1],
      Andre: investmentReturnsBank[1]
    },
    {
      year: YEAR_MILESTONES[2],
      NORD: investmentReturns[2],
      Andre: investmentReturnsBank[2]
    },
    {
      year: YEAR_MILESTONES[3],
      NORD: investmentReturns[3],
      Andre: investmentReturnsBank[3]
    },
    {
      year: '30 år',
      NORD: investmentReturns[4],
      Andre: investmentReturnsBank[4]
    }
  ]

  const legendPayload = [
    {
      value: <span className="text-dark">NORD</span>,
      type: 'circle',
      color: blues[300]
    },
    {
      value: <span className="text-dark">Andre</span>,
      type: 'circle',
      color: themeColors.dark
    }
  ]

  return (
    <div className="position-relative">
      <AreaChart
        width={width}
        height={width / aspectRatio}
        data={data}
        className={styles.expectedReturnChart}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20
        }}
      >
        <Legend verticalAlign="top" height={40} payload={legendPayload} />
        <XAxis dataKey="year" ticks={[data[0].year, data[5].year]} />
        <YAxis
          fill="none"
          yAxisId="right"
          orientation="right"
          ticks={[data[5].NORD, data[5].Andre]}
          tickFormatter={(tick) => numberToMilions(tick)}
          domain={[0, 'dataMax']}
        />
        <YAxis
          orientation="left"
          ticks={[data[0].NORD]}
          fill={themeColors.primary}
          tickFormatter={(tick) => numberToMilions(tick)}
          domain={[investmentAmount / 2, 'dataMax']}
        />
        <Area
          type="monotone"
          dataKey="NORD"
          stroke={blues[300]}
          fill={blues[300]}
          isAnimationActive={false}
          fillOpacity={1}
          dot={{ fill: blues[300], strokeWidth: 5 }}
        />
        <Area
          type="monotone"
          dataKey="Andre"
          stroke={themeColors.dark}
          fill={themeColors.dark}
          isAnimationActive={false}
          fillOpacity={1}
          dot={{ fill: blues[300], strokeWidth: 5 }}
        />
      </AreaChart>
    </div>
  )
}

Chart.defaultProps = {
  width: undefined
}

Chart.propTypes = {
  width: PropTypes.number
}

export default withResizeDetector(Chart)
