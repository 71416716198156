import React from 'react'

import FAQSection from '../../../FAQSection'

import AdvantagesSection from './AdvantagesSection'
import InvestmentSection from './InvestmentSection'
import NordWealthSection from './NordWealthSection'
import PortfolioSection from './PortfolioSection'
import ProductSection from './ProductsSection'
import questionsData from './questionsData'

const Sections = () => (
  <>
    <ProductSection />
    <PortfolioSection />
    <NordWealthSection />
    <InvestmentSection />
    <AdvantagesSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
