import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'

import styles from './PositionCard.module.scss'

const SCROLL_REVEAL_ITEM_COUNT = 3

const PositionCard = ({
  defaultOpenPosition,
  index,
  indexOffset,
  title,
  employment,
  link
}) => {
  const startIndex = index * SCROLL_REVEAL_ITEM_COUNT * indexOffset

  return (
    <ScrollTransition.Item
      index={startIndex}
      as={Col}
      xs={12}
      md={6}
      lg={4}
      className="my-3 my-lg-0"
    >
      <Card
        as="a"
        href={link}
        className={classNames(styles.cardScale, 'h-100')}
      >
        <ScrollTransition.Item
          as={Card.Body}
          className={classNames(
            styles.cardBodyWrapper,
            'd-flex flex-column align-items-center text-center my-5'
          )}
        >
          <Text as="h3" className="text-white">
            {title}
          </Text>
          <Text
            as="div"
            size="lg"
            variant="warning"
            align="center"
            className="mt-auto"
          >
            {employment}
          </Text>
          <BaffleButton
            className="mt-5"
            variant="success"
            as="a"
            href={link}
            filled
          >
            {defaultOpenPosition ? 'Skriv til os' : 'Læs mere'}
          </BaffleButton>
        </ScrollTransition.Item>
      </Card>
    </ScrollTransition.Item>
  )
}

PositionCard.propTypes = {
  defaultOpenPosition: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  indexOffset: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  employment: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

export default PositionCard
