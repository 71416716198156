import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import CheckList from '@nordinvestments/nord-storybook/dist/components/CheckList'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const AdvantagesSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <Row className="mt-n5">
      <Col>
        <ScrollTransition.Item as={Card} className="bg-deep-dark">
          <Card.Body>
            <Row className="mx-lg-4">
              <Col xs={12} lg={6} className="text-center text-lg-left">
                <BaseSection.Header.Base variant="white" scrollReveal>
                  Fordele ved wealth
                </BaseSection.Header.Base>
                <BaseSection.Text variant="body" scrollReveal>
                  Med NORD Wealth får du endnu lavere omkostninger og en
                  dedikeret personlig kontaktperson, der prioriterer dig og dine
                  spørgsmål.
                </BaseSection.Text>
              </Col>
              <Col xs={12} lg={5} className="offset-lg-1">
                <CheckList className="text-uppercase">
                  <ScrollTransition.Item as={CheckList.Item}>
                    Lavere omkostninger
                  </ScrollTransition.Item>
                  <ScrollTransition.Item as={CheckList.Item}>
                    Personlig kontaktperson
                  </ScrollTransition.Item>
                  <ScrollTransition.Item as={CheckList.Item}>
                    Prioriteret kundeservice
                  </ScrollTransition.Item>
                </CheckList>
              </Col>
            </Row>
          </Card.Body>
        </ScrollTransition.Item>
      </Col>
    </Row>
  </BaseSection>
)

export default AdvantagesSection
