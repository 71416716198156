import React from 'react'

import FAQSection from '../../../FAQSection'

import EnviromentSection from './EnviromentSection'
import InvestmentSection from './InvestmentSection'
import questionsData from './questionsData'
import SunstainableSection from './SunstainableSection'

const Sections = () => (
  <>
    <EnviromentSection />
    <InvestmentSection />
    <SunstainableSection />
    <FAQSection questions={questionsData} />
  </>
)

export default Sections
