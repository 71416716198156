import * as images from './images'

const teamData = [
  {
    name: 'Anders Hartmann',
    email: 'anders@nord.investments',
    jobTitle: 'CEO',
    image: images.Anders,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/andershartmann/',
      Twitter: 'https://twitter.com/hartmann_anders'
    }
  },
  {
    name: 'David Harboe',
    jobTitle: 'CTO',
    email: 'david@nord.investments',
    image: images.David,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/davidharboe/',
      Twitter: 'https://twitter.com/DavidHarboe'
    }
  },
  {
    name: 'Nikolaj Bomann Mertz',
    email: 'nikolaj@nord.investments',
    jobTitle: 'Head of Marketing',
    image: images.Nikolaj,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/nikolajbomann/'
    }
  },
  {
    name: 'Ketil Petersen',
    jobTitle: 'Investeringsdirektør / CIO',
    image: images.Ketil,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/ketil-petersen-8352746/'
    }
  },
  {
    name: 'Malene Ehrenskjöld',
    email: 'malene@nord.investments',
    jobTitle: 'Compliance / Bestyrelsesmedlem',
    image: images.Malene,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/malene-ehrenskj%C3%B6ld-2960dk/'
    }
  },
  {
    name: 'Johan Frølich',
    email: 'johan@nord.investments',
    jobTitle: 'Lead Software Engineer',
    image: images.Johan,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/johan-fr%C3%B8lich/'
    }
  },
  {
    name: 'Hans-Christian Helboe',
    email: 'hans-christian@nord.investments',
    jobTitle: 'Portfolio Manager',
    image: images.HansChristian,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/hans-christian-helboe-1979373a/'
    }
  },
  {
    name: 'Jeppe Blom-Hansen',
    email: 'jeppe@nord.investments',
    jobTitle: 'Head of Customer Success',
    image: images.Jeppe,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/jeppe-b-26ba46143/'
    }
  },
  {
    name: 'Adam Cichy',
    email: 'adam@nord.investments',
    jobTitle: 'Frontend Developer',
    image: images.Adam,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/adam-cichy-dk/'
    }
  },
  {
    name: 'Mette Byssing Larsen',
    email: 'mette@nord.investments',
    jobTitle: 'Customer Success Manager',
    image: images.Mette,
    socialAccounts: {
      LinkedIn: 'https://dk.linkedin.com/in/mettebyssinglarsen'
    }
  },
  {
    name: 'Anders Michelsen',
    email: 'andersmichelsen@nord.investments',
    jobTitle: 'Customer Success Manager',
    image: images.AndersMichelsen,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/anders-michelsen-110a18102/'
    }
  },
  {
    name: 'Asta Obel',
    email: 'asta@nord.investments',
    jobTitle: 'Customer Success Manager',
    image: images.Asta,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/asta-obel-6b0106a1/'
    }
  },
  {
    name: 'Nikolas Sønderborg',
    email: 'nikolas@nord.investments',
    jobTitle: 'Customer Success Manager',
    image: images.Nikolas,
    socialAccounts: {
      LinkedIn:
        'https://www.linkedin.com/in/nikolas-rask-s%C3%B8nderborg-546ab116b'
    }
  },
  {
    name: 'Michael Albrechtslund',
    email: 'michael@albrechtslund.dk',
    jobTitle: 'Bestyrelsesformand',
    image: images.Michael,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/michael-albrechtslund-391316/'
    }
  },
  {
    name: 'Christopher Hjelseth',
    jobTitle: 'Bestyrelsesmedlem',
    image: images.Christopher,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/christopherhjelseth/'
    }
  },
  {
    name: 'Henrik Hasselbalch Busch',
    jobTitle: 'Bestyrelsesmedlem',
    image: images.Henrik,
    socialAccounts: {
      LinkedIn: 'https://www.linkedin.com/in/busch/'
    }
  }
]

export default teamData
