import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

const MoreSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <BaseSection.Header.Base
      className="mt-6"
      variant="white"
      align="center"
      scrollReveal
    >
      En investering der passer til dig
    </BaseSection.Header.Base>
    <Row>
      <Col xs={12} lg={6} className="offset-lg-3 mb-6">
        <BaseSection.Text align="center" scrollReveal>
          Som alternativ til din virksomheds bankkonto kan du investere med en
          risiko, der passer til dig og din virksomhed. Det er nemt og simpelt.
        </BaseSection.Text>
      </Col>
    </Row>
  </BaseSection>
)

export default MoreSection
