import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../../BaseSection'

import * as images from './images'
import ImageWithOverlay from './ImageWithOverlay'

const GallerySection = () => (
  <BaseSection scrollReveal>
    <Row>
      <Col xs={12} lg={{ span: 8, offset: 2 }}>
        <BaseSection.Header.Base
          variant="white"
          align="center"
          className="mb-lg-5"
          scrollReveal
        >
          Vi har det sjovt, både i og uden for arbejdstiden
        </BaseSection.Header.Base>
      </Col>
    </Row>
    <Row>
      <Col xs={12} lg={8} className="my-3">
        <ImageWithOverlay
          date="02.07.2020"
          description="Johan, David og Adam"
          title="Tech team"
          image={images.TechTeam}
          index={1}
        />
      </Col>
      <Col xs={12} lg={4} className="my-3">
        <ImageWithOverlay
          date="21.06.2018"
          description="Anders"
          title="Anders præsenterer NORD"
          image={images.CBSLesson}
          index={2}
        />
      </Col>
      <Col xs={12} lg={6} className="my-3">
        <ImageWithOverlay
          date="02.12.2019"
          description="Team og naboer"
          title="Kalendergave"
          image={images.CalendarGift}
          index={3}
        />
      </Col>
      <Col xs={12} lg={6} className="my-3">
        <ImageWithOverlay
          date="23.03.2019"
          description="Nikolaj og Anders"
          title="Nordic Invest Camp"
          image={images.PriceWin}
          index={4}
        />
      </Col>
      <Col xs={12} lg={4} className="my-3">
        <ImageWithOverlay
          date="19.06.2020"
          description="HC, Jeppe og David"
          title="Slå søm i"
          image={images.NailsHit}
          index={5}
        />
      </Col>
      <Col xs={12} lg={8} className="my-3">
        <ImageWithOverlay
          date="01.11.2019"
          description="David og Saxo Bank"
          title="Blast"
          image={images.BlastEvent}
          index={6}
        />
      </Col>
    </Row>
  </BaseSection>
)

export default GallerySection
