import React from 'react'

import BaseSection from '../../../../../BaseSection'

const InfoOverlay = () => (
  <>
    <BaseSection.Header.Base level={5}>
      Hvad er rebalancering?
    </BaseSection.Header.Base>
    <BaseSection.Text>
      En portefølje skal holdes ved lige, så den bibeholder samme risiko, når
      både markederne stiger og falder.
    </BaseSection.Text>
    <BaseSection.Text>
      Vi står løbende for at rebalancere din portefølje. Rent praktisk går det
      ud på at købe og sælge ETFer ved større forskydninger, så din portefølje
      holder den samme procentvise fordeling af værdipapirer. Der rebalanceres,
      når fordelingen har forskudt mindst 3 risikoprofiler på fondsniveau. Der
      rebalanceres som udgangspunkt 1 gang om året - rebalancering sker når det
      er nødvendigt (efter kundens accept) og kan teoretisk set også være oftere
      end 1 gang.
    </BaseSection.Text>
    <BaseSection.Header.Base level={5}>
      Hvorfor skal man rebalancere?
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Over tid vil der komme nogle forskydninger i en portefølje. Det kan være,
      at aktierne stiger meget i en periode, hvor obligationerne falder. I sådan
      en situation, vil risikoen være blevet større, fordi porteføljen består af
      en større procentdel aktier end tidligere. Her vil vi derfor gå ind og
      sælge lidt af dine ETF’ere med aktier og i stedet købe nogle med
      obligationer. Omvendt vil risikoen falde, hvis obligationerne stiger og
      aktierne falder. Her vil vi så gøre det modsatte og sælge lidt af dine
      ETF´ere med obligationer og istedet købe nogle med aktier. Din portefølje
      justeres dermed tilbage til det oprindelige risiko niveau.
    </BaseSection.Text>
    <BaseSection.Header.Base level={5}>
      Giver et højere afkast
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Der rebalancerers ikke kun for at holde risikoen på det samme niveau. Når
      der sælges ud af de papirer, der er steget mest og købes af de papirer,
      der er faldet mest, er der en stor sandsynlighed for, at man sælger, når
      det er dyrt og køber, når det er billigt.&nbsp;Det viser sig som regel af
      være en god ide.
    </BaseSection.Text>
  </>
)

export default InfoOverlay
