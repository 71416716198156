import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import Circle from '@nordinvestments/nord-storybook/dist/components/Circle'
import Hero from '@nordinvestments/nord-storybook/dist/components/Hero'
import Icon from '@nordinvestments/nord-storybook/dist/components/Icon'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import VideoModal from '@nordinvestments/nord-storybook/dist/components/VideoModal'
import React, { useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Play from 'react-feather/dist/icons/play'

import BaseSection from '../../../BaseSection'
import SubscriptionModal from '../Sections/LatestAnnouncementsSection/SubscriptionModal'

import styles from './index.module.scss'
import VideoThumbnail from './VideoThumbnail'

const nasdaqUrl =
  'http://www.nasdaqomxnordic.com/aktier/microsite?languageId=5&Instrument=CSE223754&name=NORD.investments&ISIN=DK0061540184'

const InvestorHero = () => {
  const [showModal, setShowModal] = useState(false)
  const [showTVSpot, setShowTVSpot] = useState(false)

  const handleOpenModal = () => setShowModal(true)

  const handleCloseModal = () => setShowModal(false)

  const handleShowTVSpot = (event) => {
    event.preventDefault()
    setShowTVSpot(true)
  }

  const handleHideTVSpot = () => setShowTVSpot(false)

  return (
    <BaseSection.Hero
      height="large"
      backgroundImage={<Hero.Background />}
      backgroundClass="bg-nord-gradient"
      withCut
    >
      <Row>
        <Col xs={12} lg={6} className="text-center text-lg-left">
          <BaseSection.Header.Base variant="white" isHeroHeader scrollReveal>
            Investor relations
          </BaseSection.Header.Base>
          <BaseSection.Text scrollReveal>
            NORD.investments er børsnoteret på First North Growth Market Denmark
            under symbolet{' '}
            <a
              href={nasdaqUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="link-white"
            >
              “NORD”
            </a>
            . Tilmeld dig vores selskabsmeddelelser og få besked når der er nyt
            fra os.
          </BaseSection.Text>
          <ScrollTransition.Item
            as={BaffleButton}
            onClick={handleOpenModal}
            variant="success"
          >
            Abonner på selskabsmeddelelser
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} lg={6}>
          <ScrollTransition.Item
            as="div"
            className="position-relative mt-4 mt-lg-0"
          >
            <a href="#tvspot" onClick={handleShowTVSpot}>
              <div className={styles.videoThumbnailImage} id="tvspot">
                <VideoThumbnail className="shadow-lg" />
                <Text
                  as="p"
                  size="md"
                  variant="white"
                  transform="uppercase"
                  className={styles.topLeftText}
                >
                  se vores tv spot:
                </Text>
                <Circle variant="success" className={styles.videoPlay}>
                  <Icon icon={Play} className="text-sucess" />
                </Circle>
              </div>
            </a>
            <VideoModal
              show={showTVSpot}
              onHide={handleHideTVSpot}
              videoID="INQFRcc75jo"
              id="tvspot"
            />
          </ScrollTransition.Item>
        </Col>
      </Row>
      <SubscriptionModal
        showModal={showModal}
        onCloseModal={handleCloseModal}
      />
    </BaseSection.Hero>
  )
}

export default InvestorHero
