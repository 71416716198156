import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'

import nordMackbookImageIE from './images/nord-macbook-ie.png'
import NordMacbookImage from './NordMacbookImage'

const PriceSection = () => (
  <BaseSection scrollReveal>
    <SectionWithOverflowImage
      image={<NordMacbookImage />}
      imageForIE={nordMackbookImageIE}
      imageAlign="left"
      variant="A"
      hasLines
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Din pris
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        For kunder, med en formue på +5M, har vi muligheden for at sænke vores
        totale omkostninger, som normalt er 0,6% om året. Jo mere du investerer,
        desto lavere er gebyret. Vi er formentligt Danmarks billigste. Kontakt
        os, så fortæller vi dig mere.
      </BaseSection.Text>
    </SectionWithOverflowImage>
  </BaseSection>
)

export default PriceSection
