import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import PropTypes from 'prop-types'
import React from 'react'

const Base = React.forwardRef(
  ({ children, level, scrollReveal, isHeroHeader, ...props }, ref) => {
    const size = isHeroHeader ? 'xxxl' : 'xxl'
    let content = (
      <Text as={`h${level}`} size={size} ref={ref} {...props}>
        {children}
      </Text>
    )

    if (scrollReveal) {
      content = (
        <ScrollTransition.Item
          index={scrollReveal === true ? undefined : scrollReveal}
        >
          {content}
        </ScrollTransition.Item>
      )
    }

    return content
  }
)

Base.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isHeroHeader: PropTypes.bool,
  scrollReveal: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  level: PropTypes.number
}

Base.defaultProps = {
  className: undefined,
  isHeroHeader: false,
  scrollReveal: undefined,
  level: 2
}

Base.displayName = 'BaseSection.Header.Base'

export default Base
