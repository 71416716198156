import React from 'react'

import BaseSection from '../../../../BaseSection'

import WorldCard from './WorldCard'

const WorldSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <WorldCard />
  </BaseSection>
)

export default WorldSection
