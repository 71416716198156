import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Download from 'react-feather/dist/icons/download'

import styles from './DocumentCard.module.scss'

const PositionCard = ({ title, type, link }) => (
  <ScrollTransition.Item as={Col} xs={12} md={6} lg={4} className="mt-6">
    <Card
      as="a"
      href={link}
      target="_blank"
      className={classNames(styles.cardScale, 'h-100')}
    >
      <Card.Body
        className={classNames(
          styles.cardBodyWrapper,
          'd-flex flex-column align-items-center text-center my-5'
        )}
      >
        <Card.Title as="h3" className="text-white">
          {title}
        </Card.Title>
        <Text
          as="div"
          size="lg"
          variant="warning"
          align="center"
          transform="uppercase"
          className="mt-auto"
        >
          {type}
        </Text>
        <IconButton
          className="mt-5"
          variant="success"
          href={link}
          target="_blank"
          icon={Download}
          filled
        >
          Hent
        </IconButton>
      </Card.Body>
    </Card>
  </ScrollTransition.Item>
)

PositionCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
}

export default PositionCard
