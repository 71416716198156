import SectionWithOverflowImage from '@nordinvestments/nord-storybook/dist/components/SectionWithOverflowImage'
import React from 'react'

import BaseSection from '../../../../BaseSection'

import manWithAppImageIE from './images/man-with-app-ie.png'
import ManWithAppImage from './ManWithAppImage'

const WeSection = () => (
  <BaseSection scrollReveal>
    <SectionWithOverflowImage
      image={<ManWithAppImage />}
      imageForIE={manWithAppImageIE}
      imageAlign="left"
      variant="A"
      hasLines
    >
      <BaseSection.Header.Base variant="white" scrollReveal>
        Hvem er vi?
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        NORD.investments er Danmarks største uvildige, digitale
        investeringrådgiver. Vi har hjulpet ﬂere tusind danskere til at få
        lavere omkostninger på deres investeringer.
      </BaseSection.Text>
    </SectionWithOverflowImage>
  </BaseSection>
)

export default WeSection
