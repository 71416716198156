import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './ImageWithOverlay.module.scss'

const SCROLL_REVEAL_ITEM_COUNT = 5

const ImageWithOverlay = ({
  image: Image,
  title,
  description,
  date,
  index
}) => {
  const startIndex = index * SCROLL_REVEAL_ITEM_COUNT * 2

  return (
    <ScrollTransition.Item
      index={startIndex}
      as="div"
      className="position-relative"
    >
      <Image alt={title} className={styles.image} />
      <div
        className={classNames(
          'text-center',
          'position-absolute',
          styles.overlay
        )}
      >
        <Text as="div" variant="light-white">
          {date}
        </Text>
        <Text as="div" variant="white" size="lg" className="mb-2">
          {title}
        </Text>
        <Text as="div" variant="light-white" transform="uppercase">
          {description}
        </Text>
      </div>
    </ScrollTransition.Item>
  )
}

ImageWithOverlay.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.elementType.isRequired,
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired
}

export default ImageWithOverlay
