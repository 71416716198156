import React from 'react'

import BaseSection from '../../../../BaseSection'

import NobelPriceContent from './NobelPriceContent'

const NobelPriceSection = () => (
  <BaseSection fullScreen={false} className="overflow-hidden" scrollReveal>
    <NobelPriceContent />
  </BaseSection>
)

export default NobelPriceSection
