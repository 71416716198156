import Card from '@nordinvestments/nord-storybook/dist/components/Card'
import IconButton from '@nordinvestments/nord-storybook/dist/components/IconButton'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import Text from '@nordinvestments/nord-storybook/dist/components/Text'
import formatDate from '@nordinvestments/nord-storybook/dist/utilities/formatDate'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import BaseSection from '../../../../BaseSection'

import ArticleLink from './ArticleLink'
import articles from './articles'

const HighlightedArticle = () => {
  const highlightedArticle = articles.find((article) => article.isHighlighted)
  const { image, title, description, date, url } = highlightedArticle

  return (
    <ScrollTransition.Item as={Card} index={1}>
      <Card.Img variant="top" as={image} />
      <Card.Body>
        <div className="text-center text-md-left">
          <ScrollTransition.Item index={3}>
            <Text as={Card.Title} size="lg">
              {title}
            </Text>
            <Card.Text>{description}</Card.Text>
            <Card.Text className="text-monospace font-size-sm pt-2">
              {formatDate(date)}
            </Card.Text>
            <IconButton icon={ArrowRight} as="a" href={url} className="mt-3">
              Læs artikel
            </IconButton>
          </ScrollTransition.Item>
        </div>
      </Card.Body>
    </ScrollTransition.Item>
  )
}

const PressSection = () => (
  <BaseSection className="bg-primary text-white" height="large" scrollReveal>
    <BaseSection.Header.Base className="text-center" scrollReveal={0}>
      Presse
    </BaseSection.Header.Base>
    <Row>
      <Col xs={12} md={6} className="pr-lg-5">
        <HighlightedArticle />
      </Col>
      <Col
        xs={12}
        md={6}
        className="pl-lg-5 mt-6 mt-md-0 text-center text-md-left"
      >
        <ul className="list-unstyled text-center text-md-left">
          {articles
            .filter((article) => !article.isHighlighted)
            .map((filteredArticle) => (
              <ArticleLink
                key={filteredArticle.title}
                pressImage={filteredArticle.pressImage}
                title={filteredArticle.title}
                url={filteredArticle.url}
                date={filteredArticle.date}
              />
            ))}
        </ul>
      </Col>
    </Row>
  </BaseSection>
)

export default PressSection
