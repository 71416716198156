import Hero from '@nordinvestments/nord-storybook/dist/components/Hero'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import React from 'react'

import BaseSection from '../../BaseSection'

const WealthHero = () => {
  const largeAndUp = useBreakpointHandler('lg')
  const heroHeight = largeAndUp ? 'half' : 'large'

  return (
    <BaseSection.Hero
      className="text-center"
      height={heroHeight}
      backgroundImage={<Hero.Background />}
      backgroundClass="bg-nord-gradient"
      withCut
    >
      <BaseSection.Header.Base variant="white" isHeroHeader scrollReveal>
        Nord wealth
      </BaseSection.Header.Base>
      <BaseSection.Text scrollReveal>
        Til dig med en formue på +5M kr. Private Banking uden de høje
        omkostninger. <br /> Læs mere om hvad vi kan gøre for dig og din formue.
      </BaseSection.Text>
    </BaseSection.Hero>
  )
}

export default WealthHero
