import PropTypes from 'prop-types'
import React from 'react'

const Dot = ({ width, height, fill }) => (
  <svg viewBox="0 0 100 100" height={height} width={width}>
    <circle cx="50" cy="50" r="50" fill={fill} />
  </svg>
)

Dot.propTypes = {
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired
}

export default Dot
