import React from 'react'

import BaseSection from '../../../../BaseSection'

import PortfolioCard from './PortfolioCard'

const PortfolioSection = () => (
  <BaseSection scrollReveal>
    <PortfolioCard />
  </BaseSection>
)

export default PortfolioSection
