import React from 'react'

import SEO from '../../SEO'

import InvestorHero from './InvestorHero'
import Sections from './Sections'

const WealthPage = () => (
  <>
    <SEO
      title="Investor Relations"
      description="NORD.investments A/S er børsnoteret på Nasdaq First North Growth. Det betyder, at du også kan blive medejer af NORD.investments ved at investere i NORD.investments aktie."
      keywords={['Investor relations', 'Nasdaq First North Growth']}
      pathname="/investor"
    />
    <InvestorHero />
    <Sections />
  </>
)

export default WealthPage
