import React from 'react'

import CostsSection from './CostsSection'
import MediaSection from './MediaSection'
import ReviewsSection from './ReviewsSection'
import SecureSection from './SecureSection'
import StoryBoard from './StoryBoard'

const Sections = () => (
  <>
    <StoryBoard />
    <CostsSection />
    <MediaSection />
    <ReviewsSection />
    <SecureSection />
  </>
)

export default Sections
