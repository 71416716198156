import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const ManWithAppImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      manWithAppImage: file(
        relativePath: {
          eq: "components/pages/ETFPage/ETFHero/images/man-with-stocks.jpg"
        }
      ) {
        childImageSharp {
          fluid(
            quality: 100
            maxWidth: 5351
            maxHeight: 3685
            cropFocus: CENTER
          ) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.manWithAppImage.childImageSharp.fluid} {...props} />
}

export default ManWithAppImage
