const documentsData = [
  {
    title: 'Investorpræsentation',
    type: 'Pdf',
    link: '/dokumenter/investorpraesentation'
  },
  {
    title: 'Vedtægter',
    type: 'Pdf',
    link: '/dokumenter/vedtaegter'
  },
  {
    title: 'Prospekt',
    type: 'Pdf',
    link: '/dokumenter/prospekt'
  },
  {
    title: 'Årsrapport 2020',
    type: 'Pdf',
    link: '/dokumenter/aarsrapport-2020'
  },
  {
    title: 'Årsrapport 2019',
    type: 'Pdf',
    link: '/dokumenter/aarsrapport-2019'
  }
]

export default documentsData
