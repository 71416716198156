import Hero from '@nordinvestments/nord-storybook/dist/components/Hero'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import React from 'react'

import BaseSection from '../../BaseSection'

const ProductsHero = () => {
  const largeAndUp = useBreakpointHandler('lg')
  const heroHeight = largeAndUp ? 'half' : 'large'

  return (
    <BaseSection.Hero
      className="text-center"
      backgroundImage={<Hero.Background />}
      backgroundClass="bg-nord-gradient"
      height={heroHeight}
      withCut
    >
      <BaseSection.Header.HeroTop className="text-white" scrollReveal>
        Vi tilbyder
      </BaseSection.Header.HeroTop>
      <BaseSection.Header.Base className="mx-3" isHeroHeader scrollReveal>
        Saml hele din formue et sted
      </BaseSection.Header.Base>
    </BaseSection.Hero>
  )
}

export default ProductsHero
