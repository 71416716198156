import BaffleButton from '@nordinvestments/nord-storybook/dist/components/BaffleButton'
import RipplesShape from '@nordinvestments/nord-storybook/dist/components/RippleShapes'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import useDetectBrowser from '@nordinvestments/nord-storybook/dist/hooks/useDetectBrowser'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import BaseSection from '../../../BaseSection'
import OnboardingLink from '../../../OnboardingLink'

import styles from './ETFHero.module.scss'
import ManWithStocksImageIE from './images/IE-stocks.png'
import ManWithStocksImage from './ManWithStocksImage'

const ETFHero = () => {
  const isIE = useDetectBrowser('ie')

  return (
    <BaseSection.Hero
      backgroundClass={classNames('bg-nord-gradient', styles.etfHero)}
      withCut
      scrollReveal
    >
      <Row>
        <Col
          xs={12}
          lg={5}
          className="offset-lg-1 mt-4 mt-lg-0 my-lg-auto text-center text-lg-left"
        >
          <BaseSection.Header.HeroTop className="text-white" scrollReveal>
            ETF
          </BaseSection.Header.HeroTop>
          <BaseSection.Header.Base
            className="text-white"
            scrollReveal
            isHeroHeader
          >
            ETF: Investering i ETF’er giver stor spredning til lave omkostninger
          </BaseSection.Header.Base>
          <BaseSection.Text scrollReveal>
            Skal du i gang med at investere, så kan ETF’er være en god mulighed.
            Investering i ETF'er giver nemlig en bred risikospredning og
            interessen er steget gevaldigt i Danmark.
          </BaseSection.Text>
          <ScrollTransition.Item>
            <OnboardingLink component={BaffleButton} variant="success">
              Få en investeringsplan med ETF’er
            </OnboardingLink>
          </ScrollTransition.Item>
        </Col>
        <Col xs={12} lg={5} className="mt-5 mt-lg-0">
          {isIE ? (
            <img
              className="img-fluid"
              src={ManWithStocksImageIE}
              alt="appImage"
            />
          ) : (
            <RipplesShape.Mask variant="C" hasLines hero>
              <ManWithStocksImage />
            </RipplesShape.Mask>
          )}
        </Col>
      </Row>
    </BaseSection.Hero>
  )
}

export default ETFHero
