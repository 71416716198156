import CardWithRippleImage from '@nordinvestments/nord-storybook/dist/components/CardWithRippleImage'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import React from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import ArrowRight from 'react-feather/dist/icons/arrow-right'

import AnimatedLinkWithArrow from '../../../../AnimatedLinkWithArrow'
import BaseSection from '../../../../BaseSection'
import GatsbyInternalLink from '../../../../GatsbyInternalLink'
import styles from '../../../CardWithImage.module.scss'

import BoardMembersImage from './BoardMembersImage'

const NordWealthSection = () => (
  <BaseSection scrollReveal>
    <Row>
      <ScrollTransition.Item as={Col}>
        <CardWithRippleImage
          as={GatsbyInternalLink}
          to="/wealth"
          className={classNames(
            'shadow',
            'text-decoration-none',
            styles.cardBackground,
            styles.cardScale
          )}
          image={<BoardMembersImage />}
          imageAlign="left"
        >
          <BaseSection.Header.Base variant="white">
            NORD wealth
          </BaseSection.Header.Base>
          <BaseSection.Text variant="body" scrollReveal>
            Til dig med en formue på +5M kr. Med NORD Wealth får du endnu lavere
            omkostninger og en dedikeret personlig kontaktperson, der
            prioriterer dig og dine spørgsmål.
          </BaseSection.Text>
          <AnimatedLinkWithArrow icon={ArrowRight} href="/wealth">
            Læs mere
          </AnimatedLinkWithArrow>
        </CardWithRippleImage>
      </ScrollTransition.Item>
    </Row>
  </BaseSection>
)

export default NordWealthSection
