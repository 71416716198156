import React from 'react'

import BaseSection from '../../../../BaseSection'

import Products from './Products'

const ProductsSection = () => (
  <BaseSection fullScreen={false} scrollReveal>
    <Products />
  </BaseSection>
)

export default ProductsSection
