import Hero from '@nordinvestments/nord-storybook/dist/components/Hero'
import useBreakpointHandler from '@nordinvestments/nord-storybook/dist/hooks/useBreakpointHandler'
import React from 'react'

import BaseSection from '../../BaseSection'

const PriceHero = () => {
  const largeAndUp = useBreakpointHandler('lg')
  const heroHeight = largeAndUp ? 'half' : 'large'

  return (
    <BaseSection.Hero
      className="text-center"
      height={heroHeight}
      backgroundImage={<Hero.Background />}
      backgroundClass="bg-nord-gradient"
      withCut
    >
      <BaseSection.Header.HeroTop className="text-white" scrollReveal>
        Priser
      </BaseSection.Header.HeroTop>
      <BaseSection.Header.Base
        className="d-block d-lg-none"
        isHeroHeader
        scrollReveal
      >
        Højere afkast med lavere omkostninger
      </BaseSection.Header.Base>
      <BaseSection.Header.Base
        className="d-none d-lg-block"
        isHeroHeader
        scrollReveal
      >
        Få et højere afkast med lavere omkostninger
      </BaseSection.Header.Base>
      <BaseSection.Text className="text-white" scrollReveal>
        Kun 0,60 - 0,80 % i totale årlige omkostninger
      </BaseSection.Text>
    </BaseSection.Hero>
  )
}

export default PriceHero
