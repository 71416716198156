import Transition from '@nordinvestments/nord-storybook/dist/components/Transition'
import PropTypes from 'prop-types'
import React from 'react'

import BaseSection from '../../../../BaseSection'
import GatsbyInternalLink from '../../../../GatsbyInternalLink'

const NordWealth = ({ isActive }) => (
  <Transition.Expand in={isActive} key="nordWealth">
    <BaseSection.Header.Base
      variant="white"
      className="text-center text-lg-left"
    >
      Du er kvalificeret til NORD Wealth
    </BaseSection.Header.Base>
    <BaseSection.Text className="text-center text-lg-left pb-5">
      Til dig med en formue på +5M kr. Med NORD Wealth får du endnu lavere
      omkostninger og en dedikeret personlig kontaktperson, der prioriterer dig
      og dine spørgsmål. Private Banking uden de høje omkostninger.{' '}
      <GatsbyInternalLink to="/wealth" className="link-white">
        Læs mere om dine fordele med NORD Wealth.
      </GatsbyInternalLink>
    </BaseSection.Text>
  </Transition.Expand>
)

NordWealth.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default NordWealth
