import React from 'react'

const Storage = () => (
  <>
    <h2>Opbevaring</h2>

    <p>
      Vi opbevarer kun dine personlige oplysninger, så længe de hjælper os med
      at udføre de ydelser som vi beskriver i introduktionen. Derfor vil din
      personlige data i nogle tilfælde blive slettet fra vores interne arkiver,
      hvis de ikke længere kan bruges. Vi kontrollerer løbende om vi har gemt
      noget data, der ikke længere er nødvendigt at opbevare.
    </p>
    <p>
      Perioden hvor vi opbevarer dine data kan altså variere, da opbevaringen
      tjener forskellige formål. I visse tilfælde vil dine personlige
      oplysninger blive anonymiseret fremfor at blive slettet.
    </p>
    <p>
      Vi har ligeledes indført de nødvendige tekniske og organisatoriske
      foranstaltninger, for at sikre dine data samtidig med at vi stiller de
      samme krav til andre vi potentielt videregiver dine oplysninger til.
    </p>
    <p>
      De data som NORD er forpligtet til at indsamle, gemme og videregive til
      Saxo Bank A/S jævnfør hvidvaskloven, slettes 5 år efter du stopper med at
      være kunde hos NORD, med mindre andet er fastsat af anden lovgivning.
    </p>
  </>
)

export default Storage
