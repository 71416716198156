import React from 'react'

import SEO from '../../SEO'

import ResponsibleInvestmentHero from './ResponsibleInvestmentHero'
import Sections from './Sections'

const ResponsibleInvestmentPage = () => (
  <>
    <SEO
      title="En ansvarlig investering med fokus på miljø, socialt ansvar og ledelse"
      description="Investér ansvarligt med en bæredygtig portefølje. Fokus på miljø, socialt ansvar og ledelse. Få en gratis investeringsplan i dag."
      keywords={[
        'ansvarlig investering',
        'bæredygtig investering',
        'grøn investering'
      ]}
      pathname="/ansvarlig-investering"
    />
    <ResponsibleInvestmentHero />
    <Sections />
  </>
)

export default ResponsibleInvestmentPage
