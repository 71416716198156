import React from 'react'

import BaseSection from '../../../../../BaseSection'

const InfoOverlay = () => (
  <>
    <BaseSection.Header.Base level={5}>
      Sådan virker vores værktøj
    </BaseSection.Header.Base>
    <BaseSection.Text>
      NORDs investeringsværktøj bruges til at sammensætte en portefølje, der kan
      give dig det størst mulige afkast i forhold til den risiko, du har lyst
      til at tage.
    </BaseSection.Text>
    <BaseSection.Text>
      Når vi udregner hvordan din investeringsplan bør se ud, gør den først brug
      af en nobelprisvindende og gennemtestet model som ligger i værktøjet.
      Modellen finder din optimale fordeling af aktier, obligationer og andre
      aktivklasser (grupper af værdipapirer). Derefter gennemgås systematisk de
      ETF’er, der er udvalgt, og der findes frem til, hvilken fordeling, du skal
      have i din portefølje.
    </BaseSection.Text>
    <BaseSection.Header.Base level={5}>
      Den nobelprisvindende model
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Forskeren og økonomen Harry Markowitz står bag den model, der bruges i
      udregningerne. Markowitz præsenterede i 1952 en model som bygger på
      fordelene ved diversificering i en portefølje – at sprede investeringen
      over flere forskellige aktiv klasser. Ved hjælp af denne spredning er det
      muligt at opnå et højere risiko-justeret afkast, end ved kun at have én
      aktiv klasse i porteføljen. De porteføljer der har det højeste risiko
      justerede afkast, altså det optimale forhold mellem risiko og afkast,
      siges at ligge på den ”efficiente rand”.
    </BaseSection.Text>
    <BaseSection.Text>
      Modellen er siden blevet gennemtestet og brugt af mange økonomer og
      banker, og i 1990 vandt den Nobelprisen.
    </BaseSection.Text>
    <BaseSection.Header.Base level={5}>
      Fra fordeling til ETF’er
    </BaseSection.Header.Base>
    <BaseSection.Text>
      Når Markowitz’ model har udregnet den optimale fordeling af aktier,
      obligationer og andre værdipapirer, bruger vi også værktøjet til at
      udvælge ETF’erne og fordelingen imellem dem. Værktøjet kan finde frem til
      hvilke ETF’ere din investeringsplan rent praktisk bør bestå af for at
      porteføljen ligger på Markowitz’s efficiente rand. Værktøjet sørger altså
      for, at ETF’erne tilsammen har den rette mængde af f.eks. aktier og
      obligationer for at nå den optimale portefølje.
    </BaseSection.Text>
  </>
)

export default InfoOverlay
