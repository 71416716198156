import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'
import React from 'react'

const AndersImage = (props) => {
  const data = useStaticQuery(graphql`
    query {
      andersImage: file(
        relativePath: {
          eq: "components/pages/WealthPage/Sections/TeamSection/images/anders.jpg"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 2847, maxHeight: 3000, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return <Img fluid={data.andersImage.childImageSharp.fluid} {...props} />
}

export default AndersImage
