import ContentWrapper from '@nordinvestments/nord-storybook/dist/components/ContentWrapper'
import FullScreenSection from '@nordinvestments/nord-storybook/dist/components/FullScreenSection'
import ScrollTransition from '@nordinvestments/nord-storybook/dist/components/ScrollTransition'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { Row } from 'react-bootstrap'

import Header from './Header'
import Hero from './Hero'
import styles from './index.module.scss'
import Text from './Text'

const BaseSection = React.forwardRef(
  ({ children, className, scrollReveal, fullScreen, ...props }, ref) => {
    let content = <ContentWrapper>{children}</ContentWrapper>

    if (scrollReveal) {
      content = <ScrollTransition>{content}</ScrollTransition>
    }

    if (fullScreen) {
      content = (
        <FullScreenSection
          ref={ref}
          className={classNames(className, styles.baseSection)}
          {...props}
        >
          {content}
        </FullScreenSection>
      )
    }

    return <Row>{content}</Row>
  }
)

BaseSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  scrollReveal: PropTypes.bool,
  fullScreen: PropTypes.bool
}

BaseSection.defaultProps = {
  className: undefined,
  scrollReveal: undefined,
  fullScreen: true
}

BaseSection.Header = Header
BaseSection.Text = Text
BaseSection.Hero = Hero

BaseSection.displayName = 'BaseSection'
export default BaseSection
